import React from 'react'
import styles from './Marker.module.scss'
import { PortVesselProperties, VesselProperties, HandPickedVesselProperties } from '../../Domain/Vessel'
import { HoverMarker } from './HoverMarker'
import { VesselMarker } from './VesselMarker'

type VesselHoverMarkerProps = Readonly<{
  coordinates: number[]
  properties: PortVesselProperties | VesselProperties | HandPickedVesselProperties
}>

export const VesselHoverMarker = ({ coordinates, properties }: VesselHoverMarkerProps) => (
  <HoverMarker className={styles.marker} coordinates={coordinates}>
    <VesselMarker properties={properties} />
  </HoverMarker>
)
