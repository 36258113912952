import React from 'react'
import classnames from 'classnames'
import { CloseButton } from '../../UI/CloseButton'
import styles from './AuthenticationForm.module.scss'
import { useTranslation } from '../../lib/i18n'

type SuccessComponentProps = { title?: string; message: string; handleClose: () => void; description?: string }
export const SuccessComponent = ({ title, message, handleClose }: SuccessComponentProps) => {
  const { t } = useTranslation()

  return (
    <div className={classnames(styles.messageBox, styles.success)}>
      <div className={styles.messageBoxContent}>
        <span>{title ?? t('ShipTracker.Common.Success')}</span>
        <p className={styles.description}>{message}</p>
      </div>
      <CloseButton onClose={handleClose} theme={{ button: styles.closeButton, icon: styles.closeButtonIcon }} />
    </div>
  )
}
