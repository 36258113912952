import { useState } from 'react'
import {
  AuthenticationFormState,
  ForgotPasswordForm,
  GoogleAuthorizationForm,
  SignInForm,
  SignUpForm,
} from '../../Account/AuthenticationForm/AuthenticationForm'
import { Pages } from '../../constants'
import { saveVerifyEmailCache } from '../../lib/verifyEmailStorage'
import { Link } from '../../UI/Link/Link'
import { InlineLoader } from '../../UI/Loader/InlineLoader'
import { StepHeader, StepText, StepTitle } from '../GenericStepElements'
import { GenericStepProps } from '../GenericStepElements.d'
import styles from './CommonStepAuthentication.module.scss'
import { useHistory } from 'react-router-dom'
import { useTranslation } from '../../lib/i18n'
import { useAuthFlowContext } from '../../Auth/AuthFlowContext'

type CommonStepAuthenticationProps<T> = GenericStepProps<T> & {
  loginRedirectPage: typeof Pages['CREATE_ACCOUNT']
}

function titleTranslationKey(state: AuthenticationFormState) {
  switch (state) {
    case AuthenticationFormState.SIGN_IN:
      return 'ShipTracker.CommonStep.Authentication.SignInTitle'
    case AuthenticationFormState.SIGN_UP:
      return 'ShipTracker.Account.CompleteAccount.Authentication.Title'
    case AuthenticationFormState.FORGOT_PASSWORD:
      return 'ShipTracker.CommonStep.Authentication.ResetPasswordTitle'
  }
}

function textTranslationKey(state: AuthenticationFormState) {
  switch (state) {
    case AuthenticationFormState.SIGN_IN:
      return 'ShipTracker.CommonStep.Authentication.SignInDescription'
    case AuthenticationFormState.SIGN_UP:
      return 'ShipTracker.CommonStep.Authentication.Description'
    case AuthenticationFormState.FORGOT_PASSWORD:
      return 'ShipTracker.CommonStep.Authentication.ResetPasswordDescription'
  }
}

export const CommonStepAuthentication = <T,>({ onNextStep, loginRedirectPage }: CommonStepAuthenticationProps<T>) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [state, setState] = useState<AuthenticationFormState>(AuthenticationFormState.SIGN_UP)
  const [passwordChangeInProgress, setPasswordChangeInProgress] = useState(false)
  const { isLoadingToken } = useAuthFlowContext()

  const handleSignup = (email: string) => {
    saveVerifyEmailCache({ isVerified: false, email })
    onNextStep()
  }

  return (
    <>
      <StepHeader onClose={() => history.push(Pages.MAIN)} />
      <StepTitle>{t(titleTranslationKey(state))}</StepTitle>
      <StepText>{t(textTranslationKey(state))}</StepText>
      <div className={styles.authenticationStep}>
        {isLoadingToken ? (
          <div>
            {t('ShipTracker.Common.ReceivingToken')}&nbsp;
            <InlineLoader />
          </div>
        ) : (
          <>
            {state === AuthenticationFormState.SIGN_UP && (
              <>
                <SignUpForm onSignUp={handleSignup} />
                <p className={styles.switchState}>
                  {t('ShipTracker.CommonStep.Authentication.SwitchState.AlreadyHaveAnAccount.Question')}&nbsp;
                  <Link href="#login" onClick={() => setState(AuthenticationFormState.SIGN_IN)}>
                    {t('ShipTracker.CommonStep.Authentication.SwitchState.AlreadyHaveAnAccount.Link')}
                  </Link>
                </p>
              </>
            )}
            {state === AuthenticationFormState.SIGN_IN && (
              <>
                <SignInForm
                  redirectPage={loginRedirectPage}
                  discardPasswordChange={
                    passwordChangeInProgress ? () => setPasswordChangeInProgress(false) : undefined
                  }
                />
                <p className={styles.switchState}>
                  {t('ShipTracker.CommonStep.Authentication.SwitchState.DontHaveAnAccount.Question')}&nbsp;
                  <Link href="#sign-up" onClick={() => setState(AuthenticationFormState.SIGN_UP)}>
                    {t('ShipTracker.CommonStep.Authentication.SwitchState.DontHaveAnAccount.Link')}
                  </Link>
                </p>
                <p className={styles.switchState}>
                  {t('ShipTracker.CommonStep.Authentication.SwitchState.ForgotPassword.Question')}&nbsp;
                  <Link href="#forgot-password" onClick={() => setState(AuthenticationFormState.FORGOT_PASSWORD)}>
                    {t('ShipTracker.CommonStep.Authentication.SwitchState.ForgotPassword.Link')}
                  </Link>
                </p>
              </>
            )}
            {state === AuthenticationFormState.FORGOT_PASSWORD && (
              <>
                <ForgotPasswordForm
                  onPasswordChangeRequested={() => {
                    setPasswordChangeInProgress(true)
                    setState(AuthenticationFormState.SIGN_IN)
                  }}
                />
                <p className={styles.switchState}>
                  {t('ShipTracker.CommonStep.Authentication.SwitchState.RememberThePassword.Question')}&nbsp;
                  <Link href="#login" onClick={() => setState(AuthenticationFormState.SIGN_IN)}>
                    {t('ShipTracker.CommonStep.Authentication.SwitchState.RememberThePassword.Link')}
                  </Link>
                </p>
              </>
            )}
            <div className={styles.section}>
              <div className={styles.seperator}>
                <span className={styles.choose}>OR</span>
              </div>
              <GoogleAuthorizationForm redirectPage={loginRedirectPage} />
            </div>
          </>
        )}
      </div>
    </>
  )
}
