import { formatISO } from 'date-fns'
import { PropsWithChildren } from 'react'
import { useCallback } from 'react'
import { CURRENT_VERSION_LEGAL_TERMS, Pages } from '../../constants'
import { store } from '../../dataStore'
import { createZoneDateTime } from '../../Domain/DateTime'
import { StepFooter, StepHeader, StepTitle, StepText, StepContent } from '../../Flow/GenericStepElements'
import { CtaButton } from '../../UI/CtaButton/CtaButton'
import { GenericStepProps } from '../GenericStepElements.d'
import { useHistory } from 'react-router-dom'
import { useTranslation } from '../../lib/i18n'
import styles from './CommonStepLegalTerms.module.scss'

type CommonStepLegalTermsProps<T> = GenericStepProps<T> & {
  metadataLegalTermsKey: 'acceptedApiTerms' | 'acceptedTerms'
}
export const CommonStepLegalTerms = <T,>({
  onNextStep,
  metadataLegalTermsKey,
  children,
}: PropsWithChildren<CommonStepLegalTermsProps<T>>) => {
  const { t } = useTranslation()
  const history = useHistory()
  const handleAcceptLegalTerms = useCallback(async () => {
    await store.metadata.update({
      [metadataLegalTermsKey]: createZoneDateTime(formatISO(new Date(CURRENT_VERSION_LEGAL_TERMS))),
    })
    onNextStep()
  }, [metadataLegalTermsKey, onNextStep])

  return (
    <>
      <StepHeader onClose={() => history.push(Pages.MAIN)} />
      <StepTitle>{t('ShipTracker.CommonStep.LegalTerms.Title')}</StepTitle>
      <StepText>{t('ShipTracker.CommonStep.LegalTerms.Description')}</StepText>
      <StepContent className={styles.legalTerms}>{children}</StepContent>
      <StepFooter>
        <CtaButton onClick={handleAcceptLegalTerms}>Accept Legal Terms</CtaButton>
      </StepFooter>
    </>
  )
}
