import { useState, useEffect, CSSProperties, useRef } from 'react'
import { Point as GeoPoint } from 'geojson'
import { Map, LngLat } from 'mapbox-gl'
import { fromEvent } from 'rxjs'

import { Point, Map as Mapbox, LngLatLike } from 'mapbox-gl'

export const pointToCssTransformXYProps = (point: Point, zoom: number): CSSProperties => ({
  transform: `translate(${Math.round(point.x)}px, ${Math.round(point.y)}px)`,
  transformOrigin: 'top left',
  height: 0,
})

export const moveCoordinatesByXY = (mapbox: Mapbox) => (
  coordinates: LngLatLike,
  transform: { x?: number; y?: number }
): LngLatLike => {
  const point = mapbox.project(coordinates)
  point.x += transform.x ?? 0
  point.y += transform.y ?? 0
  return mapbox.unproject(point)
}

export function useFixedPoint(mapbox: Map, coordinates: GeoPoint['coordinates']) {
  const [point, setPoint] = useState<Point>(mapbox.project(LngLat.convert(coordinates)))
  const prev = useRef<typeof coordinates>()

  useEffect(() => {
    if (prev.current === undefined || prev.current.toString() !== coordinates.toString()) {
      setPoint(mapbox.project(LngLat.convert(coordinates)))
    }

    prev.current = coordinates

    const subscription = fromEvent<Event>(mapbox, 'move').subscribe(() => {
      setPoint(mapbox.project(LngLat.convert(coordinates)))
    })
    return subscription.unsubscribe.bind(subscription)
  }, [mapbox, coordinates])

  return point
}
