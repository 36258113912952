import { BehaviorSubject, defer } from 'rxjs'
import { Option, none, some, fold } from 'fp-ts/es6/Option'
import { pipe } from 'fp-ts/es6/function'
import { createMMSI, VesselJson } from '../../Domain/Vessel'
import { fetchVesselStatus } from '../../Api/Vessel/fetchStatus'
import { distinctUntilChanged, shareReplay, switchMap } from 'rxjs/operators'
import { equalsMMSIOption } from '../helpers/paths'
import { Either, left, right } from 'fp-ts/es6/Either'
import { is404 } from '../../lib/utils'

const subject = new BehaviorSubject<Option<number>>(none)

export class VesselNotFoundError extends Error {}

export const selectedVessel = subject.pipe(
  distinctUntilChanged(equalsMMSIOption),
  switchMap(option =>
    defer<Promise<Either<Error, Option<VesselJson>>>>(() =>
      pipe(
        option,
        fold(
          () => Promise.resolve(right(none)),
          mmsi =>
            fetchVesselStatus(createMMSI(mmsi))
              .then(json => right(some(json)))
              .catch(e => left(is404(e) ? new VesselNotFoundError(mmsi.toString()) : e))
        )
      )
    )
  ),
  shareReplay(1)
)

export const setSelectedVessel = (mmsi: number) => subject.next(some(mmsi))

export const clearSelectedVessel = () => subject.next(none)
