import React, { useEffect, useState } from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Pages } from './constants'

export function PublicOnlyRoute(props: RouteProps) {
  const { isLoading, isAuthenticated } = useAuth0()
  const [initialIsAuthenticated, setInitialIsAuthenticated] = useState<boolean>()

  const { children } = props

  useEffect(() => {
    if (initialIsAuthenticated === undefined && !isLoading) {
      setInitialIsAuthenticated(isAuthenticated)
    }
  }, [initialIsAuthenticated, isAuthenticated, isLoading])

  if (isLoading) {
    return <Route {...props} />
  }

  if (!initialIsAuthenticated) {
    return <Route {...props}>{children}</Route>
  }

  return (
    <Route {...props}>
      <Redirect to={Pages.MAIN} />
    </Route>
  )
}
