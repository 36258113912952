import React from 'react'

import { Point } from 'geojson'
import { LngLat } from 'mapbox-gl'
import { Marker } from './Marker'
import styles from './Marker.module.scss'
import { pointToCssTransformXYProps } from './helpers'
import { useMapboxContext } from '../Mapbox'

type MarkerProps = Readonly<{
  className?: string
  coordinates: Point['coordinates']
  children: JSX.Element | JSX.Element[]
}>

// based on https://github.com/alex3165/react-mapbox-gl/blob/master/src/projected-layer.tsx
// TODO: add anchor logic

export function HoverMarker({ className, children, coordinates }: MarkerProps) {
  const { mapbox } = useMapboxContext()
  const point = mapbox.project(LngLat.convert(coordinates))

  return (
    <Marker styles={pointToCssTransformXYProps(point, mapbox.getZoom())} className={styles.pointFreeMarker}>
      <div className={className}>{children}</div>
    </Marker>
  )
}
