import { PropsWithChildren } from 'react'
import classnames from 'classnames'
import styles from './Toggle.module.scss'

export const TOGGLE_THEME_DEFAULT: ToggleTheme = {
  toggle: styles.toggle,
  checked: styles.checked,
  disabled: styles.disabled,
  toggleText: styles.toggleText,
}

export type ToggleTheme = Readonly<{
  toggleContainer?: string
  toggle: string
  checked: string
  disabled: string
  toggleText: string
}>

interface IToggleProps {
  isChecked: boolean
  labelOn?: string
  labelOff?: string
  disabled?: boolean
  handleToggle: (checked: boolean) => void
  theme?: ToggleTheme
  hasDarkMode?: boolean
}

export const Toggle = ({
  theme = TOGGLE_THEME_DEFAULT,
  isChecked,
  disabled,
  handleToggle,
  labelOn,
  labelOff,
  children,
  hasDarkMode = false,
}: PropsWithChildren<IToggleProps>) => {
  const label = isChecked ? labelOn : labelOff

  return (
    <div className={theme?.toggleContainer}>
      <label>
        <input
          checked={isChecked}
          onChange={handleToggle.bind(handleToggle, !isChecked)}
          disabled={disabled}
          className={styles.hiddenInput}
          type="checkbox"
        />
        <div
          className={classnames(theme.toggle, {
            [theme.checked]: isChecked,
            [theme.disabled]: disabled,
            [styles.noDarkMode]: !hasDarkMode,
          })}
        >
          {label && <div className={theme.toggleText}>{label}</div>}
        </div>
        {children}
      </label>
    </div>
  )
}
