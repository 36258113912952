import React, { useEffect, useState } from 'react'
import { Point as GeoPoint } from 'geojson'
import classnames from 'classnames'
import { PortVesselProperties, VesselProperties, HandPickedVesselProperties } from '../../Domain/Vessel'
import { moveCoordinatesByXY, pointToCssTransformXYProps, useFixedPoint } from './helpers'
import { Marker } from './Marker'
import styles from './Marker.module.scss'
import { useMapboxContext } from '../Mapbox'
import { usePortal } from '../../lib/hooks/usePortal'

export const DETAILS_MARKER_WIDTH = Number(styles.DETAILS_MARKER_WIDTH)

function useFlyTo(coordinates: GeoPoint['coordinates']) {
  const [isFlying, setIsFlying] = useState(true)

  const { mapbox } = useMapboxContext()

  useEffect(() => {
    let mounted = true

    const handler = () => {
      mounted && setIsFlying(false)
      mapbox.off('moveend', handler)
    }

    mapbox.on('moveend', () => setTimeout(handler, 500))

    mapbox.flyTo({
      center: moveCoordinatesByXY(mapbox)(coordinates, {
        x: DETAILS_MARKER_WIDTH / 2,
      }),
    })

    return () => {
      mounted = false
    }
  }, [mapbox, coordinates])

  return isFlying
}

export type VesselDetailsMarkerProps = Readonly<{
  coordinates: number[]
  properties: PortVesselProperties | VesselProperties | HandPickedVesselProperties
}>
export const VesselDetailsMarker: React.FC<{ coordinates: GeoPoint['coordinates'] }> = ({ coordinates, children }) => {
  const { mapbox } = useMapboxContext()
  const isFlying = useFlyTo(coordinates)

  return (
    <Marker styles={pointToCssTransformXYProps(useFixedPoint(mapbox, coordinates), mapbox.getZoom())}>
      <div className={classnames(styles.detailsMarker, { [styles.visibilityHidden]: isFlying })}>{children}</div>
    </Marker>
  )
}

export const MobileVesselDetailsMarker: React.FC<{ coordinates: GeoPoint['coordinates'] }> = ({
  coordinates,
  children,
}) => {
  const { Portal } = usePortal()
  const isFlying = useFlyTo(coordinates)

  return isFlying ? null : (
    <Portal>
      <div className={styles.detailsMarker}>{children}</div>
    </Portal>
  )
}
