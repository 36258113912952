import { useTranslation } from '../../lib/i18n'
import styles from './VesselDetails.module.scss'
import { pipe } from 'fp-ts/es6/function'
import { Option, fold } from 'fp-ts/es6/Option'
import { Country } from '../../Domain/Countries'

export const VesselDetailsTitle: React.FC<{ title?: string; country: Option<Country> }> = ({ title, country }) => {
  const { t } = useTranslation()

  return (
    <h3 className={styles.vesselDetailsTitle}>
      {pipe(
        country,
        fold(
          () => <div className={styles.flag} />,
          ({ alpha2, name }) => <img className={styles.flag} src={`/assets/flags/24x24/${alpha2}.png`} alt={name} />
        )
      )}
      {title || t('ShipTracker.Common.NotAvailable')}
    </h3>
  )
}
