import ky from 'ky'
import { aisToken$ } from '../Auth/ais-jwt-token'
import { toPromise } from '../lib/utils'
import { getTokenSilently } from '../Auth/client'

export const authRequest = ky.extend({
  hooks: {
    beforeRequest: [
      async request => {
        const token = await toPromise(getTokenSilently)

        request.headers.set('Authorization', `Bearer ${token}`)

        return request
      },
    ],
  },
})

export const aisAuthRequest = ky.extend({
  hooks: {
    beforeRequest: [
      async request => {
        const token = await new Promise(aisToken$.subscribe.bind(aisToken$))

        request.headers.set('Authorization', `Bearer ${token}`)

        return request
      },
    ],
  },
})
