import { pipe } from 'fp-ts/es6/function'
import { fromNullable, map } from 'fp-ts/es6/Option'
import { useCallback, useMemo, useState } from 'react'
import { Zipper, create, next, prev } from '../lib/Zipper'

export function useFlow<A>(allSteps: A[]) {
  const { head, tail } = useMemo(() => {
    const [head, ...tail] = allSteps
    return { head, tail }
  }, [allSteps])
  const [steps, updateSteps] = useState<Zipper<A>>(create(head, tail))

  const { current } = steps

  const setStep = useCallback(
    (step: A) => {
      let zipper = create(head, tail)
      while (zipper.current !== step) {
        const nextZipper = next(zipper)
        if (nextZipper) {
          zipper = nextZipper
        } else {
          break
        }
      }

      updateSteps(zipper)
    },
    [head, tail]
  )

  return {
    current,
    nextStep: () => pipe(fromNullable(next(steps)), map(updateSteps)),
    prevStep: () => pipe(fromNullable(prev(steps)), map(updateSteps)),
    setStep,
  }
}
