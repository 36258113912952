import { MetadataJson, SystemOfMeasurement } from './../../Domain/User'
import { BACKEND_URL } from '../../config'
import { authRequest } from '../auth'
import { Metadata } from '../../Domain/User'

export const fetchMetadata = (): Promise<Metadata> =>
  authRequest
    .get(`${BACKEND_URL}/api/me/metadata`)
    .json<MetadataJson>()
    .then(metadata => ({
      measurementUnit: SystemOfMeasurement.Metric,
      ...metadata,
    }))
