import { VesselJson } from '../../Domain/Vessel'
import { aisAuthRequest } from '../auth'
import { AIS_BACKEND_URL } from '../../config'
import { from, of } from 'rxjs'
import { flatMap, scan } from 'rxjs/operators'
import { chunksOf } from 'fp-ts/es6/Array'

const BATCH_SIZE = 1000

const BATCH_CONCURRENCY = 3

const createBatches = chunksOf(BATCH_SIZE)

export const fetchVesselsByMmsiList = (mmsis: string[]) =>
  mmsis.length === 0
    ? of([])
    : from(createBatches(mmsis))
        .pipe(
          flatMap(
            json => aisAuthRequest.post(`${AIS_BACKEND_URL}/vessels/status`, { json }).json<VesselJson[]>(),
            BATCH_CONCURRENCY
          )
        )
        .pipe(scan((prev: VesselJson[], next: VesselJson[]) => prev.concat(next), []))
