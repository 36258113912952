import classnames from 'classnames'
import { useTranslation } from '../../lib/i18n'
import { AccountIcon, AccountStatus } from '../../UI/Icons/Account'
import MaterialIcon from '../../UI/MaterialIcon'
import { useHistory } from 'react-router-dom'
import { Pages } from '../../constants'
import { useAuth0 } from '@auth0/auth0-react'
import styles from './Menu.module.scss'
import { clientLogout } from '../../Auth/client'
import { DropoutMenu } from '../../UI/DropoutMenu/DropoutMenu'
import { TopRightDropoutMenuDropper } from '../../UI/DropoutMenu/DropoutMenuDropper'

export function AccountButton() {
  const { isLoading, isAuthenticated, logout } = useAuth0()
  const { t } = useTranslation()
  const history = useHistory()

  if (isLoading) {
    return null
  }

  const handleLogin = () => {
    history.push(Pages.LOGIN)
  }
  const handleLogout = () => {
    clientLogout(logout)
  }

  if (isAuthenticated) {
    return (
      <DropoutMenu>
        {({ isOpen, closeMenu, openMenu }) => (
          <div
            className={classnames(styles.menuItem, styles.myAccount)}
            onClick={() => {
              isOpen ? closeMenu() : openMenu()
            }}
          >
            <AccountIcon className={styles.accountIcon} />
            <AccountStatus className={styles.accountStatus} />
            <MaterialIcon className={styles.menuHandle} type="expand_more" />
            {isOpen && (
              <TopRightDropoutMenuDropper className={styles.accountMenu} triangleOffsetRight={30}>
                <li className={styles.accountMenuItem} onClick={() => history.push(Pages.ACCOUNT)}>
                  <MaterialIcon className={styles.icon} type="admin_panel_settings" />
                  <span className={styles.action}>{t('ShipTracker.Account.MyAccount')}</span>
                </li>
                <li className={styles.accountMenuItem} onClick={handleLogout}>
                  <MaterialIcon className={styles.icon} type="logout" />
                  <span className={styles.action}>{t('ShipTracker.Account.Logout')}</span>
                </li>
              </TopRightDropoutMenuDropper>
            )}
          </div>
        )}
      </DropoutMenu>
    )
  }

  return (
    <DropoutMenu>
      {({ isOpen, closeMenu, openMenu }) => (
        <div
          className={classnames(styles.menuItem, styles.myAccount)}
          onClick={() => {
            isOpen ? closeMenu() : openMenu()
          }}
        >
          <AccountIcon className={styles.accountIcon} />
          <MaterialIcon className={styles.menuHandle} type="expand_more" />
          {isOpen && (
            <TopRightDropoutMenuDropper className={styles.accountMenu} triangleOffsetRight={30}>
              <li className={styles.accountMenuItem} onClick={handleLogin}>
                <MaterialIcon className={styles.icon} type="login" />
                <span className={styles.action}>{t('ShipTracker.Account.Login')}</span>
              </li>
              <li
                className={styles.accountMenuItem}
                onClick={() => {
                  history.push(Pages.CREATE_ACCOUNT)
                }}
              >
                <MaterialIcon className={styles.icon} type="admin_panel_settings" />
                <span className={styles.action}>{t('ShipTracker.Account.CreateAccountAsNotifyOnArrival')}</span>
                <span className={styles.label}>{t('ShipTracker.Common.Free')}</span>
              </li>
            </TopRightDropoutMenuDropper>
          )}
        </div>
      )}
    </DropoutMenu>
  )
}
