import { Auth0Client } from '@auth0/auth0-spa-js'
import { BehaviorSubject, noop } from 'rxjs'
import { filter, flatMap } from 'rxjs/operators'

type State = { isAuthenticated: boolean; getTokenSilently: Auth0Client['getTokenSilently'] }

export const client = new BehaviorSubject<State>({ isAuthenticated: false, getTokenSilently: () => new Promise(noop) })

export const initializeClient = client.next.bind(client)

export const getTokenSilently = client.pipe(
  filter(({ isAuthenticated }) => isAuthenticated),
  flatMap(({ getTokenSilently }) => getTokenSilently())
)

export const clientLogout = (logout: Auth0Client['logout']) =>
  logout({
    returnTo: `${window.location.origin}`,
  })
