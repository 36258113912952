import { BehaviorSubject } from 'rxjs'
import { scan, shareReplay } from 'rxjs/operators'
import { flow } from 'fp-ts/es6/function'
import { Subscription } from '../../Domain/Notification/Subscription'
import { useState, useEffect } from 'react'

enum ActionType {
  INIT,
  ADD,
  REMOVE,
}

export type State = Subscription[]

type Init = { type: ActionType.INIT; state: State }
type Add = { type: ActionType.ADD; subscription: Subscription }
type Remove = { type: ActionType.REMOVE; id: string }

type Action = Add | Remove | Init

const subject = new BehaviorSubject<Action>({ type: ActionType.INIT, state: [] })

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.INIT: {
      return action.state
    }

    case ActionType.ADD: {
      return [...state, action.subscription]
    }

    case ActionType.REMOVE: {
      return state.filter(({ uuid }) => uuid !== action.id)
    }
  }
}

export const subscribtionsState = subject.pipe(scan<Action, State>(reducer, []), shareReplay(1))

const dispatch = subject.next.bind(subject)

const init = (state: State): Init => ({ type: ActionType.INIT, state })
const add = (subscription: Subscription): Add => ({ type: ActionType.ADD, subscription })
const remove = (id: string): Remove => ({ type: ActionType.REMOVE, id })

export const actions = {
  init: flow(init, dispatch),
  add: flow(add, dispatch),
  remove: flow(remove, dispatch),
}

export function useNotificationSubscriptions() {
  const [subscriptions, setSubscriptions] = useState<State>([])

  useEffect(() => {
    const subscription = subscribtionsState.subscribe(setSubscriptions)

    return subscription.unsubscribe.bind(subscription)
  }, [])

  return { subscriptions }
}
