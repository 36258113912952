import center from '@turf/center'

import { getShipCoordinates } from './shipShape'
import { VesselJson, VesselSource } from '../../Domain/Vessel'
import { ShapeFeature } from '../../Domain/VesselFeature'

export function vesselShapePolygon(
  { location: { coordinates }, mmsi, shipDimensions, heading, courseOverGround }: VesselJson,
  source: VesselSource,
  portcallId?: string
): ShapeFeature {
  const { distanceToBow, distanceToPort, distanceToStarboard, distanceToStern } = shipDimensions || {
    distanceToBow: 1,
    distanceToPort: 1,
    distanceToStarboard: 1,
    distanceToStern: 1,
  }

  const mostAccurateHeading = (heading || courseOverGround || 0) % 360
  const lengthInMeters = distanceToBow + distanceToStern
  const widthInMeters = distanceToStarboard + distanceToPort
  const [x, y] = coordinates
  const shipCoordinates = [
    getShipCoordinates(lengthInMeters, widthInMeters, mostAccurateHeading, distanceToStern, distanceToPort, [x, y]),
  ]

  const geometry: GeoJSON.Polygon = {
    type: 'Polygon',
    coordinates: shipCoordinates,
  }

  const [lng, lat] = center(geometry).geometry.coordinates

  return {
    type: 'Feature',
    properties: { mmsi, lng, lat, source, portcallId },
    geometry,
  }
}
