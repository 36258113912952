import React from 'react'
import { DropoutMenu } from '../../UI/DropoutMenu/DropoutMenu'
import { TopLeftDropoutMenuDropper } from '../../UI/DropoutMenu/DropoutMenuDropper'
import { LayerIcon } from '../../UI/Icons/Layer'
import { RadioButtonWithLabel } from '../../UI/RadioButton/RadioButton'
import { useMapboxContext, UserMapStyle } from '../Mapbox'
import styles from './LayersDropdown.module.scss'

const userMapStyleLabels: { [key in UserMapStyle]: string } = {
  [UserMapStyle.DEFAULT]: 'Default',
  [UserMapStyle.SATELLITE]: 'Satellite',
}

const UserMapStyleOption = ({ mapStyle, onClick }: { mapStyle: UserMapStyle; onClick: () => void }) => {
  const { setUserMapStyle, userMapStyle } = useMapboxContext()

  const onChange = () => {
    setUserMapStyle(mapStyle)
    onClick()
  }

  return (
    <RadioButtonWithLabel
      checked={userMapStyle === mapStyle}
      handleChange={onChange}
      label={userMapStyleLabels[mapStyle]}
    />
  )
}

export const LayersDropdown = () => {
  return (
    <div className={styles.mapLayers}>
      <DropoutMenu>
        {({ isOpen, closeMenu, openMenu }) => (
          <>
            <button type="button" className={styles.button} onClick={() => (isOpen ? closeMenu() : openMenu())}>
              <LayerIcon width={20} height={20} className={styles.icon} />
            </button>
            {isOpen && (
              <TopLeftDropoutMenuDropper triangleOffsetTop={12} isHorizontal>
                Map type
                <ul className={styles.list}>
                  <li className={styles.item}>
                    <UserMapStyleOption mapStyle={UserMapStyle.DEFAULT} onClick={closeMenu} />
                  </li>
                  <li className={styles.item}>
                    <UserMapStyleOption mapStyle={UserMapStyle.SATELLITE} onClick={closeMenu} />
                  </li>
                </ul>
              </TopLeftDropoutMenuDropper>
            )}
          </>
        )}
      </DropoutMenu>
    </div>
  )
}
