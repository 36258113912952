import React from 'react'
import classnames from 'classnames'
import styles from './AuthenticationForm.module.scss'

type WarningComponentProps = { message: string }
export const WarningComponent = ({ message }: WarningComponentProps) => {
  return (
    <div className={classnames(styles.messageBox, styles.warning)}>
      <div className={styles.messageBoxContent}>
        <p className={styles.description} dangerouslySetInnerHTML={{ __html: message }} />
      </div>
    </div>
  )
}
