import React from 'react'
import { Port } from '../../../Domain/Port'
import { PortVessel, HandPickedVessel } from '../../../Domain/Vessel'
import { VesselListingActions } from './VesselListingActions'
import styles from './VesselListing.module.scss'
import { VesselListingList } from './VesselListingList'
import MaterialIcon from '../../../UI/MaterialIcon'
import { useState } from 'react'
import classnames from 'classnames'
import { useEffect } from 'react'

export const VESSEL_LISTING_INITIAL_VISIBILITY = true

type PortHeaderProps = { port: VesselListingContentProps['port'] }

const PortHeader: React.FC<PortHeaderProps> = ({ port: { name, port: code } }) => {
  const title = `${name} (${code})`

  return (
    <header>
      <h1 className={styles.title}>{title}</h1>
    </header>
  )
}

type VesselListingContentProps = {
  port: Pick<Port, 'name' | 'port'>
  portcalls: PortVessel[]
  totalPortVessels: PortVessel[]
  handPickedVessels: HandPickedVessel[]
  onListVisibilityChanged?: (visible: boolean) => void
}

export const VesselListing = ({
  port,
  portcalls,
  totalPortVessels,
  handPickedVessels,
  onListVisibilityChanged,
}: VesselListingContentProps) => {
  const [isListVisible, setIsListVisible] = useState(VESSEL_LISTING_INITIAL_VISIBILITY)
  useEffect(() => {
    if (onListVisibilityChanged) {
      onListVisibilityChanged(isListVisible)
    }
  }, [isListVisible, onListVisibilityChanged])

  return (
    <div className={classnames(styles.vesselListing, { [styles.vesselListingHidden]: !isListVisible })}>
      <div className={styles.vesselListingContent}>
        <PortHeader port={port} />
        <VesselListingActions totalPortVessels={totalPortVessels} />
        <VesselListingList handpickedVessels={handPickedVessels} portcalls={portcalls} />
      </div>
      <button className={styles.visibilityButton} onClick={() => setIsListVisible(!isListVisible)}>
        <MaterialIcon
          className={classnames(styles.visibilityIcon, { [styles.visibilityIconRotated]: !isListVisible })}
          type="chevron_right"
        />
      </button>
    </div>
  )
}
