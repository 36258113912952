import { createMap } from './Map/Mapbox'
import Header from './Header/Header'
import styles from './ShipTracker.module.scss'
import { useMapbox } from './Map/useMapbox'
import { useAuth0 } from '@auth0/auth0-react'
import { matchPath, useHistory, useLocation } from 'react-router-dom'
import { pipe, constUndefined } from 'fp-ts/es6/function'
import { fold, fromNullable } from 'fp-ts/es6/Option'
import classnames from 'classnames'
import { useDarkMode } from './lib/hooks/useDarkMode'
import { getShowCasePort, Pages, SHIP_TRACKER_THEME_CSS, SHIP_TRACKER_THEME_DARK_CSS } from './constants'
import { PortalProvider } from './lib/hooks/usePortal'
import CookieBar from './UI/CookieBar/CookieBar'
import { useCookieSettings } from './UI/CookieBar/useCookieSettings'
import { ShowCase, AuthenticatedShowCase } from './Map/ShowCaseMap'
import { VesselSearchResult } from './Domain/VesselSearchResult'
import { selectedVesselPath } from './Map/helpers/paths'
import { setSearched } from './Map/Traffic/vesselSearch'

const findMatch = (pathname: string) =>
  pipe(
    matchPath<{ mmsi?: string }>(pathname, { path: `${Pages.MAP}/vessels/:mmsi/details` })?.params.mmsi,
    fromNullable
  )

export default function Main() {
  const { isAuthenticated } = useAuth0()
  const { onMount, mapbox } = useMapbox(createMap)
  const { isDarkMode } = useDarkMode()
  const { areCookiesEnabled } = useCookieSettings()
  const { pathname } = useLocation()
  const selectedVessel = findMatch(pathname)
  const history = useHistory()

  return (
    <main className={classnames(styles.main, SHIP_TRACKER_THEME_CSS, { [SHIP_TRACKER_THEME_DARK_CSS]: isDarkMode })}>
      <PortalProvider id="shiptracker-portal">
        <Header
          handleSearchResult={pipe(
            selectedVessel,
            fold(
              () => ({ ship: { mmsi } }: VesselSearchResult, query: string) => {
                if (mmsi) {
                  setSearched({ mmsi, query })
                  history.push(selectedVesselPath(mmsi))
                }
              },
              constUndefined
            )
          )}
        />
        <div className={styles.map}>
          <div ref={onMount} className={styles.mapBox} />
          {pipe(
            getShowCasePort(),
            fromNullable,
            fold(
              () => null,
              port => (
                <>
                  {isAuthenticated ? (
                    <AuthenticatedShowCase port={port} mapbox={mapbox} />
                  ) : (
                    <ShowCase port={port} mapbox={mapbox} />
                  )}
                  {!areCookiesEnabled && <CookieBar />}
                </>
              )
            )
          )}
        </div>
      </PortalProvider>
    </main>
  )
}
