import { Map as Mapbox } from 'mapbox-gl'
import { of, fromEvent, from, defer, interval, Observable, combineLatest, BehaviorSubject } from 'rxjs'
import {
  map,
  switchMap,
  scan,
  distinctUntilChanged,
  startWith,
  catchError,
  filter,
  shareReplay,
  debounceTime,
  mergeMap,
} from 'rxjs/operators'
import { outerTiles, tilesAreEqual, tilesFromOuterTiles, distanceToCenterComparator, Tile } from '../helpers/tiles'
import { getShowCasePort, SECOND } from '../../constants'
import {
  emptyTrafficVessels,
  TrafficVessel,
  createMMSI,
  PortVessel,
  portcallFromVesselVisit,
  HandPickedVessel,
  handPickedFromVesselVisit,
  emptyMMSIs,
  MMSI,
  trafficVesselFromJson,
  getBestArrivalTime,
} from '../../Domain/Vessel'
import { useState, useEffect, useCallback } from 'react'
import { Visit } from '../../Domain/PortCall'
import { useMapboxContext, renderPortcalls, renderTraffic, clearPortcalls, clearTraffic } from '../Mapbox'
import { fetchTiles } from '../../Api/Vessel/fetchTiles'
import { fetchVesselsByMmsiList } from '../../Api/Vessel/fetchByMMSIList'
import { isSome, fromNullable, Option, fromEither, map as mapSome, fold, flatten, toUndefined } from 'fp-ts/es6/Option'
import { pipe, constant, constFalse, flow } from 'fp-ts/es6/function'
import { compareAsc } from 'date-fns'
import { actions, vesselState, State as VesselState } from './localVesselStore'
import { findSubscription } from '../SelectedVesselContent/useEditSubscription'
import { fetchNextVisitForHandPicked } from '../../Api/Vessel/fetchNextVisitForHandPicked'
import { subscribtionsState, State as SubscriptionsState } from './useNotificationSubscriptions'
import { flagStateFilterSubject } from './useFlagStateFilter'
import { vesselTypeFilterSubject } from './useShipTypeFilter'
import { Country } from '../../Domain/Countries'
import { vesselStatusFilterSubject } from './vesselStatusFilter'
import { Unlocode } from '../../Domain/Port'
import { fetchPortVessels } from '../../Api/Port/fetchPortVessels'
import { selectedVessel } from './selectedVessel'

type Visits = Record<string, Visit>

export enum VesselDisplayState {
  ICONS,
  SHAPES,
}

const INITIAL_TRAFFIC_DISPLAY_STATE = true

export const mapZoomState = (mapbox: Mapbox) =>
  fromEvent(mapbox, 'zoomend').pipe(map(mapbox.getZoom.bind(mapbox)), startWith(mapbox.getZoom()))

const mapMoveState = (mapbox: Mapbox) =>
  fromEvent(mapbox, 'moveend').pipe(map(mapbox.getBounds.bind(mapbox)), startWith(mapbox.getBounds()))

const vesselDisplayState = (mapbox: Mapbox) =>
  mapZoomState(mapbox).pipe(map(zoom => (zoom > 12 ? VesselDisplayState.SHAPES : VesselDisplayState.ICONS)))

const REQUEST_BATCH_SIZE = 4

export const isFavoritesFilterActiveSubject = new BehaviorSubject<boolean>(false)
export const isHandPickedFilterActiveSubject = new BehaviorSubject<boolean>(false)
export const isSubscribedFilterActiveSubject = new BehaviorSubject<boolean>(false)
export const trafficDisplayState = new BehaviorSubject<boolean>(INITIAL_TRAFFIC_DISPLAY_STATE)

const collectTiles = (mapbox: Mapbox, zoom: number, debounce: number) =>
  mapMoveState(mapbox).pipe(
    debounceTime(debounce),
    filter(() => !mapbox.isMoving()),
    map(() => outerTiles(mapbox.getBounds(), zoom)),
    distinctUntilChanged(
      (prev, next) =>
        tilesAreEqual(prev.northWestTile, next.northWestTile) && tilesAreEqual(prev.southEastTile, next.southEastTile)
    ),
    map(outerTiles => tilesFromOuterTiles(outerTiles, mapbox.getCenter(), zoom).sort(distanceToCenterComparator)),
    switchMap(tiles => loadTiles(mapbox, zoom, tiles))
  )

const loadTiles = (mapbox: Mapbox, zoom: number, tiles: Tile[]) =>
  from(tiles).pipe(
    mergeMap(
      tile => defer(() => fetchTiles(zoom, tile)).pipe(catchError(() => of(emptyTrafficVessels))),
      REQUEST_BATCH_SIZE
    ),
    filter(() => Math.round(mapbox.getZoom()) === zoom),
    scan((prev, next) => prev.concat(next), emptyTrafficVessels)
  )

const traffic = (mapbox: Mapbox, debounce: number) =>
  mapZoomState(mapbox).pipe(
    map(Math.round.bind(Math)),
    distinctUntilChanged(),
    switchMap(zoom => collectTiles(mapbox, zoom, debounce)),
    shareReplay(1),
    catchError(() => of(emptyTrafficVessels))
  )

const vesselTraffic = (mapbox: Mapbox) =>
  combineLatest(
    vesselDisplayState(mapbox).pipe(startWith(VesselDisplayState.ICONS), distinctUntilChanged()),
    traffic(mapbox, 900)
  )

const updateInterval = interval(30 * SECOND).pipe(startWith(-1), shareReplay(1))

const portTraffic = (port: Unlocode, fetchVisits: () => Promise<Visit[]>) =>
  defer(() =>
    Promise.all([
      fetchPortVessels(port),
      fetchVisits().then(visits => ({
        visits,
        visitsStore: visits.reduce((acc: { [key: string]: Visit }, v) => {
          const { mmsi } = v

          acc[mmsi.toString()] = v

          return acc
        }, {}),
      })),
    ])
      .then(([vesselsJson, { visits, visitsStore }]) => ({
        visits,
        visitsStore,
        portVessels: vesselsJson.reduce((acc: PortVessel[], json) => {
          if (!('location' in json)) {
            return acc
          }

          return [...acc, portcallFromVesselVisit(visitsStore[json.mmsi.toString()], json)]
        }, []),
      }))
      .catch(() => ({ visits: [], visitsStore: {}, portVessels: [] }))
  )

export const handPickedVisits = () =>
  vesselState.pipe(
    mergeMap(({ handPicked }) => defer(() => fetchNextVisitForHandPicked(handPicked, fromNullable(getShowCasePort()))))
  )

const compareETAs = (a: string | undefined, b: string | undefined) => {
  if (!a && !b) return 0
  else if (!a) return 1
  else if (!b) return -1
  else return compareAsc(new Date(a), new Date(b))
}

export const useFavoritesFilter = () => {
  const [favorites, setFavorites] = useState<MMSI[]>([])
  const [isFavoritesFilterActive, setFavoritesFilterActive] = useState<boolean>(false)

  useEffect(() => {
    const subscriptions = [
      isFavoritesFilterActiveSubject.subscribe(setFavoritesFilterActive),
      vesselState.subscribe(({ favorites }) => setFavorites(favorites)),
    ]

    return () => subscriptions.forEach(s => s.unsubscribe())
  }, [isFavoritesFilterActive])

  const handleChangeFavorite = useCallback((mmsi: MMSI, isFavorite: boolean) => {
    if (isFavorite) {
      actions.addFavorite(mmsi)
    } else {
      actions.removeFavorite(mmsi)
    }
  }, [])

  const handleSetFavoritesFilterActive = useCallback(
    (isActive: boolean) => isFavoritesFilterActiveSubject.next(isActive),
    []
  )

  return { isFavoritesFilterActive, favorites, handleChangeFavorite, handleSetFavoritesFilterActive }
}

export const useHandPickedFilter = () => {
  const [isHandPickedFilterActive, setHandPickedFilterActive] = useState<boolean>(false)

  useEffect(() => {
    const subscription = isHandPickedFilterActiveSubject.subscribe(setHandPickedFilterActive)

    return subscription.unsubscribe.bind(subscription)
  }, [])

  const handleSetHandPickedFilterActive = useCallback(
    (isActive: boolean) => isHandPickedFilterActiveSubject.next(isActive),
    []
  )

  return { isHandPickedFilterActive, handleSetHandPickedFilterActive }
}

export const useSubscribedFilter = () => {
  const [isSubscribedFilterActive, setSubscribedFilterActive] = useState<boolean>(false)

  useEffect(() => {
    const subscription = isSubscribedFilterActiveSubject.subscribe(setSubscribedFilterActive)

    return subscription.unsubscribe.bind(subscription)
  }, [])

  const handleSetSubscribedFilterActive = useCallback(
    (isActive: boolean) => isSubscribedFilterActiveSubject.next(isActive),
    []
  )

  return { isSubscribedFilterActive, handleSetSubscribedFilterActive }
}

const combinedTrafficDisplayState = combineLatest(
  trafficDisplayState,
  combineLatest(isFavoritesFilterActiveSubject, isHandPickedFilterActiveSubject, isSubscribedFilterActiveSubject).pipe(
    distinctUntilChanged((prev, next) => prev.toString() === next.toString())
  )
)
  .pipe(
    scan<
      [boolean, [boolean, boolean, boolean]],
      { stored: boolean; display: boolean; filters: [boolean, boolean, boolean] }
    >(
      (acc, [x, filters]) => {
        const isFilterChange = acc.filters.toString() !== filters.toString()
        const display = isFilterChange ? (filters.includes(true) ? false : acc.stored) : x

        return { stored: x, display, filters }
      },
      {
        stored: INITIAL_TRAFFIC_DISPLAY_STATE,
        display: INITIAL_TRAFFIC_DISPLAY_STATE,
        filters: [
          isFavoritesFilterActiveSubject.value,
          isHandPickedFilterActiveSubject.value,
          isSubscribedFilterActiveSubject.value,
        ],
      }
    )
  )
  .pipe(map(({ display }) => display))

export const useTrafficFilter = () => {
  const [isShowingTraffic, setIsShowingTraffic] = useState<boolean>(INITIAL_TRAFFIC_DISPLAY_STATE)

  useEffect(() => {
    const subscription = combinedTrafficDisplayState.subscribe(setIsShowingTraffic)

    return subscription.unsubscribe.bind(subscription)
  }, [])

  const handleSetShowTrafficFilterActive = useCallback((state: boolean) => trafficDisplayState.next(state), [])

  return { isShowingTraffic, handleSetShowTrafficFilterActive }
}

const collectHandPickedVessels = (
  handPickedVisits$: Observable<Visits>,
  handPicked$: Observable<{ handPicked: MMSI[] }>
) =>
  combineLatest(handPicked$, handPickedVisits$).pipe(
    mergeMap(([{ handPicked }, handPickedVisits]) =>
      fetchVesselsByMmsiList(handPicked).pipe(
        map(vesselJson => ({
          handPicked,
          handPickedVisits,
          handPickedVessels: vesselJson
            .filter(json => 'location' in json)
            .map(json => handPickedFromVesselVisit(handPickedVisits[json.mmsi] || undefined, json)),
        }))
      )
    )
  )

const portVisitsWithInterval = (port: Unlocode, fetchVisits: () => Promise<Visit[]>, isHandPickedEnabled: boolean) =>
  updateInterval.pipe(
    switchMap(() =>
      combineLatest(
        portTraffic(port, fetchVisits),
        collectHandPickedVessels(
          isHandPickedEnabled ? handPickedVisits() : of({}),
          isHandPickedEnabled ? vesselState.pipe(distinctUntilChanged()) : of({ handPicked: emptyMMSIs })
        )
      ).pipe(
        map(([{ portVessels, visits, visitsStore }, { handPickedVessels, handPickedVisits, handPicked }]) => {
          return {
            visits,
            visitsStore,
            handPickedVisits,
            mmsis: portVessels.map(({ properties: { mmsi } }) => createMMSI(mmsi)),
            handPicked,
            vessels: {
              handPickedVessels,
              portVessels,
            },
          }
        })
      )
    )
  )

export const useShowCaseTraffic = (port: Unlocode, fetchVisits: () => Promise<Visit[]>) =>
  usePortTraffic(port, fetchVisits, true)

export function usePortTraffic(
  port: Unlocode,
  fetchVisits: () => Promise<Visit[]>,
  isHandPickedEnabled: boolean = false
) {
  const { mapbox } = useMapboxContext()

  const [state, setState] = useState<{
    traffic: TrafficVessel[]
    portcalls: PortVessel[]
    totalPortVessels: PortVessel[]
    handPicked: HandPickedVessel[]
    displayState: VesselDisplayState
  }>({
    portcalls: [],
    handPicked: [],
    traffic: [],
    totalPortVessels: [],
    displayState: VesselDisplayState.ICONS,
  })

  useEffect(() => {
    const subscription = combineLatest(
      portVisitsWithInterval(port, fetchVisits, isHandPickedEnabled),
      combinedTrafficDisplayState.pipe(
        switchMap(isVisible =>
          isVisible ? vesselTraffic(mapbox) : combineLatest(vesselDisplayState(mapbox), of(emptyTrafficVessels))
        )
      ),
      filters,
      isHandPickedFilterActiveSubject,
      selectedVessel.pipe(map(flow(fromEither, flatten)))
    )
      .pipe(debounceTime(750))
      .subscribe(
        ([
          {
            mmsis,
            handPicked,
            vessels: { portVessels, handPickedVessels },
          },
          [displayState, traffic],
          { portcallFilters, handPickedFilters },
          isHandPickedFilterActive,
          selectedVesselJson,
        ]) => {
          const selectedMMSI = pipe(
            selectedVesselJson,
            mapSome(({ mmsi }) => createMMSI(mmsi)),
            toUndefined
          )

          const portcalls =
            isHandPickedEnabled && isHandPickedFilterActive ? [] : filterAndSortPortcals(portVessels, portcallFilters)

          const handPickedFiltered = handPickedVessels.filter(h => handPickedFilters.every(fn => fn(h)))

          let combined: Array<PortVessel | HandPickedVessel> = portcalls
          combined = combined.concat(handPickedFiltered)

          const trafficFiltered = traffic
            .filter(({ properties: { mmsi } }) => {
              const trafficMMSI = createMMSI(mmsi)

              return !(trafficMMSI === selectedMMSI || handPicked.includes(trafficMMSI) || mmsis.includes(trafficMMSI))
            })
            .concat(
              pipe(
                selectedVesselJson,
                fold(
                  () => [],
                  json =>
                    combined.every(({ properties: { mmsi } }) => mmsi !== json.mmsi)
                      ? [trafficVesselFromJson(json)]
                      : []
                )
              )
            )

          renderTraffic(mapbox, displayState, trafficFiltered)
          renderPortcalls(mapbox, displayState, combined)

          setState({
            displayState,
            traffic: trafficFiltered,
            portcalls,
            handPicked: handPickedFiltered,
            totalPortVessels: portVessels,
          })
        }
      )

    return () => {
      subscription.unsubscribe()
      clearPortcalls(mapbox)
      clearTraffic(mapbox)
    }
  }, [port, mapbox, fetchVisits, isHandPickedEnabled])

  return state
}

const emptyFilters: Array<(p: PortVessel) => boolean> = []

const filters = combineLatest<[boolean, boolean, SubscriptionsState, VesselState, Option<Country>, string[], string[]]>(
  [
    isFavoritesFilterActiveSubject,
    isSubscribedFilterActiveSubject,
    subscribtionsState,
    vesselState,
    flagStateFilterSubject,
    vesselTypeFilterSubject,
    vesselStatusFilterSubject,
  ]
).pipe(
  map(
    ([
      isFavoritesFilterActive,
      isSubscribedFilterActive,
      subscriptions,
      { favorites },
      flagState,
      shipTypeCategories,
      vesselStatuses,
    ]) => ({
      portcallFilters: emptyFilters
        .concat(isFavoritesFilterActive ? (p: PortVessel) => favorites.includes(createMMSI(p.properties.mmsi)) : [])
        .concat(
          isSubscribedFilterActive
            ? (p: PortVessel) =>
                pipe(
                  p.properties.portcallId,
                  fromNullable,
                  mapSome(portcallId => findSubscription(portcallId, subscriptions)),
                  isSome
                )
            : []
        )
        .concat(
          vesselStatuses.length ? ({ properties: { status } }: PortVessel) => !vesselStatuses.includes(status) : []
        )
        .concat(
          pipe(
            flagState,
            fold(constant([]), ({ id }) => [
              (p: PortVessel) =>
                pipe(
                  p.properties.country,
                  fold(constFalse, country => country.id === id)
                ),
            ])
          )
        )
        .concat(
          shipTypeCategories.length
            ? ({ properties: { spireVesselType } }: PortVessel) =>
                spireVesselType !== undefined && !shipTypeCategories.includes(spireVesselType)
            : []
        ),
      handPickedFilters: isFavoritesFilterActive
        ? [(p: HandPickedVessel) => favorites.includes(createMMSI(p.properties.mmsi))]
        : [],
    })
  )
)

export const sortPortcalls = ({ primary, secondary }: { primary: PortVessel[]; secondary: PortVessel[] }) =>
  primary
    .sort((a, b) => compareETAs(getBestArrivalTime(a.properties)?.value, getBestArrivalTime(b.properties)?.value))
    .concat(
      secondary.sort((a, b) =>
        compareETAs(getBestArrivalTime(a.properties)?.value, getBestArrivalTime(b.properties)?.value)
      )
    )

type Filter = (p: PortVessel) => boolean
export const filterAndSortPortcals = (portcalls: PortVessel[], activeFilters: Filter[]) => {
  const partitionedPortcalls: { primary: PortVessel[]; secondary: PortVessel[] } = { primary: [], secondary: [] }

  portcalls.forEach(p => {
    // exclude
    if (!activeFilters.every(fn => fn(p))) {
      return
    }
    //partition
    partitionedPortcalls[getBestArrivalTime(p.properties)?.value ? 'primary' : 'secondary'].push(p)
  })

  return sortPortcalls(partitionedPortcalls)
}
