// Libs
import { constVoid } from 'fp-ts/es6/function'
import React, { PropsWithChildren } from 'react'
import classnames from 'classnames'

// Styles
import styles from './Checkbox.module.scss'

type CheckboxProps = PropsWithChildren<{
  label?: string
  checked?: boolean
  disabled?: boolean
  onChange?: (checked: boolean) => void
  className?: string
  isLeftToRight?: boolean
  testId?: string
}>

const CheckMarkIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" id="svg14" version="1.1" viewBox="0 0 18 18" height="18px" width="18px">
    <title id="title2">Element/Checkbox/Selected</title>
    <desc id="desc4">Created with Sketch.</desc>
    <polygon id="Path" points="7,11.307692 14.6,4 16,5.3461539 7,14 2,9.1923077 3.4,7.8461539 " fill="#fff" />
  </svg>
)

type CheckmarkProps = PropsWithChildren<CheckboxProps>

export const Checkmark = ({ className, disabled, checked = false, children }: CheckmarkProps) => (
  <label className={classnames(styles.checkbox, className, { [styles.disabled]: disabled })}>
    <span className={classnames(styles.checkboxButton, { [styles.checkboxChecked]: checked })}>
      {checked && <CheckMarkIcon />}
    </span>
    {children}
  </label>
)

export const Checkbox = ({
  children,
  className,
  checked = false,
  disabled = false,
  onChange = constVoid,
  testId,
}: CheckboxProps) => (
  <Checkmark className={className} disabled={disabled} checked={checked}>
    <input
      data-testid={testId}
      type="checkbox"
      checked={checked}
      onChange={disabled ? constVoid : () => onChange(!checked)}
    />
    {children}
  </Checkmark>
)
