import classnames from 'classnames'
import { getYear } from 'date-fns'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Pages, PXP_HOMEPAGE_URL } from '../../constants'
import { useDarkMode } from '../../lib/hooks/useDarkMode'
import { Feature, useFeatureToggler } from '../../lib/hooks/useFeatureToggler'
import { useTranslation } from '../../lib/i18n'
import { Toggle } from '../../UI/Toggle/Toggle'
import styles from './MenuNavigation.module.scss'

type MenuNavigationProps = { isOpen: boolean; onClose: () => void }
export const MenuNavigation: React.FC<MenuNavigationProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation()
  const { isDarkMode, setDarkMode } = useDarkMode()
  const { isEnabled: hasMapAccess } = useFeatureToggler(Feature.PORTCALLS)

  const currentYear = useMemo(() => getYear(new Date()), [])

  return (
    <>
      {isOpen && <div className={styles.menuNavigationOverlay} onClick={onClose}></div>}
      <nav className={classnames(styles.menuNavigation, { [styles.menuNavigationOpen]: isOpen })}>
        <ul className={classnames(styles.section, styles.sectionTop)}>
          {hasMapAccess && (
            <li className={styles.entry}>
              <Link onClick={onClose} to={Pages.MAP} className={styles.inner}>
                <label className={styles.text}>{t('ShipTracker.Menu.ShowMap')}</label>
              </Link>
            </li>
          )}
          <li className={styles.entry}>
            <Link onClick={onClose} to={Pages.SUPPORT} className={styles.inner}>
              <span className={styles.text}>{t('ShipTracker.Menu.Support')}</span>
            </Link>
          </li>
        </ul>
        <ul className={classnames(styles.section, styles.sectionBottom)}>
          <li className={classnames(styles.entry, styles.inner)}>
            <label className={styles.text}>{t('ShipTracker.Menu.DarkMode')}</label>
            <Toggle isChecked={isDarkMode} handleToggle={setDarkMode} />
          </li>
          <li className={styles.entry}>
            <Link to={Pages.LEGAL_TERMS} className={styles.inner}>
              <span className={styles.text}>{t('ShipTracker.Menu.LegalTerms')}</span>
            </Link>
          </li>
          <li className={classnames(styles.entry, styles.entrySmall, styles.entryInactive, styles.inner)}>
            <span className={styles.text}>
              &copy; {currentYear} Shiptracker by{' '}
              <a href={PXP_HOMEPAGE_URL} target="_blank" rel="noopener noreferrer">
                PortXchange
              </a>
            </span>
          </li>
        </ul>
      </nav>
    </>
  )
}
