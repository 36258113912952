import React, { useEffect } from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { toast } from 'react-toastify'
import { NotSignedInToast, DURATION } from './UI/Toast'
import { useAuth0 } from '@auth0/auth0-react'
import { Pages } from './constants'

export function PrivateRoute(props: RouteProps) {
  const { isLoading, isAuthenticated } = useAuth0()

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      toast.info(<NotSignedInToast />, { autoClose: DURATION.MEDIUM })
    }
  }, [isLoading, isAuthenticated])

  const { children } = props

  if (isLoading) {
    return <Route {...props} />
  }

  if (isAuthenticated) {
    return <Route {...props}>{children}</Route>
  }

  return (
    <Route {...props}>
      <Redirect to={`${Pages.LOGIN}?from=${props.location?.pathname ?? Pages.MAIN}`} />
    </Route>
  )
}
