import { defer, Observable } from 'rxjs'
import { shareReplay } from 'rxjs/operators'
import { toPromise } from './utils'
import { Option, none, fold, some } from 'fp-ts/es6/Option'
import { pipe } from 'fp-ts/es6/function'

const cacheRequest = <A>(request: () => Promise<A>) => defer(request).pipe(shareReplay(1))

export function createCachedRequest<A>(request: () => Promise<A>, period?: number) {
  let state: Option<Observable<A>> = none

  const clearCache = () => (state = none)

  function reload() {
    const cachedRequest = cacheRequest(request)

    state = some(cachedRequest)

    const promise = toPromise(cachedRequest)

    if (period) {
      promise.then(() => setTimeout(clearCache, period))
    }

    return promise
  }

  return {
    fetch: () => pipe(state, fold(reload, toPromise)),
    clearCache,
  }
}
