import React, { PropsWithChildren } from 'react'
import classnames from 'classnames'
import styles from './SelectOptionList.module.scss'

type MenuProps = PropsWithChildren<{ getProps: () => React.HTMLProps<HTMLUListElement>; isOpen: boolean }>

export const Menu = ({ getProps, isOpen, children }: MenuProps) => (
  <ul {...getProps()} className={classnames(styles.menu, { [styles.open]: isOpen })}>
    {children}
  </ul>
)
