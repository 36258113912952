import { Layer } from 'mapbox-gl'
import { PRONTO_MAPBOX_TOKEN } from '../config'

export const DEFAULT_CENTER: [number, number] = [4.063651, 51.986117] // NLRTM

export const MAPBOX_LIGHT_STYLE_ID = 'hbr-pronto/ckkpahgps0ppg17npqs3d457z'
export const MAPBOX_DARK_STYLE_ID = 'hbr-pronto/ckko6dgeh6e9d17nwf52v7bp9'
export const MAPBOX_SATELLITE_STYLE_ID = 'hbr-pronto/ckya6d490ijx514tlgm89u3m2'
export const MAPBOX_LIGHT_STYLE = `mapbox://styles/${MAPBOX_LIGHT_STYLE_ID}`
export const MAPBOX_DARK_STYLE = `mapbox://styles/${MAPBOX_DARK_STYLE_ID}`

export const MAP_OPTIONS = {
  accessToken: PRONTO_MAPBOX_TOKEN,
  zoom: 3,
  center: DEFAULT_CENTER,
}

export const INITIAL_ZOOM = 9

export const MAP_LAYERS: Layer[] = [
  {
    id: 'traffic-layer',
    source: 'traffic-source',
    type: 'symbol',
    layout: {
      'icon-allow-overlap': true,
      'icon-image': {
        property: 'iconName',
        type: 'identity',
      },
      'icon-rotate': {
        property: 'heading',
        type: 'identity',
      },
    },
  },

  {
    id: 'portCalls-layer',
    source: 'portCalls-source',
    type: 'symbol',
    layout: {
      'icon-allow-overlap': true,
      'icon-image': {
        property: 'iconName',
        type: 'identity',
      },
      'icon-rotate': {
        property: 'heading',
        type: 'identity',
      },
    },
  },
  {
    id: 'traffic-shapes-layer',
    type: 'fill',
    source: 'traffic-shapes-source',
    layout: {},
    paint: {
      'fill-color': '#f39900',
      'fill-outline-color': '#b27000',
      'fill-opacity': 0.95,
    },
  },
  {
    id: 'portcall-shapes-layer',
    type: 'fill',
    source: 'portcall-shapes-source',
    layout: {},
    paint: {
      'fill-color': '#46be41',
      'fill-outline-color': '#74ab35',
      'fill-opacity': 0.95,
    },
  },
  {
    id: 'hoverMarker-layer',
    source: 'hoverMarker-source',
    type: 'circle',
    paint: {
      'circle-radius': 6,
      'circle-color': '#ffffff',
      'circle-stroke-opacity': 0.5,
      'circle-stroke-color': '#001f4b',
      'circle-stroke-width': 3,
    },
  },
]
