import { Auth0Provider } from '@auth0/auth0-react'
import ReactDOM from 'react-dom'
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, BACKEND_URL } from './config'
import { ShipTrackerRouter } from './Router'
import './styles/base.module.scss'
import './styles/theme.module.scss'
import 'overlayscrollbars/css/OverlayScrollbars.css'
import { ResponsivenessProvider } from './lib/hooks/useResponsiveness'
import { PortalProvider } from './lib/hooks/usePortal'
import { ToastContainer } from 'react-toastify'
import { WithAuth0Loaded } from './Auth/WithAuth0Loaded'

ReactDOM.render(
  <>
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      audience={`${BACKEND_URL}/api/`}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      redirectUri={window.location.origin}
    >
      <WithAuth0Loaded>
        <ResponsivenessProvider>
          <PortalProvider id="portals">
            <ShipTrackerRouter />
          </PortalProvider>
        </ResponsivenessProvider>
      </WithAuth0Loaded>
    </Auth0Provider>
    <ToastContainer position="bottom-center" />
  </>,
  document.getElementById('root')
)
