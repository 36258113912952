import { useEffect, useState } from 'react'
import { createStore } from '../../lib/localStorage'
import { BehaviorSubject } from 'rxjs'
import { constant, flow, pipe } from 'fp-ts/es6/function'
import { fromNullable, fold } from 'fp-ts/es6/Option'

const cookieStore = createStore('cookieConsent')
const initialCookieSettings = pipe(
  cookieStore.getItem(),
  fromNullable,
  fold(constant<boolean>(false), cookieConsent => cookieConsent === 'true')
)

const cookie$ = new BehaviorSubject<boolean>(initialCookieSettings)

cookie$.subscribe(flow(JSON.stringify, cookieStore.setItem))

export const useCookieSettings = () => {
  const [areCookiesEnabled, setCookiesEnabled] = useState(initialCookieSettings)

  useEffect(() => {
    const subscriber = cookie$.subscribe(setCookiesEnabled)

    return subscriber.unsubscribe.bind(subscriber)
  }, [])

  return {
    areCookiesEnabled,
    setCookiesEnabled: cookie$.next.bind(cookie$),
  }
}
