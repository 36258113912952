import classNames from 'classnames'
import { ErrorMessage, useField } from 'formik'
import React from 'react'
import { InputError } from '../InputError/InputError'
import styles from './FormikInputField.module.scss'

type FormikInputFieldProps = {
  label: string
  name: string
  placeholder?: string
  type: string
  disabled?: boolean
  readOnly?: boolean
}
export const FormikInputField = ({ label, ...inputProps }: FormikInputFieldProps) => {
  const [field, meta] = useField(inputProps)
  return (
    <>
      <label className={styles.label}>{label}</label>
      <input
        {...field}
        {...inputProps}
        placeholder={inputProps.placeholder ?? label}
        className={classNames(styles.input, {
          [styles.error]: meta.touched && meta.error,
          [styles.readOnly]: inputProps.readOnly,
        })}
      />
      <ErrorMessage name={inputProps.name} component={InputError} />
    </>
  )
}
