import React from 'react'
import { Formik, Field, ErrorMessage } from 'formik'
import classnames from 'classnames'
import { object as YupObject, string as YupString } from 'yup'

import { Metadata } from '../../Domain/User'
import { useTranslation } from '../../lib/i18n'
import { InputError } from '../InputError'
import { useFromFetch } from '../../lib/useFromFetch'
import { useHistory } from 'react-router-dom'
import { Pages } from '../../constants'
import { InlineLoader } from '../../UI/Loader/InlineLoader'
import { store } from '../../dataStore'
import { useAuth0 } from '@auth0/auth0-react'
import styles from './UserProfile.module.scss'
import { useDeleteAccount } from '../useDeleteAccount'

const validationSchema = YupObject<Metadata>().shape({
  givenName: YupString().required('Required'),
  familyName: YupString().required('Required'),
})

export function ShowCaseUserProfile() {
  const { handleDeleteAccount, ModalContainer } = useDeleteAccount()
  const { user } = useAuth0()
  const { isLoading, data } = useFromFetch(store.metadata.fetch)
  const history = useHistory()
  const { t } = useTranslation()

  const initialValues: Partial<Metadata> = {
    givenName: data?.givenName ?? '',
    familyName: data?.familyName ?? '',
  }

  return (
    <>
      {isLoading && (
        <>
          <InlineLoader /> {t('ShipTracker.Common.Loading')}
        </>
      )}
      {!isLoading && data && user && (
        <Formik
          initialValues={initialValues}
          onSubmit={async ({ givenName, familyName }) => {
            await store.metadata.update({ givenName, familyName })

            history.push(Pages.MAIN)
          }}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, errors, touched, isSubmitting, dirty }) => {
            return (
              <div className={styles.accountForm}>
                <label
                  className={classnames(styles.inputField, {
                    [styles.formError]: 'givenName' in errors && 'givenName' in touched,
                  })}
                >
                  <div className={styles.label}>{t('ShipTracker.Account.UserProfile.FirstName')}</div>
                  <Field type="text" name="givenName" placeholder={t('ShipTracker.Account.UserProfile.FirstName')} />
                  <ErrorMessage name="givenName" component={InputError} />
                </label>
                <label
                  className={classnames(styles.inputField, {
                    [styles.formError]: 'familyName' in errors && 'familyName' in touched,
                  })}
                >
                  <div className={styles.label}>{t('ShipTracker.Account.UserProfile.LastName')}</div>
                  <Field type="text" name="familyName" placeholder={t('ShipTracker.Account.UserProfile.LastName')} />
                  <ErrorMessage name="familyName" component={InputError} />
                </label>
                <label className={styles.inputField}>
                  <div className={styles.label}>Email address</div>
                  <input className={styles.readOnly} type="text" name="email" readOnly value={user.email} />
                </label>
                <button
                  disabled={isLoading || isSubmitting || !dirty}
                  type="submit"
                  onClick={() => handleSubmit()}
                  className={styles.submitButton}
                >
                  {t('ShipTracker.Account.UserProfile.SaveChanges')}
                </button>
              </div>
            )
          }}
        </Formik>
      )}
      <a className={styles.deleteShowCaseAccount} href="#delete" onClick={handleDeleteAccount}>
        {t('ShipTracker.Account.Subscription.DeleteMyAccount')}
      </a>
      <ModalContainer />
    </>
  )
}
