import React from 'react'
import classnames from 'classnames'
import { useTranslation } from '../../../../lib/i18n'
import { Input, Menu, Item } from '../../../../UI/Select'
import Downshift, { GetInputPropsOptions } from 'downshift'
import { CountrySearchResult, fuzzyCountrySearch, Country } from '../../../../Domain/Countries'
import { useSearchState } from '../../../../lib/hooks/useSearchState'
import { InlineLoader } from '../../../../UI/Loader/InlineLoader'
import styles from './CountriesDropdown.module.scss'
import { fold, getOrElse, Option, fromNullable, none } from 'fp-ts/es6/Option'
import { constant, pipe, constFalse, constNull, flow } from 'fp-ts/es6/function'

type CountriesDropdownProps = {
  className?: string
  placeholder: string
  flagState: Option<Country>
  setFlagState: (o: Option<Country>) => void
  availableCountryCount: { [key: number]: number }
}

const MINIMUM_TERM_LENGTH = 2

export function CountriesDropdown({
  flagState,
  setFlagState,
  className,
  placeholder,
  availableCountryCount,
}: CountriesDropdownProps) {
  const { t } = useTranslation()

  const { searchState, onQueryChange } = useSearchState<CountrySearchResult[]>(fuzzyCountrySearch, {
    debounceTime: 700,
    minimumQueryLength: MINIMUM_TERM_LENGTH,
  })

  const results = searchState.kind === 'LOADED' ? searchState.results.map(result => result.item) : []

  return pipe(
    flagState,
    fold(
      () => (
        <Downshift
          initialInputValue={pipe(
            flagState,
            fold(constant(''), ({ name }) => name)
          )}
          itemToString={item => item?.name || ''}
          onChange={flow(fromNullable, setFlagState)}
          selectedItem={pipe(flagState, getOrElse<Country | null>(constNull))}
        >
          {({ isOpen, openMenu, closeMenu, getMenuProps, getItemProps, getInputProps, highlightedIndex }) => (
            <div className={classnames(styles.countriesDropdown, className)}>
              <label className={styles.label}>
                {t('ShipTracker.VesselListing.FilterFlagState')}
                <Input
                  icon={'search'}
                  className={styles.input}
                  getProps={() =>
                    getInputProps<GetInputPropsOptions>({
                      placeholder,
                      onInput: e => onQueryChange(e.currentTarget.value),
                      onClick: () => {
                        isOpen ? closeMenu() : openMenu()
                      },
                    })
                  }
                />
              </label>
              <Menu isOpen={isOpen} getProps={getMenuProps}>
                {isOpen && searchState.kind === 'INITIAL_SEARCH' && (
                  <Item>{t('ShipTracker.VesselListing.CountriesDropdown.startTyping')}</Item>
                )}
                {isOpen && searchState.kind === 'COMPOSING_QUERY' && (
                  <Item>{t('ShipTracker.VesselListing.CountriesDropdown.minimumLength', MINIMUM_TERM_LENGTH)}</Item>
                )}
                {isOpen && searchState.kind === 'LOADING' && (
                  <Item>
                    <InlineLoader /> {t('ShipTracker.VesselListing.CountriesDropdown.loading')}
                  </Item>
                )}
                {isOpen &&
                  searchState.kind === 'LOADED' &&
                  results.map((country, index) => (
                    <Item
                      isHighlighted={
                        highlightedIndex === index ||
                        pipe(
                          flagState,
                          fold(constFalse, ({ id }) => country.id === id)
                        )
                      }
                      key={country.id}
                      getProps={() => getItemProps({ item: country, index })}
                    >
                      {country.name}&nbsp;({availableCountryCount[country.id] || 0})
                    </Item>
                  ))}
              </Menu>
            </div>
          )}
        </Downshift>
      ),
      ({ name }) => (
        <div className={classnames(styles.countriesDropdown, className)}>
          <label className={styles.label}>
            {t('ShipTracker.VesselListing.FilterFlagState')}
            <Input
              className={styles.input}
              getProps={() => ({
                value: name,
                readOnly: true,
                onClick: () => setFlagState(none),
              })}
              icon="clear"
            />
          </label>
        </div>
      )
    )
  )
}
