import classnames from 'classnames'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import React from 'react'
import { SHIP_TRACKER_THEME_CSS, SHIP_TRACKER_THEME_DARK_CSS } from '../constants'
import Header from '../Header/Header'
import { useDarkMode } from '../lib/hooks/useDarkMode'
import { SUPPORT_EMAIL_ADDRESS } from '../constants'
import styles from './LegalTermsPage.module.scss'

export const LegalTerms = ({ className }: { className?: string }) => (
  <div className={classnames(styles.terms, className)}>
    <p>
      Please read these terms of use carefully as they apply to your access and/or use of any information, data, or
      features/functions (<strong>“Services”</strong>) included on this website. By accessing this website or by
      creating an account (including but not limited to submitting your electronic acceptance of these terms of use by
      checking the box prompting you to accept these terms of use on the relevant account registration page), you (or{' '}
      <strong>“Customer”</strong>) acknowledge your understanding of and your agreement with these terms of use. If you
      do not agree to these terms and conditions, please do not access this website. These terms and conditions may be
      changed at any time without notice.
    </p>
    <p>
      The Services and the website are provided by PortXChange Products B.V., with its registered office at
      Stationsplein 45, 3013 AK Rotterdam and registered under KVK number 72868007 (<strong>“PXP”</strong>).
    </p>
    <p>These terms of use refer to the following additional terms, which also apply to your use of our site:</p>
    <ul>
      <li>
        Our Privacy Statement{' '}
        <a href="https://port-xchange.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
          https://port-xchange.com/privacy-policy/
        </a>
        . See further under How we may use your personal information.
      </li>
      <li>
        Our Cookie Policy{' '}
        <a href="https://port-xchange.com/cookie-policy/" target="_blank" rel="noopener noreferrer">
          https://port-xchange.com/cookie-policy/
        </a>
        , which sets out information about the cookies on our site.
      </li>
    </ul>
    <h2>1. Use of the website</h2>
    <h3>1.1.</h3>
    <p>
      You shall use the information and data on the website for your own internal use only. Information and data on this
      website is provided on an “as is” basis. PXP endeavours to keep the information and data current and accurate, but
      does not make any representations or warranties of any kind, express or implied, about the completeness, accuracy,
      reliability, suitability or availability with respect to the website, or the data and information, Services or
      content on it for any purpose. Any reliance placed on such information is therefore strictly at your own risk. In
      no event will PXP be liable for any loss or damage including without limitation, indirect or consequential loss or
      damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection
      with, the use of this website or its tools.
    </p>
    <h3>1.2.</h3>
    <p>
      PXP does not warrant that the website, information, data or its functions will be uninterrupted and/or error-free,
      that defects will be corrected or that they are free from computer viruses or harmful components.
    </p>
    <h3>1.3.</h3>
    <p>You may use the website only for lawful purposes. You may not use the website:</p>
    <ol type="i">
      <li>in any way that breaches any applicable local, national or international law or regulation,</li>
      <li>to transmit, or procure the sending of spam,</li>
      <li>
        to knowingly transmit any data, send or upload any material that contains viruses or any other harmful programs
        designed to adversely affect the operation of any computer software or hardware.
      </li>
    </ol>
    <h3>1.4.</h3>
    <p>
      You may not access this website through automated or programmatic means (such as through a content scraper)
      without prior permission of PXP.
    </p>
    <h3>1.5.</h3>
    <p>
      Throughout the duration of the terms of use and upon termination thereof, for whatever reason, you shall not,
      directly or indirectly or permit any of your employees and/or contractors: (a) to be involved in the development
      or marketing of products or services that are in competition with the Service, the website or any spin-offs
      thereof; (b) to develop a product or service that is equivalent to or corresponds with the Service or website.
    </p>
    <h3>1.6.</h3>
    <p>
      This website is not directed to any person in any jurisdiction where (by reason of that person’s nationality,
      residence, citizenship or otherwise) the publication or availability of this website and its content is
      contradictory to local laws or regulations. Persons to whom such prohibitions apply may not access this website.
    </p>
    <h2>2. Access to the website</h2>
    <h3>2.1.</h3>
    <p>You must keep your account details safe.</p>
    <h3>2.2.</h3>
    <p>
      If you choose, or you are provided with, a user identification code, password or any other piece of information as
      part of our security procedures, you must treat such information as confidential. You must not disclose it to any
      third party.
    </p>
    <h3>2.3.</h3>
    <p>
      PXP has the right to disable any user identification code or password, whether chosen by you or allocated by PXP,
      at any time, if in PXP’s reasonable opinion you have failed to comply with any of the provisions of these terms of
      use.
    </p>
    <h3>2.4.</h3>
    <p>
      If you know or suspect that anyone other than you knows your user identification code or password, you must
      promptly notify PXP at <a href={`mailto:${SUPPORT_EMAIL_ADDRESS}`}>{SUPPORT_EMAIL_ADDRESS}</a>.
    </p>
    <h2>3. Services</h2>
    <h3>3.1.</h3>
    <p>
      Subject to the provisions of the terms of use, PXP hereby grants you for the duration of the terms of use a
      non-exclusive and non-transferable right to execute and monitor the Service, and you hereby accept said right of
      use. The Service provided on a Free or Premium plan has features referred to in that plan on a “Subscriptions”
      page of the website.
    </p>
    <h3>3.2.</h3>
    <p>
      Except as otherwise set forth herein, the you may not use the Services, information and data on the website for
      any illegal purpose or in any manner inconsistent with the terms of use. Except as expressly permitted pursuant to
      the terms of use, you may not copy, resell, decompile, disassemble, reverse engineer, distribute, publish,
      display, modify, upload, create derivative works from the information or the data on the website.
    </p>
    <h3>3.3.</h3>
    <p>
      You are not allowed to (sub)licence and/or transfer the user right granted by PXP as referred to in Article 2.1
      above or to make this available in any other manner to a third party.
    </p>
    <h3>3.4.</h3>
    <p>When making the Service available, PXP shall have the right to assign third parties.</p>
    <h3>3.5. </h3>
    <p>
      You agree that nothing in these terms of use constitutes an undertaking by PXP to provide the information, data or
      Services in its present form or under any specifications. PXP, in its sole discretion may from time to time make
      additions to, deletions from, modifications to, or change the format and features of the information, data or
      Services and to arrange for such to be done.
    </p>
    <h3>3.6. </h3>
    <p>
      In case PXP is obliged to alter, modify or reduce the extend of Service provided through the website, due to the
      implementation of restrictions of applicable law on ship trafficking systems, PXP shall bear no responsibility and
      shall not be obliged to full or partial refund of any fee.
    </p>
    <h3>3.7.</h3>
    <p>
      PXP may shut down the Service (temporarily) for the purpose of developing the Service and/or the website and/or
      the requisite ancillary systems and/or when dealing with any faults, also for administration and maintenance
      purposes and/or in case of unauthorised use, may restrict the functionality of the website and/or the Service as
      and when necessary. PXP shall not be held liable for any loss or damage of any kind which may ensue from such
      (temporary) shutdown.
    </p>
    <h3>3.8.</h3>
    <p>
      The subscription on a Free plan is active for 30 (thirty) calendar days starting from the date when the User has
      chosen the plan. The subscription the Premium plan has the duration referred to in that plan.
    </p>
    <h3>3.9.</h3>
    <p>
      If you subscribe for the Premium plan, you shall pay the applicable fees for the services provided under these
      plans as they become due including all applicable taxes. Your obligation to pay fees continues through the end of
      the subscription period during which you cancel your subscription. You may cancel your Premium plan following the
      instructions in the relevant section of the website.
    </p>
    <h2>4. User data</h2>
    <h3>4.1. </h3>
    <p>
      This website may include information and data uploaded by other users of the website. This information and these
      materials have not been verified or approved by PXP. PXP is under no obligation to oversee, monitor or moderate
      any information or data uploaded by other users, and PXP expressly excludes its liability for any loss or damage
      arising from the use of any information or data uploaded by other users, whether the information or data are
      moderated or not.
    </p>
    <h3>4.2. </h3>
    <p>
      Any information or data which you upload to the website will be considered non-confidential. You grant PXP and
      other users of the website a non-exclusive license to use, store, copy, process, publish that information and data
      and to distribute and make it available to third parties.
    </p>
    <h3>4.3. </h3>
    <p>
      PXP has the right to remove any information or data you uploaded on the website if, in PXP’s sole discretion, the
      information or data do not comply with these terms of use.
    </p>
    <h3>4.4. </h3>
    <p>You are solely responsible for securing and backing up your information and data.</p>
    <h2>5. Intellectual property rights</h2>
    <h3>5.1. </h3>
    <p>
      PXP is the owner or the licensee of all intellectual property rights in the website, and of the information and
      data published on it. Those works are protected by intellectual property laws and treaties around the world. All
      such rights are reserved.
    </p>
    <h3>5.2. </h3>
    <p>
      You undertake not to challenge these intellectual property rights of PXP, either throughout the duration of the
      terms of use, or upon termination thereof.
    </p>
    <h3>5.3.</h3>
    <p>
      No intellectual property rights will be transferred by the parties within the framework of these terms of use
      and/or the usage of the Service.
    </p>
    <h2>6. Indemnity</h2>
    <h3>6.1. </h3>
    <p>
      You shall indemnify PXP, its directors, shareholders and employees against third-party legal actions, including
      any loss or damage and (legal) costs incurred or payable by PXP in connection with such claims, where arising
      from:
    </p>
    <h4>6.1.1. </h4>your failure wholly or in part to meet your obligations arising under the term of use,
    <h4>6.1.2. </h4>use of the Service, Data, the website not in accordance with the terms of use,
    <h4>6.1.3. </h4>use by PXP of the information and data uploaded by you on the website.
    <h3>6.2.</h3>
    <p>
      Any such legal actions as referred to in section 6.1 will be brought to your attention by PXP within a reasonable
      period. Prior to reaching any settlement with a third party, you will be required to have permission in writing
      from PXP.
    </p>
    <h2>7. Liability</h2>
    <h3>7.1. </h3>
    <p>
      The liability of each party ensuing from any claim in connection with the use of the Service, for whatever reason,
      with the exception of a breach by the Customer of sections 1, 3, 4, and 5, will be limited to direct loss or
      damage up the maximum paid for the relevant Service during the twelve (12) months preceding the month in which the
      damage was caused.
    </p>
    <h3>7.2. </h3>
    <p>
      Neither party shall be liable in respect of any indirect loss or damage including consequential loss, loss of
      profits, lost savings, diminished goodwill, loss due to business interruption or ensuing from claims by the other
      party’s customers, corruption or loss of data and/or other data, damage in connection with the use of third-party
      items, materials or software stipulated by the other party, damage ensuing from suppliers stipulated by the
      Customer to PXP and any kind of loss, ensuing for whatever reason.
    </p>
    <h3>7.3. </h3>
    <p>
      The limitations and exclusions of liability provided for in these terms of use will be nullified if and in so far
      as the loss or damage is due to wilful misconduct or gross negligence on the part of the party causing the damage.
      Moreover, the limitations and exclusions of liability in these terms of use will not apply with respect to the
      indemnities provided herein.
    </p>
    <h3>7.4. </h3>
    <p>
      PXP shall not be held liable for any losses incurred and/or to be incurred by the Customer as a result of the
      Customer’s failure, in the case of an emergency, to comply wholly or otherwise with the instructions given by
      PortXchange or another competent authority.
    </p>
    <h2>8. Force majeure</h2>
    <h3>8.1. </h3>
    <p>
      Notwithstanding PXP’s obligation to use its best endeavours as is expected of a responsible contracted party,
      neither party will be obliged to comply with one or more obligations, other than obligations to pay money, if
      hindered in doing so due to force majeure. The term force majeure is taken to mean fire, explosion, power
      failures, earthquake, flooding, severe storms, strikes, embargoes, terrorism (including cyberterrorism), natural
      disasters, actions or omissions on the part of Internet traffic services, actions or omissions on the part of
      regulating authorities, including the implementation of laws and regulations or other government activities which
      impact on the availability of the Service.
    </p>
    <h2>9. Data security and Privacy</h2>
    <h3>9.1. </h3>
    <p>
      Each Party shall take appropriate technical and organisational measures to safeguard their information and data
      against loss or any form of unlawful access. These measures must guarantee, having regard to the state of the art
      and the cost of their implementation, a level of security appropriate to the risks represented by the Service and
      the nature of the information and data to be protected.
    </p>
    <h3>9.2. </h3>
    <p>
      Where a Service contains personal data, the Parties shall take measures and/or shall make arrangements if required
      – in line with the applicable national and international privacy legislation and regulations (including in any
      event the General Data Protection Regulation, after 25 May 2018), having regard to the fact that such laws and
      regulations are amended, extended and re-enacted from time to time – to warrant the appropriate technical and
      organisational security and processing of these personal data.
    </p>
    <h3>9.3. </h3>
    <p>
      By using the Service, both the Customer and the User declare that they have taken cognisance of and agree with the
      content of PXP’s privacy statement as published on the website. PortXchange may adapt its privacy statement from
      time to time.
    </p>
    <h2>10. Termination </h2>
    <h3>10.1. </h3>
    <p>
      Each party has the right to terminate the terms of use effective from the end of a calendar month subject to a
      notice period of three (1) month. Such notice must be provided in writing.
    </p>
    <h3>10.2. </h3>
    <p>
      PXP may terminate the Agreement wholly or in part, with immediate effect and with no liability attached to PXP for
      any loss or damage to the Customer, where:
    </p>
    <h4>10.2.1. </h4>
    any change in the laws and regulations or arrangements result in PXP no longer being able to access the requisite
    information or data and/or make this available to third parties, as a result of which PXP will no longer be in a
    position to meet its obligations under the terms of use.
    <h4>10.2.2. </h4>
    any operational risks and/or security risks in connection with the Service are deemed by PXP to have become or
    expected to become unacceptable for PXP or one or more of the Customers.
    <h4>10.2.3. </h4>
    the Customer is in breach of the provisions of these terms of use.
    <h4>10.2.4. </h4>
    the Customer does something or allows something to be done which PXP believes causes or may cause the use of the
    Service to be jeopardized.
    <h3>10.3. </h3>
    <p>
      Any (application for a) (temporary) suspension of payments or bankruptcy, closing down or liquidation of the
      business of a party will entitle the other party to terminate the terms of use with immediate effect, wholly or in
      part, in which case said other party shall not be held liable for compensation.
    </p>
    <h3>10.4. </h3>
    <p>
      Upon such termination all claims by the said terminating Party against the other Party will be payable forthwith
      and in full. The other Party shall be obliged to take whatever measures are necessary to facilitate the
      terminating Party in exercising its rights; (ii)PortXchange shall withdraw the availability of the Service
      forthwith.
    </p>
    <h2>11. Concluding provisions</h2>
    <h3>11.1. </h3>
    <p>
      Should any provision in the terms of use be declared to be invalid, voidable or otherwise unenforceable, the
      remaining provisions of the terms of use will remain in full force. In such circumstances, the parties will
      replace the invalid part of the terms of use by agreement with provisions that are valid and in respect of which
      the legal consequences, having regard to the content and scope of the terms of use, correspond as much as possible
      with those of the invalid part.
    </p>
    <h3>11.2. </h3>
    <p>No verbal commitment or arrangement will be deemed valid unless confirmed in writing by the other Party.</p>
    <h3>11.3. </h3>
    <p>
      PXP may transfer its rights and obligations under these terms of use to another organisation. PXP will always tell
      you in writing if this happens and we will ensure that the transfer will not affect your rights under the
      contract.
    </p>
    <h2>12. Applicable law and settlement of disputes</h2>
    <h3>12.1. </h3>
    <p>
      The Terms of use will be governed exclusively by Dutch law. The same applies with respect to each non-contractual
      obligation arising from and connected with the Terms of use.
    </p>
    <h3>12.2. </h3>
    <p>
      Any dispute arising from or in connection with the Terms of use, including any dispute concerning the existence
      and validity thereof and any non-contractual obligations, will in the first instance be referred to the competent
      court in Rotterdam.
    </p>
  </div>
)

export const LegalTermsPage: React.FC = () => {
  const { isDarkMode } = useDarkMode()

  return (
    <main
      className={classnames(styles.legalTermsPage, SHIP_TRACKER_THEME_CSS, {
        [SHIP_TRACKER_THEME_DARK_CSS]: isDarkMode,
      })}
    >
      <Header />
      <OverlayScrollbarsComponent className={styles.legalTermsScrollable}>
        <div className={styles.content}>
          <h1>Terms of use</h1>
          <LegalTerms />
        </div>
      </OverlayScrollbarsComponent>
    </main>
  )
}
