import { useEffect } from 'react'
import './lib/i18n'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import ShipTracker from './ShipTracker'
import { Pages } from './constants'
import { useAuth0 } from '@auth0/auth0-react'
import { initializeClient } from './Auth/client'
import { ShowCaseApp } from './Account/App'
import { PrivateRoute } from './PrivateRoute'
import { useHandleAuthError } from './Auth/useHandleAuthError'
import { useHandleEmailVerificationSuccess } from './Auth/useHandleEmailVerificationSuccess'
import { LegalTermsPage } from './Generic/LegalTermsPage'
import { SupportPage } from './Generic/SupportPage'
import { LegacyRedirectSupport } from './LegacyRedirectSupport'
import { HandleOnboardingRedirect } from './Auth/HandleOnboardingRedirect'
import { CreateAccountOnboarding } from './Account/CreateAccount/CreateAccountOnboarding'
import { LoginPage } from './Account/LoginPage/LoginPage'
import { PublicOnlyRoute } from './PublicOnlyRoute'
import { useCustomStyleSheet } from './lib/hooks/useCustomStylesheet'

const NotFound = () => <Route>404: Page not found</Route>

const RoutesWithTracking = ({ user }: { user?: { email?: string } }) => {
  useHandleEmailVerificationSuccess()

  return (
    <Switch>
      <Route exact path={Pages.MAIN}>
        <HandleOnboardingRedirect>
          <LegacyRedirectSupport>
            <Redirect to={Pages.MAP} />
          </LegacyRedirectSupport>
        </HandleOnboardingRedirect>
      </Route>
      <Route path={Pages.MAP}>
        <ShipTracker />
      </Route>
      <Route exact path={Pages.SUPPORT}>
        <SupportPage />
      </Route>
      <Route path={Pages.CREATE_ACCOUNT}>
        <CreateAccountOnboarding />
      </Route>
      <Route exact path={Pages.LEGAL_TERMS}>
        <LegalTermsPage />
      </Route>
      <PrivateRoute path={Pages.ACCOUNT}>
        <Route path={Pages.ACCOUNT}>
          <ShowCaseApp />
        </Route>
      </PrivateRoute>
      <PublicOnlyRoute exact path={Pages.LOGIN}>
        <LoginPage />
      </PublicOnlyRoute>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  )
}

export const ShipTrackerRouter = () => {
  const { isAuthenticated, getAccessTokenSilently, error, user } = useAuth0()

  useHandleAuthError(error)

  useCustomStyleSheet(isAuthenticated)

  useEffect(() => {
    initializeClient({ isAuthenticated, getTokenSilently: getAccessTokenSilently })
  }, [isAuthenticated, getAccessTokenSilently])

  return (
    <BrowserRouter>
      <RoutesWithTracking user={user} />
    </BrowserRouter>
  )
}
