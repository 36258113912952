const getEnvironmentVariable = (name: string): string => {
  const value = process.env[name]

  if (value === undefined || value === '') {
    throw new Error(`Key ${name} is not defined in environment or defined as an empty string`)
  }

  return value
}

export const DEPLOYMENT_ENVIRONMENT = getEnvironmentVariable('REACT_APP_DEPLOYMENT_ENVIRONMENT')

export const PRONTO_MAPBOX_TOKEN = getEnvironmentVariable('REACT_APP_PRONTO_MAPBOX_TOKEN')

export const BACKEND_URL = getEnvironmentVariable('REACT_APP_SHIPTRACKER_BACKEND_URL')

export const AIS_BACKEND_URL = getEnvironmentVariable('REACT_APP_AIS_BACKEND_URL')

export const AUTH0_DOMAIN = getEnvironmentVariable('REACT_APP_SESE_AUTH0_DOMAIN')

export const SHOW_CASE = getEnvironmentVariable('REACT_APP_SHOW_CASE')

export const AUTH0_CLIENT_ID = getEnvironmentVariable('REACT_APP_SHOW_CASE_AUTH0_CLIENT_ID')

export const isTestEnvironment = DEPLOYMENT_ENVIRONMENT === 'test'
