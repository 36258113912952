import { fold, fromNullable } from 'fp-ts/es6/Option'
import { constant, pipe } from 'fp-ts/es6/function'
import { createStore } from './localStorage'

export const storage = createStore('metadata-cache')

type MetadataCache = { [key: string]: unknown }
export const getMetadataCache = (): MetadataCache =>
  pipe(storage.getItem(), fromNullable, fold(constant({}), JSON.parse))

export const saveMetadataCache = (callback: (cache: MetadataCache) => MetadataCache) =>
  pipe(getMetadataCache(), callback, JSON.stringify, storage.setItem)

export const clearMetadataCache = storage.removeItem
