import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { EmailVerificationErrorToast, DURATION } from '../UI/Toast'
import { Auth0ContextInterface } from '@auth0/auth0-react'
import { useState } from 'react'

export enum KnownError {
  EMAIL_VERIFICATION_ERROR = 'EMAIL_VERIFICATION_ERROR',
}

export type EmailVerification = {
  type: KnownError
  email: string
}

type Payload = EmailVerification

export const isPayload = (payload: any): payload is Payload =>
  typeof payload === 'object' && 'type' in payload && payload.type in KnownError

export function useHandleAuthError(error: Auth0ContextInterface['error']) {
  const [displayedErrors, setDisplayedErrors] = useState<{ [err in KnownError]?: boolean }>({})
  useEffect(() => {
    if (error !== undefined) {
      try {
        const payload = JSON.parse(error.message)

        if (isPayload(payload) && !displayedErrors[payload.type]) {
          setDisplayedErrors(state => ({ ...state, [payload.type]: true }))
          switch (KnownError[payload.type]) {
            case KnownError.EMAIL_VERIFICATION_ERROR: {
              toast.error(<EmailVerificationErrorToast email={payload.email} />, { autoClose: DURATION.LONG })
            }
          }
        }
      } catch {}
    }
  }, [error, displayedErrors])
}
