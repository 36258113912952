import classnames from 'classnames'
import { NavLink, Link } from 'react-router-dom'
import styles from './Nav.module.scss'
import { Pages } from '../../constants'

import { AccountUserProfileIcon, AccountLogoutIcon, AccountMapIcon } from '../../UI/Icons/Account'
import { useTranslation } from '../../lib/i18n'
import { useAuth0 } from '@auth0/auth0-react'
import { clientLogout } from '../../Auth/client'
import { useAccountState } from '../AccountState'
import { pipe, constUndefined } from 'fp-ts/es6/function'
import { fold } from 'fp-ts/es6/Option'

type NavProps = { hasPaidAccount: boolean }

export const Nav = ({ hasPaidAccount }: NavProps) => {
  const { logout } = useAuth0()
  const { t } = useTranslation()

  const [{ isSideMenuOpen }] = useAccountState()

  return (
    <nav
      className={classnames(
        styles.nav,
        pipe(
          isSideMenuOpen,
          fold(constUndefined, isOpen => (isOpen ? styles.open : styles.closed))
        )
      )}
    >
      <div className={styles.navPrimary}>
        <NavLink exact to={Pages.ACCOUNT} className={styles.navItem} activeClassName={styles.navItemActive}>
          <AccountUserProfileIcon className={styles.navIcon} /> {t('ShipTracker.Account.Nav.ChangeProfileTitle')}
        </NavLink>
      </div>
      <div className={styles.navSecondary}>
        <Link to={Pages.MAIN} className={styles.navItem}>
          <AccountMapIcon className={styles.navIcon} />
          {t('ShipTracker.Account.Nav.ReturnToMap')}
        </Link>
        <button onClick={() => clientLogout(logout)} className={styles.navItem}>
          <AccountLogoutIcon className={styles.navIcon} />
          {t('ShipTracker.Account.Nav.Logout')}
        </button>
      </div>
    </nav>
  )
}
