import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { fetchPortVisitFromUcrn } from './Api/Port/fetchVisit'
import { getShowCasePort } from './constants'
import { Port } from './Domain/Port'
import { useFromFetch } from './lib/useFromFetch'
import { selectedVesselPath } from './Map/helpers/paths'

const HASHBANG_SHIP_DETAILS_REGEX: RegExp = /!\/ship-details\?ucrn=(\S+)/

type RedirectProps = { port: Port; ucrn: string }

const Redirect: React.FC<RedirectProps> = ({ port, ucrn, children }) => {
  const history = useHistory()
  const { isLoading, data, error } = useFromFetch(() => fetchPortVisitFromUcrn(ucrn, port))
  useEffect(() => {
    if (!isLoading && data) {
      history.replace(selectedVesselPath(data.mmsi))
    }
  }, [data, isLoading, history])

  if (isLoading && !error) {
    return null
  }

  return <>{children}</>
}

export const LegacyRedirectSupport: React.FC = ({ children }) => {
  const { hash: hashbangLocation } = window.location
  const regexResult = HASHBANG_SHIP_DETAILS_REGEX.exec(hashbangLocation)
  const port = getShowCasePort()

  if (regexResult && port) {
    return (
      <Redirect ucrn={regexResult[1]} port={port}>
        {children}
      </Redirect>
    )
  }

  return <>{children}</>
}
