import styles from './VesselDetails.module.scss'
import { pipe } from 'fp-ts/es6/function'
import { Option, fold } from 'fp-ts/es6/Option'
import { ImagePlaceHolder } from '../../UI/ImagePlaceholder/ImagePlaceHolder'

export const VesselDetailsPicture: React.FC<{ pictureUrl: Option<string> }> = ({ pictureUrl }) => (
  <div className={styles.vesselDetailsPicture}>
    {pipe(
      pictureUrl,
      fold(
        () => <ImagePlaceHolder className={styles.vesselDetailsPhoto} />,
        url => <img alt="Selected vessel" className={styles.vesselDetailsPhoto} src={url} />
      )
    )}
  </div>
)
