import classnames from 'classnames'
import format from 'format-util'
import { useField } from 'formik'
import { PasswordPolicy, RuleMissing } from 'password-sheriff'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import MaterialIcon from '../../UI/MaterialIcon'
import styles from './AuthenticationForm.module.scss'

type CheckMarkProps = {
  checked: boolean
}
const CheckMark = ({ checked }: CheckMarkProps) => (
  <MaterialIcon
    className={classnames(styles.check, { [styles.isChecked]: checked })}
    type="check_circle"
    title={checked ? 'checked' : 'unchecked'}
  />
)

type InputPasswordProps = { name: string; label: string; passwordPolicy?: PasswordPolicy; className?: string }
export const InputPassword: React.FC<InputPasswordProps> = ({ name, label, passwordPolicy, className }) => {
  const [field] = useField({ name })
  const [rules, setRules] = useState<RuleMissing[]>([])

  useEffect(() => {
    if (passwordPolicy) {
      const { rules } = passwordPolicy.missing(field.value)
      setRules(rules)
    }
  }, [field.value, passwordPolicy])

  return (
    <div className={classnames(styles.inputField, className)}>
      <label className={styles.label}>{label}</label>
      <input {...field} type="password" placeholder={label} />
      {passwordPolicy && field.value && (
        <div className={styles.passwordChecks}>
          <h5 className={styles.passwordChecksTitle}>Your password must contain:</h5>
          <ul className={styles.rules}>
            {rules.map(rule => (
              <li className={styles.rule} key={rule.code}>
                <div className={styles.entry}>
                  <CheckMark checked={rule.verified} />
                  {format(rule.message, ...rule.format)}
                </div>
                {rule.items && (
                  <ul className={styles.rules}>
                    {rule.items.map(innerRule => (
                      <li className={styles.rule} key={innerRule.code}>
                        <CheckMark checked={innerRule.verified} />
                        {innerRule.message}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}
