import { Switch, Route } from 'react-router-dom'
import { Pages } from '../constants'
import { ShowCaseUserProfile } from './UserProfile/ShowCaseUserProfile'
import { useTranslation } from '../lib/i18n'
import { AccountPage } from './AccountPage'

type RouteProps = { redirect: string }

export function ShowCaseRoutes({ redirect }: RouteProps) {
  const { t } = useTranslation()

  return (
    <Switch>
      <Route exact path={Pages.ACCOUNT} redirect={redirect}>
        <AccountPage sectionTitle={t('ShipTracker.Account.UserProfile.UserProfileTitle')}>
          <ShowCaseUserProfile />
        </AccountPage>
      </Route>
    </Switch>
  )
}
