import worldJson from 'world_countries_lists/data/en/world.json'
import Fuse from 'fuse.js'
import { findFirst } from 'fp-ts/es6/Array'
import { pipe } from 'fp-ts/es6/function'
import { map } from 'fp-ts/es6/Option'

export type Country = typeof worldJson[number]

export const countries = worldJson

export const findCountry = (condition: (country: Country) => boolean) => {
  return pipe(
    worldJson,
    findFirst(country => condition(country)),
    map(foundCountry => {
      // Map country name to the shorter version
      if (foundCountry && foundCountry.name === 'United Kingdom of Great Britain and Northern Ireland') {
        return { ...foundCountry, name: 'United Kingdom' }
      }
      return foundCountry
    })
  )
}

export const findCountryByAlpha2 = (countryCode: Country['alpha2']) =>
  findCountry(({ alpha2 }) => alpha2 === countryCode.toLocaleLowerCase())

export const findCountryByAlpha3 = (countryCode: Country['alpha3']) =>
  findCountry(({ alpha3 }) => alpha3 === countryCode.toLocaleLowerCase())

export type CountrySearchResult = Fuse.FuseResult<Country>

const MAXIMUM_RESULTS = 20

const INDEX_OPTIONS = { keys: ['name', 'alpha3', 'alpha2'] }

function createFuzzyCountrySearch() {
  const newIndex = Fuse.createIndex(INDEX_OPTIONS.keys, countries)

  const index = new Fuse<Country>(countries, INDEX_OPTIONS, newIndex)

  return (term: string) =>
    new Promise<CountrySearchResult[]>(resolve => {
      requestAnimationFrame(() => {
        const result = index.search(term)

        resolve(result.slice(0, MAXIMUM_RESULTS))
      })
    })
}

export const fuzzyCountrySearch = createFuzzyCountrySearch()
