import React from 'react'
import classNames from 'classnames'
import styles from './CtaButton.module.scss'

type CtaButtonProps = {
  className?: string
  onClick: (e: React.MouseEvent) => void
  isDisabled?: boolean
  isOutlined?: boolean
  isForceLight?: boolean
}
export const CtaButton: React.FC<CtaButtonProps> = ({
  children,
  className,
  onClick,
  isDisabled = false,
  isOutlined = false,
  isForceLight = false,
}) => (
  <button
    type="button"
    className={classNames(styles.button, className, {
      [styles.buttonDisabled]: isDisabled,
      [styles.buttonOutlined]: isOutlined,
      [styles.buttonForceLight]: isForceLight,
    })}
    onClick={onClick}
  >
    {children}
  </button>
)
