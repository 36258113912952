import React, { PropsWithChildren } from 'react'
import classnames from 'classnames'
import 'react-toastify/dist/ReactToastify.css'
import './toastify.scss'
import styles from './Toast.module.scss'
import MaterialIcon from '../MaterialIcon'
import { useTranslation } from '../../lib/i18n'
import { AtLeastOne } from '../../lib/AtLeastOne'

export const DURATION = {
  SHORT: 3000,
  MEDIUM: 5000,
  LONG: 30_000,
  EXTRA_LONG: 60_000,
}

export const ToastItem = ({ children }: PropsWithChildren<{}>) => <div className={styles.toastItem}>{children}</div>

export const InfoToast = ({ children }: PropsWithChildren<{}>) => (
  <ToastItem>
    <MaterialIcon className={classnames(styles.toastIcon, styles.info)} type="info" />
    {children}
  </ToastItem>
)

export const ErrorToast = ({ children }: PropsWithChildren<{}>) => (
  <ToastItem>
    <MaterialIcon className={classnames(styles.toastIcon, styles.error)} type="cancel" />
    {children}
  </ToastItem>
)

export const SuccessToast = ({ children }: PropsWithChildren<{}>) => (
  <ToastItem>
    <MaterialIcon className={classnames(styles.toastIcon, styles.success)} type="check_circle" />
    {children}
  </ToastItem>
)

type CustomToastProps = AtLeastOne<{ header?: string; message?: string }>

export const CustomToast = ({ header, message }: CustomToastProps) => (
  <div className={styles.content}>
    {header && <span className={styles.strong}>{header}:</span>}
    {message && <span>{message}</span>}
  </div>
)

export const CustomInfoToast = ({ header, message }: CustomToastProps) => (
  <InfoToast>
    <CustomToast header={header} message={message} />
  </InfoToast>
)

export const CustomSuccessToast = ({ header, message }: CustomToastProps) => (
  <SuccessToast>
    <CustomToast header={header} message={message} />
  </SuccessToast>
)

export const CustomErrorToast = ({ header, message }: CustomToastProps) => (
  <ErrorToast>
    <CustomToast header={header} message={message} />
  </ErrorToast>
)

export function NotSignedInToast() {
  const { t } = useTranslation()

  return (
    <CustomInfoToast
      header={t('ShipTracker.Toast.NotSignedIn.Header')}
      message={t('ShipTracker.Toast.NotSignedIn.Message')}
    />
  )
}

type EmailVerificationErrorToastProps = { email: string }
export function EmailVerificationErrorToast({ email }: EmailVerificationErrorToastProps) {
  const { t } = useTranslation()

  return (
    <CustomErrorToast
      header={t('ShipTracker.Toast.EmailVerificationError.Header')}
      message={t('ShipTracker.Toast.EmailVerificationError.Message', email)}
    />
  )
}

export function EmailVerified() {
  const { t } = useTranslation()

  return (
    <CustomSuccessToast
      header={t('ShipTracker.Toast.EmailVerified.Header')}
      message={t('ShipTracker.Toast.EmailVerified.Message')}
    />
  )
}

export function EmailAlreadyVerified() {
  const { t } = useTranslation()

  return (
    <CustomInfoToast
      header={t('ShipTracker.Toast.EmailAlreadyVerified.Header')}
      message={t('ShipTracker.Toast.EmailAlreadyVerified.Message')}
    />
  )
}

type VesselNotFoundProps = { mmsi: string }

export function VesselNotFound({ mmsi }: VesselNotFoundProps) {
  const { t } = useTranslation()
  return (
    <CustomErrorToast
      header={t('ShipTracker.Toast.VesselNotFound.Header', mmsi)}
      message={t('ShipTracker.Toast.VesselNotFound.Message')}
    />
  )
}
