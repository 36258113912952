import { fold, fromNullable } from 'fp-ts/es6/Option'
import { constNull, pipe } from 'fp-ts/es6/function'
import { createStore } from './localStorage'

export const storage = createStore('verify-email-cache')

export type VerifyEmailCache = { isVerified: boolean; email?: string }

export const getVerifyEmailCache = (): VerifyEmailCache | null =>
  pipe(storage.getItem(), fromNullable, fold(constNull, JSON.parse))

export const saveVerifyEmailCache = (cache: VerifyEmailCache) => pipe(cache, JSON.stringify, storage.setItem)

export const clearVerifyEmailCache = storage.removeItem
