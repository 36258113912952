// Libs
import React, { PropsWithChildren } from 'react'

// Styles
import styles from './RadioButton.module.scss'

type RadioButtonProps = PropsWithChildren<
  Partial<{
    checked: boolean
    name: string
    value: string
    label: string
    handleChange: (e: React.SyntheticEvent) => void
  }>
>

export const RadioButtonLabel: React.FC = ({ children }) => <span className={styles.label}>{children}</span>

export const RadioButtonWithLabel: React.FC<RadioButtonProps> = props => (
  <RadioButton {...props}>{props.label && <RadioButtonLabel>{props.label}</RadioButtonLabel>}</RadioButton>
)

export const RadioButton: React.FC<RadioButtonProps> = ({ checked, name, children, value, handleChange }) => (
  <label className={styles.radioButtonContainer}>
    <input
      className={styles.radioInput}
      type="radio"
      value={value}
      name={name}
      onChange={handleChange}
      checked={checked}
      readOnly={handleChange === undefined}
    />
    <span className={styles.radioButton}></span>
    {children}
  </label>
)
