import { useState } from 'react'
import {
  AuthenticationFormState,
  ForgotPasswordForm,
  SignInForm,
} from '../../Account/AuthenticationForm/AuthenticationForm'
import { InlineLoader } from '../../UI/Loader/InlineLoader'
import { StepHeader, StepText, StepTitle } from '../GenericStepElements'
import styles from './CommonStepEmailVerification.module.scss'
import { GenericStepProps } from '../GenericStepElements.d'
import { useHistory } from 'react-router-dom'
import { Pages } from '../../constants'
import { useTranslation } from '../../lib/i18n'
import { useAuthFlowContext, VerificationStatus } from '../../Auth/AuthFlowContext'
import { Link } from '../../UI/Link/Link'

const VerifiedLayout = ({
  isLoadingToken,
  email,
  loginRedirectPage,
}: {
  isLoadingToken: boolean
  email?: string
  loginRedirectPage: string
}) => {
  const { t } = useTranslation()
  const [state, setState] = useState<AuthenticationFormState>(AuthenticationFormState.SIGN_IN)
  const [passwordChangeInProgress, setPasswordChangeInProgress] = useState(false)

  return (
    <>
      <StepTitle>{t('ShipTracker.CommonStep.VerifyEmail.EmailVerified.Title')}</StepTitle>
      <StepText>{t('ShipTracker.CommonStep.VerifyEmail.EmailVerified.Description')}</StepText>
      <div className={styles.formContainer}>
        {isLoadingToken ? (
          <>
            Receiving token&nbsp;
            <InlineLoader />
          </>
        ) : (
          <>
            {state === AuthenticationFormState.SIGN_IN && (
              <>
                <SignInForm
                  email={email}
                  redirectPage={loginRedirectPage}
                  discardPasswordChange={
                    passwordChangeInProgress ? () => setPasswordChangeInProgress(false) : undefined
                  }
                />
                <p className={styles.switchState}>
                  {t('ShipTracker.CommonStep.Authentication.SwitchState.ForgotPassword.Question')}&nbsp;
                  <Link href="#forgot-password" onClick={() => setState(AuthenticationFormState.FORGOT_PASSWORD)}>
                    {t('ShipTracker.CommonStep.Authentication.SwitchState.ForgotPassword.Link')}
                  </Link>
                </p>
              </>
            )}
            {state === AuthenticationFormState.FORGOT_PASSWORD && (
              <>
                <ForgotPasswordForm
                  onPasswordChangeRequested={() => {
                    setPasswordChangeInProgress(true)
                    setState(AuthenticationFormState.SIGN_IN)
                  }}
                />
                <p className={styles.switchState}>
                  {t('ShipTracker.CommonStep.Authentication.SwitchState.RememberThePassword.Question')}&nbsp;
                  <Link href="#login" onClick={() => setState(AuthenticationFormState.SIGN_IN)}>
                    {t('ShipTracker.CommonStep.Authentication.SwitchState.RememberThePassword.Link')}
                  </Link>
                </p>
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}

// function NotVerifiedLayout() {
//   const { t } = useTranslation()
//
//   return (
//     <>
//       <StepTitle>{t('ShipTracker.CommonStep.VerifyEmail.AccountCreated.Title')}</StepTitle>
//       <h3 className={styles.importantCaption}>{t('ShipTracker.CommonStep.VerifyEmail.AccountCreated.Warning')}</h3>
//       <StepText>({t('ShipTracker.CommonStep.VerifyEmail.AccountCreated.SpamNote')})</StepText>
//     </>
//   )
// }

type CommonStepEmailVerificationProps<T> = GenericStepProps<T> & {
  loginRedirectPage: string
}
export const CommonStepEmailVerification = <T,>({
  onPrevStep,
  loginRedirectPage,
}: CommonStepEmailVerificationProps<T>) => {
  const { status, cache, isLoadingToken, clearCache } = useAuthFlowContext()
  const history = useHistory()

  return (
    <>
      <StepHeader
        onBackButton={() => {
          clearCache()
          onPrevStep()
        }}
        onClose={() => history.push(Pages.MAIN)}
      />
      <div className={styles.signedUpWithEmail}>
        {status === VerificationStatus.Verified ? (
          <VerifiedLayout isLoadingToken={isLoadingToken} email={cache?.email} loginRedirectPage={loginRedirectPage} />
        ) : (
          <VerifiedLayout isLoadingToken={isLoadingToken} email={cache?.email} loginRedirectPage={loginRedirectPage} />
        )}
      </div>
    </>
  )
}
