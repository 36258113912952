import ResizeObserver from 'resize-observer-polyfill'
import { Observable } from 'rxjs'

export const resizeObservable = (element: HTMLElement) =>
  new Observable(subscriber => {
    const resizeObserver = new ResizeObserver(subscriber.next.bind(subscriber))
    resizeObserver.observe(element)

    return () => resizeObserver.unobserve(element)
  })
