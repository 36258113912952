const RADIUS = 6371000 // earthRadius in meters
const RADPERDEGREE = Math.PI / 180.0

/**
 * Calculate the latlong given a position and the bearing and distance
 * @param location location in lon/lat
 * @param distance distance in meter in meter
 * @param bearing in degrees from north
 * @returns {number[]} calculated lon/lat
 */
function longitudeAndLatitudeFromBearingAndDistance(
  longitudeAndLatitude: [number, number],
  distance: number,
  bearing: number
): [number, number] {
  const startLongitudeInRadians = RADPERDEGREE * longitudeAndLatitude[0]
  const startLatitudeInRadians = RADPERDEGREE * longitudeAndLatitude[1]
  const bearingInRadians = RADPERDEGREE * bearing

  const endLatitudeInRadians = Math.asin(
    Math.sin(startLatitudeInRadians) * Math.cos(distance / RADIUS) +
      Math.cos(startLatitudeInRadians) * Math.sin(distance / RADIUS) * Math.cos(bearingInRadians)
  )
  const endLongitudeInRadians =
    startLongitudeInRadians +
    Math.atan2(
      Math.sin(bearingInRadians) * Math.sin(distance / RADIUS) * Math.cos(startLatitudeInRadians),
      Math.cos(distance / RADIUS) - Math.sin(startLatitudeInRadians) * Math.sin(endLatitudeInRadians)
    )
  return [endLongitudeInRadians / RADPERDEGREE, endLatitudeInRadians / RADPERDEGREE]
}

// A ship with width of "1" and height of "1":
const headFactor = 0.8
const unitShipCoordinates: Array<[number, number]> = [
  [0, 0],
  [0, headFactor],
  [0.5, 1],
  [1, headFactor],
  [1, 0],
  [0, 0],
]

const stretchX = (factor: number) => ([x, y]: [number, number]): [number, number] => {
  return [x * factor, y]
}

const stretchY = (factor: number) => ([x, y]: [number, number]): [number, number] => {
  return [x, factor * y]
}

const rotateDegrees = (degrees: number) => ([x, y]: [number, number]): [number, number] => {
  return [
    x * Math.cos(RADPERDEGREE * degrees) - y * Math.sin(RADPERDEGREE * degrees),
    x * Math.sin(RADPERDEGREE * degrees) + y * Math.cos(RADPERDEGREE * degrees),
  ]
}

const translateX = (delta: number) => ([x, y]: [number, number]): [number, number] => {
  return [x + delta, y]
}

const translateY = (delta: number) => ([x, y]: [number, number]): [number, number] => {
  return [x, y + delta]
}

const toDistanceAndBearingInDegrees = ([x, y]: [number, number]): [number, number] => {
  const distance = Math.sqrt(x * x + y * y)
  const radiansFromEast = Math.atan2(y, x)
  const degreesFromEast = radiansFromEast / RADPERDEGREE
  const degreesFromNorth = degreesFromEast - 90
  return [distance, degreesFromNorth]
}

const longitudeAndLatitudeRelativeTo = (referenceLongitudeAndLatitude: [number, number]) => (
  coordinatesInMeters: [number, number]
): [number, number] => {
  const [distance, bearingInDegrees] = toDistanceAndBearingInDegrees(coordinatesInMeters)
  return longitudeAndLatitudeFromBearingAndDistance(referenceLongitudeAndLatitude, distance, bearingInDegrees)
}

export function getShipCoordinates(
  lengthInMeters: number,
  widthInMeters: number,
  headingInDegrees: number,
  transponderDistanceToSternInMeters: number,
  transponderDistanceToPortsideInMeters: number,
  longitudeAndLatitudeTransponder: [number, number]
): Array<[number, number]> {
  return unitShipCoordinates
    .map(stretchX(widthInMeters))
    .map(stretchY(lengthInMeters))
    .map(translateX(-transponderDistanceToPortsideInMeters))
    .map(translateY(-transponderDistanceToSternInMeters))
    .map(rotateDegrees(headingInDegrees))
    .map(longitudeAndLatitudeRelativeTo(longitudeAndLatitudeTransponder))
}
