import classnames from 'classnames'
import Downshift, { ControllerStateAndHelpers } from 'downshift'
import React from 'react'
import styles from './DropoutMenu.module.scss'

type DropoutMenuProps = Readonly<{
  children: <T>(props: Pick<ControllerStateAndHelpers<T>, 'isOpen' | 'closeMenu' | 'openMenu'>) => React.ReactNode
}>

export const DropoutMenu: React.FC<DropoutMenuProps> = ({ children }: DropoutMenuProps) => (
  <Downshift>
    {({ isOpen, closeMenu, openMenu, getMenuProps, getRootProps }) => (
      <>
        {isOpen && <div className={styles.dropoutMenuOverlay} />}
        <div {...getRootProps(undefined, { suppressRefError: true })}>
          <div className={classnames(styles.dropoutMenu, { [styles.active]: isOpen })} {...getMenuProps()}>
            {children({ isOpen, closeMenu, openMenu })}
          </div>
        </div>
      </>
    )}
  </Downshift>
)
