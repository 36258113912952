import { flow } from 'fp-ts/es6/function'
import { fold, fromNullable } from 'fp-ts/es6/Option'
import { useEffect, useState } from 'react'
import { ReplaySubject, timer } from 'rxjs'
import { debounce, startWith } from 'rxjs/operators'
import { createStore } from '../localStorage'

const store = createStore('shipTrackerDarkMode')

export const isInitialDarkModeSetting = flow(
  store.getItem,
  fromNullable,
  fold(
    () => window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches,
    stored => stored === 'true'
  )
)

export const persistDarkModeSetting: (setting: boolean) => void = flow(String, store.setItem)

const darkModeSubject = new ReplaySubject<boolean>()

const darkModeSubscriber = darkModeSubject.pipe(
  startWith(isInitialDarkModeSetting()),
  debounce(() => timer(100))
)

darkModeSubject.subscribe(persistDarkModeSetting)

export const useDarkMode = () => {
  const [isDarkMode, setDarkMode] = useState<boolean>(isInitialDarkModeSetting())

  useEffect(() => {
    const subscription = darkModeSubscriber.subscribe(setDarkMode)

    return subscription.unsubscribe.bind(subscription)
  }, [])

  return { isDarkMode, setDarkMode: darkModeSubject.next.bind(darkModeSubject) }
}
