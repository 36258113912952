import { store } from '../dataStore'

type PropName = 'onboardingGuiStatus' | 'onboardingApiStatus'

export const useHandleFlowStart = (propName: PropName): (() => void) => {
  return () => {
    store.metadata.fetch().then(metadata => {
      if (metadata && (!metadata[propName] || metadata[propName] === 'not_started')) {
        store.metadata.update({ [propName]: 'in_progress' })
      }
    })
  }
}
