import classnames from 'classnames'

import { useTranslation } from '../../lib/i18n'
import MaterialIcon from '../MaterialIcon'

import styles from './ImagePlaceHolder.module.scss'

export function ImagePlaceHolder({ className }: { className?: string }) {
  const { t } = useTranslation()

  return (
    <div data-testid="image-placeholder" key="no-photo" className={classnames(styles.placeholder, className)}>
      <MaterialIcon type="no_photography" />
      <span>{t('ShipTracker.Map.Marker.NotAvailable')}</span>
    </div>
  )
}
