import {
  VesselProperties,
  PortVesselProperties,
  arrivalTimeLabelKey,
  arrivalTimeKindLabelKey,
  getBestArrivalTime,
  formatArrivalTime,
} from '../../Domain/Vessel'
import { useTranslation } from '../../lib/i18n'
import styles from './VesselDetails.module.scss'

const NotAvailable = () => <span className={styles.notAvailable}>n/a</span>

type BasicDetailsProps = Pick<
  VesselProperties | PortVesselProperties,
  'destinationPort' | 'reportedArrivalTime' | 'predictedArrivalTime' | 'actualArrivalTime' | 'speedOverGround'
>

export function BasicDetails({
  destinationPort,
  speedOverGround,
  reportedArrivalTime,
  predictedArrivalTime,
  actualArrivalTime,
}: BasicDetailsProps) {
  const { t } = useTranslation()

  const bestArrivalTime = getBestArrivalTime({ actualArrivalTime, predictedArrivalTime, reportedArrivalTime })

  const arrivalTime = bestArrivalTime?.value
  const arrivalTimeOffset = bestArrivalTime?.offset

  return (
    <p className={styles.basicDetails}>
      <span className={styles.section}>
        <span className={styles.label}>{t('ShipTracker.Map.Marker.DestinationPort').toUpperCase()}</span>
        {destinationPort ? <span>{destinationPort}</span> : <NotAvailable />}
      </span>
      <span className={styles.section}>
        <span className={styles.label}>
          {t(arrivalTimeLabelKey(bestArrivalTime)).toUpperCase()} (
          {t(arrivalTimeKindLabelKey(bestArrivalTime?.kind) ?? 'ShipTracker.Common.Reported')})
        </span>
        {arrivalTime ? (
          <span className={styles.etaDate}>{formatArrivalTime(arrivalTime, arrivalTimeOffset)}</span>
        ) : (
          <NotAvailable />
        )}
      </span>
      <span className={styles.section}>
        <span className={styles.label}>{t('ShipTracker.Map.Marker.Speed').toUpperCase()}</span>
        {speedOverGround ? <span>{speedOverGround} kts</span> : <NotAvailable />}
      </span>
    </p>
  )
}
