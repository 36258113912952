import { useAuth0 } from '@auth0/auth0-react'
import classnames from 'classnames'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { AuthFlowProvider, VerificationStatus, useAuthFlowContext } from '../../Auth/AuthFlowContext'
import { Pages, SHIP_TRACKER_THEME_CSS, SHIP_TRACKER_THEME_DARK_CSS } from '../../constants'
import { useDarkMode } from '../../lib/hooks/useDarkMode'
import { useTranslation } from '../../lib/i18n'
import { CloseButton, CloseButtonTheme } from '../../UI/CloseButton'
import { Link } from '../../UI/Link/Link'
import { InlineLoader, lightBackgroundInlineLoader } from '../../UI/Loader/InlineLoader'
import { Logo } from '../../UI/Logo'
import {
  AuthenticationFormState,
  ForgotPasswordForm,
  GoogleAuthorizationForm,
  SignInForm,
} from '../AuthenticationForm/AuthenticationForm'
import { SuccessComponent } from '../AuthenticationForm/SuccessComponent'
import styles from './LoginPage.module.scss'

const closeButtonTheme: CloseButtonTheme = {
  button: styles.closeButton,
  icon: styles.closeButtonIcon,
}

const useRedirectPage = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  return useRef(params.get('from') ?? Pages.MAIN).current
}

export const LoginPageContent = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const redirectPage = useRedirectPage()
  const { isAuthenticated } = useAuth0()
  const { isDarkMode } = useDarkMode()
  const { isLoadingToken, status } = useAuthFlowContext()
  const [state, setState] = useState<AuthenticationFormState>(AuthenticationFormState.SIGN_IN)
  const [passwordChangeInProgress, setPasswordChangeInProgress] = useState(false)

  useEffect(() => {
    if (isAuthenticated) {
      history.push(redirectPage)
    } else if (status !== VerificationStatus.None) {
      history.push(Pages.CREATE_ACCOUNT)
    }
  }, [history, isAuthenticated, redirectPage, status])

  return (
    <div
      className={classNames(styles.loginPage, SHIP_TRACKER_THEME_CSS, {
        [SHIP_TRACKER_THEME_DARK_CSS]: isDarkMode,
      })}
    >
      <div className={styles.header}>
        <Logo className={styles.logo} />
        <CloseButton theme={closeButtonTheme} onClose={() => history.push(Pages.MAIN)} />
      </div>
      <div className={styles.contentWrapper}>
        <div className={classnames(styles.content, { [styles.contentCentered]: isLoadingToken })}>
          {!isLoadingToken ? (
            <>
              <h1 className={styles.title}>{t('ShipTracker.Account.LoginPage.Title')}</h1>
              <p className={styles.text}>{t('ShipTracker.Account.LoginPage.Text')}</p>
            </>
          ) : (
            <h1 className={styles.title}>{t('ShipTracker.Common.PleaseWait')}</h1>
          )}
          {isLoadingToken ? (
            <div className={styles.spinnerContainer}>
              {t('ShipTracker.Common.ReceivingToken')}&nbsp;
              <InlineLoader theme={{ ...lightBackgroundInlineLoader, spinner: styles.spinner }} />
            </div>
          ) : (
            <>
              {state === AuthenticationFormState.SIGN_IN && (
                <>
                  {passwordChangeInProgress && (
                    <SuccessComponent
                      message={t('ShipTracker.CommonStep.Authentication.ResetPasswordConfirmation')}
                      handleClose={() => setPasswordChangeInProgress(false)}
                    />
                  )}
                  <SignInForm
                    theme={{ inputField: styles.inputField }}
                    redirectPage={`${Pages.LOGIN}?from=${redirectPage}`}
                  />
                  <p className={styles.switchState}>
                    {t('ShipTracker.CommonStep.Authentication.SwitchState.DontHaveAnAccount.Question')}&nbsp;
                    <Link href={Pages.CREATE_ACCOUNT}>
                      {t('ShipTracker.CommonStep.Authentication.SwitchState.DontHaveAnAccount.Link')}
                    </Link>
                  </p>
                  <p className={styles.switchState}>
                    {t('ShipTracker.CommonStep.Authentication.SwitchState.ForgotPassword.Question')}&nbsp;
                    <Link href="#forgot-password" onClick={() => setState(AuthenticationFormState.FORGOT_PASSWORD)}>
                      {t('ShipTracker.CommonStep.Authentication.SwitchState.ForgotPassword.Link')}
                    </Link>
                  </p>
                </>
              )}
              {state === AuthenticationFormState.FORGOT_PASSWORD && (
                <>
                  <ForgotPasswordForm
                    theme={{ inputField: styles.inputField }}
                    onPasswordChangeRequested={() => {
                      setPasswordChangeInProgress(true)
                      setState(AuthenticationFormState.SIGN_IN)
                    }}
                  />
                  <p className={styles.switchState}>
                    {t('ShipTracker.CommonStep.Authentication.SwitchState.RememberThePassword.Question')}&nbsp;
                    <Link href="#login" onClick={() => setState(AuthenticationFormState.SIGN_IN)}>
                      {t('ShipTracker.CommonStep.Authentication.SwitchState.RememberThePassword.Link')}
                    </Link>
                  </p>
                </>
              )}
              <div className={styles.seperator}>
                <span className={styles.choose}>or</span>
              </div>
              <GoogleAuthorizationForm redirectPage={`${Pages.LOGIN}?from=${redirectPage}`} />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export const LoginPage = () => (
  <AuthFlowProvider>
    <LoginPageContent />
  </AuthFlowProvider>
)
