import { visitFromJson, VisitJson, Visit } from './../../Domain/PortCall'
import { authRequest } from './../auth'
import { BACKEND_URL } from './../../config'
import { MMSI } from './../../Domain/Vessel'
import { Port } from '../../Domain/Port'
import { fold, Option } from 'fp-ts/es6/Option'
import { pipe } from 'fp-ts/es6/function'
import ky from 'ky'

export const fetchNextVisitForHandPicked = (
  mmsis: MMSI[],
  showCasePort: Option<Port>
): Promise<Record<string, Visit>> =>
  pipe(
    showCasePort,
    fold(
      () => authRequest.post(`${BACKEND_URL}/api/visits/next`, { json: mmsis }),
      ({ port }) => ky.post(`${BACKEND_URL}/api/${port.toLowerCase()}/visits/next`, { json: mmsis })
    )
  )
    .json<VisitJson[]>()
    .then(visitJsons =>
      visitJsons.reduce<Record<string, Visit>>((acc, visitJson) => {
        const visit = visitFromJson(visitJson)

        return { ...acc, [visit.mmsi]: visit }
      }, {})
    )
