import { Option, none, isSome, map } from 'fp-ts/es6/Option'
import { deleteSubscription } from '../../Api/Notification/Subscription/deleteSubscription'
import { addSubscription } from '../../Api/Notification/Subscription/addSubscription'
import { pipe } from 'fp-ts/es6/function'
import { findFirst } from 'fp-ts/es6/Array'
import { createUnlocode } from '../../Domain/Port'
import { isShipIndentifierKey } from '../../Domain/Ship'
import { Subscription } from '../../Domain/Notification/Subscription'
import { useState } from 'react'
import { actions } from '../Traffic/useNotificationSubscriptions'

const PORTCALL_REGEX = /PCE-([A-Z]+)-([A-Z]+)([0-9]+)-(.*)$/

export function findSubscription(portcallId: string, subscriptions: Subscription[]) {
  const match = portcallId.match(PORTCALL_REGEX)

  if (match) {
    const [portMatch, vesselIdentifierMatch, vesselId] = match.slice(1)
    const port = createUnlocode(portMatch)
    const vesselIdentifier = vesselIdentifierMatch.toLowerCase()

    return pipe(
      subscriptions,
      findFirst(
        ({ filter: { ports, ship } }) =>
          ports.includes(port) && isShipIndentifierKey(vesselIdentifier) && ship[vesselIdentifier] === vesselId
      ),
      map(({ uuid }) => uuid)
    )
  }

  return none
}

enum Status {
  IDLE,
  ERROR,
  SUBSCRIBING,
  UNSUBSCRIBING,
}

type State =
  | { kind: Status.ERROR; error: Error }
  | { kind: Status.SUBSCRIBING }
  | { kind: Status.UNSUBSCRIBING }
  | { kind: Status.IDLE }

const idleState: State = { kind: Status.IDLE }

export function useEditSubscription(portcallId: string | undefined, subscriptionIdOption: Option<string>) {
  const [state, setState] = useState<State>(idleState)

  return {
    isPending: [Status.UNSUBSCRIBING, Status.SUBSCRIBING].includes(state.kind),
    hasSubscription: (state.kind === Status.IDLE && isSome(subscriptionIdOption)) || state.kind === Status.SUBSCRIBING,
    addSubscription: () => {
      if (portcallId) {
        setState({ kind: Status.SUBSCRIBING })
        addSubscription(portcallId)
          .then(subscription => {
            setState(idleState)
            actions.add(subscription)
          })
          .catch(error => setState({ kind: Status.ERROR, error }))
      }
    },
    deleteSubscription: () => {
      pipe(
        subscriptionIdOption,
        map(id => {
          setState({ kind: Status.UNSUBSCRIBING })

          deleteSubscription(id)
            .then(() => {
              setState(idleState)
              actions.remove(id)
            })
            .catch(error => setState({ kind: Status.ERROR, error }))
        })
      )
    },
  }
}
