import React, { FC } from 'react'

import styles from './Search.module.scss'
import { InlineLoader } from '../UI/Loader/InlineLoader'
import { VesselSearchResult } from '../Domain/VesselSearchResult'
import { shipIdentifiers } from '../Domain/Ship'
import { MINIMUM_QUERY_LENGTH } from './constants'

type ShipIdEntryProps = Readonly<{ key: string; value: string; label: string }>

const ShipIdentifier = ({ value, label }: ShipIdEntryProps) => (
  <span className={styles['ship-id']}>
    <span className={styles['ship-id-label']}>{label}</span> {value}
  </span>
)

type ShipIdentifiersProps = Pick<VesselSearchResult, 'ship'>

export const ShipIdentifiers: FC<ShipIdentifiersProps> = ({ ship }) => (
  <div className={styles['ship-ids']}>
    {shipIdentifiers.reduce((acc: JSX.Element[], key) => {
      const value = ship[key]

      return value ? [...acc, <ShipIdentifier key={key} value={value} label={key} />] : acc
    }, [])}
  </div>
)

export const SearchResultsLoading = () => (
  <div className={styles.loader}>
    <InlineLoader />
  </div>
)

export const SearchResultsNotice = () => (
  <div className={styles.notice}>
    To limit the number of search results to show, please provide at least {MINIMUM_QUERY_LENGTH} characters.
  </div>
)
