import React, { PropsWithChildren } from 'react'
import classnames from 'classnames'
import styles from './SelectOptionList.module.scss'

type ItemProps = PropsWithChildren<{ getProps?: () => React.HTMLProps<HTMLLIElement>; isHighlighted?: boolean }>

export const Item = ({ getProps, isHighlighted, children }: ItemProps) => (
  <li {...(getProps ? getProps() : {})} className={classnames(styles.item, { [styles.highlighted]: isHighlighted })}>
    {children}
  </li>
)
