import React from 'react'
import classnames from 'classnames'
import { SHIP_TRACKER_THEME_CSS, SHIP_TRACKER_THEME_DARK_CSS } from '../constants'
import Header from '../Header/Header'
import { useDarkMode } from '../lib/hooks/useDarkMode'
import styles from './GenericPage.module.scss'
import { useTranslation } from '../lib/i18n'
import classNames from 'classnames'

export const SupportPage: React.FC = () => {
  const { isDarkMode } = useDarkMode()
  const { t } = useTranslation()

  return (
    <main
      className={classnames(styles.genericPage, SHIP_TRACKER_THEME_CSS, {
        [SHIP_TRACKER_THEME_DARK_CSS]: isDarkMode,
      })}
    >
      <Header />
      <div className={classNames(styles.content, styles.formatTags)}>
        <h2>{t('ShipTracker.SupportPage.Title')}</h2>
        <p dangerouslySetInnerHTML={{ __html: t('ShipTracker.SupportPage.Content') }} />
      </div>
    </main>
  )
}
