import React from 'react'
import classnames from 'classnames'
import styles from './HamburgerMenu.module.scss'

type HamburgerMenuProps = { isOpen: boolean; handleClick: () => void; customStyle?: string }

export const HamburgerMenu = ({ isOpen, handleClick, customStyle }: HamburgerMenuProps) => (
  <button className={styles.hamburgerMenuWrapper} onClick={handleClick}>
    <div className={classnames(styles.hamburgerMenu, customStyle, { [styles.open]: isOpen })}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </button>
)
