import React, { useCallback, useEffect, useState, useMemo } from 'react'
import styles from './VesselListing.module.scss'
import classnames from 'classnames'
import MaterialIcon from '../../../UI/MaterialIcon'
import { useFavoritesFilter, useHandPickedFilter, useSubscribedFilter } from '../useVesselTraffic'
import { DropoutMenu } from '../../../UI/DropoutMenu/DropoutMenu'
import { TopLeftDropoutMenuDropper } from '../../../UI/DropoutMenu/DropoutMenuDropper'
import { Checkbox } from '../../../UI/Checkbox/Checkbox'
import { shipTypesSum, countrySum, vesselStatusesSum } from '../../../Domain/PortCall'
import { Option } from 'fp-ts/es6/Option'
import { useTranslation } from '../../../lib/i18n'
import { Feature, useFeatureToggler } from '../../../lib/hooks/useFeatureToggler'
import { useAuth0 } from '@auth0/auth0-react'
import { CountriesDropdown } from './SelectCountry/CountriesDropdown'
import { Country } from '../../../Domain/Countries'
import { flagStateFilterSubject } from '../useFlagStateFilter'
import { vesselTypeFilterSubject, removeVesselType, addVesselType } from '../useShipTypeFilter'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { CloseButton } from '../../../UI/CloseButton'
import { useResponsiveness } from '../../../lib/hooks/useResponsiveness'
import { addVesselStatus, removeVesselStatus, vesselStatusFilterSubject } from '../vesselStatusFilter'
import { Ticker } from '../../../UI/Ticker/Ticker'
import { PortVessel } from '../../../Domain/Vessel'

type VesselListingActionsProps = {
  totalPortVessels: PortVessel[]
}

type StatusFiltersDropoutMenu = VesselListingActionsProps & {
  isOpen: boolean
  closeDropout: () => void
}

enum Section {
  VesselStatus,
  ShipType,
}

const StatusFiltersDropout: React.FC<StatusFiltersDropoutMenu> = ({ isOpen, closeDropout, totalPortVessels }) => {
  const { t } = useTranslation()
  const { isMobile } = useResponsiveness()
  const { isHandPickedFilterActive, handleSetHandPickedFilterActive } = useHandPickedFilter()
  const [changedHandPickedFilter, setChangedHandPickedFilter] = useState<boolean>(isHandPickedFilterActive)
  const [changedFlagState, setChangedFlagState] = useState<Option<Country>>(flagStateFilterSubject.value)
  const [changedShipTypes, setChangedShipTypes] = useState<string[]>(vesselTypeFilterSubject.value)
  const [changedVesselStatuses, setChangedVesselStatuses] = useState<string[]>(vesselStatusFilterSubject.value)

  const [activeSections, setActiveSections] = useState<Record<Section, boolean>>({
    [Section.VesselStatus]: true,
    [Section.ShipType]: false,
  })

  const { availableCountryCount, totalShipTypes, shipTypes, vesselStatuses, totalVesselStatuses } = useMemo(() => {
    const totalShipTypes = totalPortVessels.reduce(shipTypesSum, {})
    const totalVesselStatuses = totalPortVessels.reduce(vesselStatusesSum, {})

    return {
      totalShipTypes,
      totalVesselStatuses,
      shipTypes: Object.keys(totalShipTypes).sort(),
      vesselStatuses: Object.keys(totalVesselStatuses).sort(),
      availableCountryCount: totalPortVessels.reduce(countrySum, {}),
    }
  }, [totalPortVessels])

  const { isEnabled: isHandPickedListingEnabled } = useFeatureToggler(Feature.HANDPICKED_LIST)

  useEffect(() => {
    /* reset state on open */
    setChangedHandPickedFilter(isHandPickedFilterActive)
    setChangedFlagState(flagStateFilterSubject.value)
    setChangedShipTypes(vesselTypeFilterSubject.value)
    setChangedVesselStatuses(vesselStatusFilterSubject.value)
  }, [isHandPickedFilterActive, isOpen])

  const handleApply = useCallback(() => {
    handleSetHandPickedFilterActive(changedHandPickedFilter)
    flagStateFilterSubject.next(changedFlagState)
    vesselTypeFilterSubject.next(changedShipTypes)
    vesselStatusFilterSubject.next(changedVesselStatuses)
    closeDropout()
  }, [
    closeDropout,
    changedHandPickedFilter,
    changedFlagState,
    changedShipTypes,
    changedVesselStatuses,
    handleSetHandPickedFilterActive,
  ])

  const addShipTypesCallback = useCallback(type => addVesselType(type, changedShipTypes), [changedShipTypes])
  const removeShipTypesCallback = useCallback(type => removeVesselType(type, changedShipTypes), [changedShipTypes])

  return isOpen ? (
    <TopLeftDropoutMenuDropper triangleOffsetLeft={25} className={styles.filtersDropout} asPopupOnMobile>
      <OverlayScrollbarsComponent
        className={styles.filtersDropoutContent}
        options={{ scrollbars: { autoHide: 'scroll' } }}
      >
        <div className={styles.filtersList}>
          {isMobile && (
            <CloseButton onClose={closeDropout} theme={{ button: styles.closeButton, icon: styles.closeButtonIcon }} />
          )}
          {isHandPickedListingEnabled ? (
            <div className={styles.filterSection}>
              <Checkbox
                key={`status-filters-dropout-handpicked-filter`}
                checked={changedHandPickedFilter}
                onChange={setChangedHandPickedFilter}
                className={styles.filterEntry}
              >
                {t('ShipTracker.VesselListing.OnlyShowManualFleet')}
              </Checkbox>
            </div>
          ) : null}

          <div className={styles.filterSection}>
            <div
              className={styles.filterSectionHandle}
              onClick={() =>
                setActiveSections({
                  ...activeSections,
                  [Section.VesselStatus]: !activeSections[Section.VesselStatus],
                })
              }
            >
              Vessel status
              <MaterialIcon type={activeSections[Section.VesselStatus] ? 'expand_less' : 'expand_more'} />
            </div>
            <div
              className={classnames(styles.filterSectionContent, {
                [styles.isOpen]: activeSections[Section.VesselStatus],
              })}
            >
              {vesselStatuses.map(vesselStatus => {
                const filtered = changedVesselStatuses.includes(vesselStatus)

                return (
                  <Checkbox
                    testId={`checkbox-${vesselStatus}`}
                    key={`status-filters-dropout-${vesselStatus}`}
                    checked={!filtered}
                    onChange={() =>
                      setChangedVesselStatuses(
                        filtered
                          ? removeVesselStatus(vesselStatus, changedVesselStatuses)
                          : addVesselStatus(vesselStatus, changedVesselStatuses)
                      )
                    }
                    className={classnames(styles.filterEntry, {
                      [styles.filterEntryDisabled]: isHandPickedFilterActive,
                    })}
                  >
                    <div className={styles.aisVesselStatus}>
                      <div className={styles.aisVesselStatusStretch}>{vesselStatus}</div>
                      <Ticker>{vesselStatus}</Ticker>
                    </div>
                    <div className={styles.aisVesselStatusCount}>
                      {!isHandPickedFilterActive ? `(${totalVesselStatuses[vesselStatus] || 0})` : ''}
                    </div>
                  </Checkbox>
                )
              })}
            </div>
          </div>
          <div className={styles.filterSection}>
            <div
              data-testid="vessel-type-handle"
              onClick={() =>
                setActiveSections({ ...activeSections, [Section.ShipType]: !activeSections[Section.ShipType] })
              }
              className={styles.filterSectionHandle}
            >
              Vessel type
              <MaterialIcon type={activeSections[Section.ShipType] ? 'expand_less' : 'expand_more'} />
            </div>
            <div
              className={classnames(styles.filterSectionContent, {
                [styles.isOpen]: activeSections[Section.ShipType],
              })}
            >
              {shipTypes.map(shipType => {
                const filtered = changedShipTypes.includes(shipType)

                return (
                  <Checkbox
                    key={shipType}
                    checked={!filtered}
                    onChange={() => {
                      setChangedShipTypes(filtered ? removeShipTypesCallback(shipType) : addShipTypesCallback(shipType))
                    }}
                    className={classnames(styles.filterEntry)}
                  >
                    {shipType} ({totalShipTypes[shipType]})
                  </Checkbox>
                )
              })}
            </div>
          </div>
          <div className={styles.filterSection}>
            <div className={styles.filterEntry}>
              <CountriesDropdown
                availableCountryCount={availableCountryCount}
                flagState={changedFlagState}
                setFlagState={setChangedFlagState}
                placeholder={t('ShipTracker.VesselListing.FlagState')}
              />
            </div>
          </div>
          <div className={styles.filterSection}>
            <button data-testid="apply-filters" className={styles.applyButton} onClick={handleApply}>
              {t('ShipTracker.VesselListing.ApplyFilters')}
            </button>
          </div>
        </div>
      </OverlayScrollbarsComponent>
    </TopLeftDropoutMenuDropper>
  ) : null
}

export const VesselListingActions: React.FC<VesselListingActionsProps> = ({ totalPortVessels }) => {
  const { t } = useTranslation()
  const { isAuthenticated } = useAuth0()
  const { favorites, handleSetFavoritesFilterActive, isFavoritesFilterActive } = useFavoritesFilter()
  const { isHandPickedFilterActive } = useHandPickedFilter()
  const { isSubscribedFilterActive, handleSetSubscribedFilterActive } = useSubscribedFilter()

  const handleToggleFavoritesFilter = useCallback(() => {
    handleSetFavoritesFilterActive(!isFavoritesFilterActive)
  }, [handleSetFavoritesFilterActive, isFavoritesFilterActive])

  return (
    <>
      <nav className={styles.actions}>
        <div className={classnames(styles.filtersButtonWrapper)}>
          <DropoutMenu>
            {({ isOpen, closeMenu, openMenu }) => (
              <>
                <button
                  data-testid="filter-handle"
                  className={classnames(styles.filtersButton)}
                  onClick={() => (isOpen ? closeMenu() : openMenu())}
                >
                  <div className={styles.leftColumn}>
                    <MaterialIcon
                      type="more_vert"
                      className={classnames(styles.moreIcon, { [styles.active]: isOpen })}
                    />
                  </div>
                  <h3>{`${t('ShipTracker.VesselListing.FilterList')} ${
                    !isHandPickedFilterActive ? `(${totalPortVessels.length})` : ''
                  }`}</h3>
                </button>
                <StatusFiltersDropout isOpen={isOpen} closeDropout={closeMenu} totalPortVessels={totalPortVessels} />
              </>
            )}
          </DropoutMenu>
        </div>
        <>
          <button
            className={classnames(styles.actionButton, { [styles.disabled]: favorites.length === 0 })}
            onClick={handleToggleFavoritesFilter}
          >
            <i
              className={classnames(styles.iconSvg, styles.fav, styles.light, {
                [styles.checked]: isFavoritesFilterActive,
              })}
            />
          </button>
          <button
            className={classnames(styles.actionButton, { [styles.disabled]: !isAuthenticated })}
            onClick={() => handleSetSubscribedFilterActive(!isSubscribedFilterActive)}
          >
            <i
              className={classnames(styles.iconSvg, styles.mail, styles.light, {
                [styles.checked]: isSubscribedFilterActive,
              })}
            />
          </button>
        </>
      </nav>
    </>
  )
}
