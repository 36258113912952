import { handleJsonResponse } from '../lib/response'
import { BACKEND_URL } from '../config'
import { VesselSearchResult } from '../Domain/VesselSearchResult'

export async function fetchVesselSearch(queryString: string): Promise<VesselSearchResult[]> {
  const escapedQuery = encodeURIComponent(queryString).toUpperCase()

  const result = await fetch(`${BACKEND_URL}/api/vessels/search/${escapedQuery}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }).then(handleJsonResponse)

  return result
}
