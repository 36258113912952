import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import MapboxGL, { Map as Mapbox, NavigationControl } from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { PortVessel, TrafficVessel, HandPickedVessel } from '../Domain/Vessel'
import {
  MAP_LAYERS,
  MAP_OPTIONS,
  INITIAL_ZOOM,
  MAPBOX_LIGHT_STYLE,
  MAPBOX_DARK_STYLE,
  MAPBOX_DARK_STYLE_ID,
  MAPBOX_LIGHT_STYLE_ID,
  MAPBOX_SATELLITE_STYLE_ID,
} from './constants'
import { fromEventPattern, fromEvent, interval, of } from 'rxjs'
import { Feature, Geometry, FeatureCollection } from 'geojson'
import { VesselDisplayState } from './Traffic/useVesselTraffic'
import { IconProps, ShapeProps } from '../Domain/VesselFeature'
import { PRONTO_MAPBOX_TOKEN } from '../config'
import { isInitialDarkModeSetting, useDarkMode } from '../lib/hooks/useDarkMode'
import { fetchMapboxStyle } from '../Api/fetchMapboxStyle'
import { switchMap, takeUntil, filter, takeLast, debounceTime } from 'rxjs/operators'
import { resizeObservable } from '../lib/resizeObservable'
import { defaultNever } from '../lib/trackError'

const FEATURE_SOURCES = [
  'traffic-source',
  'portCalls-source',
  'portcall-shapes-source',
  'traffic-shapes-source',
  'hoverMarker-source',
  'historicTrace-source',
  'predictedTrace-source',
] as const

export async function createMap(node: HTMLDivElement) {
  /* tslint:disable */
  const ownPropertyDescriptor = Object.getOwnPropertyDescriptor(MapboxGL, 'accessToken')
  if (ownPropertyDescriptor !== undefined && ownPropertyDescriptor.set !== undefined) {
    ownPropertyDescriptor.set(PRONTO_MAPBOX_TOKEN)
  }
  /* tslint:enable */

  const mapbox = new Mapbox({
    ...MAP_OPTIONS,
    container: node,
    style: isInitialDarkModeSetting() ? MAPBOX_DARK_STYLE : MAPBOX_LIGHT_STYLE,
  })

  // Add Controls
  mapbox.addControl(new NavigationControl({ showCompass: false }), 'top-right')
  // Disable map rotation using right click + drag.
  mapbox.dragRotate.disable()

  // Disable map rotation using touch rotation gesture.
  mapbox.touchZoomRotate.disableRotation()

  // Undocumented feature to show tile bounds + coordinates
  // mapbox.showTileBoundaries = true

  // Wait for map to be loaded
  await new Promise<Mapbox>(resolve => mapbox.on('load', resolve))

  // Setup GeoJson source and layers, when loaded
  FEATURE_SOURCES.forEach(source => {
    const data: FeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    }

    mapbox.addSource(source, {
      type: 'geojson',
      data,
    })
  })

  MAP_LAYERS.forEach(layer => mapbox.addLayer(layer))

  return mapbox
}

const sourcesIds = MAP_LAYERS.map(({ source }) => source)
export const changeMapStyle = async (mapbox: Mapbox, styleId: string) => {
  /*
    In order to keep all sources and layers on the mapbox-gl@0.54.0 we need to fetch and modify new style ourselves.
    Source: https://github.com/mapbox/mapbox-gl-js/issues/4006
  */
  const currentStyle = mapbox.getStyle()
  const newStyle = await fetchMapboxStyle(styleId)

  newStyle.sources = {
    ...currentStyle.sources,
    ...newStyle.sources,
  }

  const appLayers = currentStyle.layers?.filter(layer => sourcesIds.includes(layer.source)) || []
  newStyle.layers = [...(newStyle.layers || []), ...appLayers]

  mapbox.setStyle(newStyle)
}

export const initializeMap = (mapbox: Mapbox, center: [number, number], zoom: number | undefined = INITIAL_ZOOM) =>
  // fly to user location
  new Promise<void>(resolve => {
    mapbox.doubleClickZoom.disable()
    mapbox.scrollZoom.disable()
    mapbox.resize()

    const subscriptions = [
      // There's an issue with the map aborting the flyTo on mouse down
      // Please check if this is needed, whenever the map gets upgraded
      fromEvent(mapbox.getCanvas(), 'mousedown').subscribe(e => e.stopImmediatePropagation()),

      fromEventPattern(
        handler => mapbox.on('moveend', handler),
        handler => mapbox.off('moveend', handler)
      ).subscribe(() => {
        subscriptions.forEach(s => s.unsubscribe())
        mapbox.doubleClickZoom.enable()
        mapbox.scrollZoom.enable()
        resolve()
      }),
    ]

    mapbox.flyTo({ zoom, center, speed: 2 })
  })

type Source = typeof FEATURE_SOURCES[number]

export function renderSource<P>(mapbox: Mapbox, source: Source, features: Array<Feature<Geometry, P>>) {
  const mapSource = mapbox.getSource(source)

  if (mapSource.type === 'geojson') {
    mapSource.setData({ type: 'FeatureCollection', features })
  }
}

const clearSource = (mapbox: Mapbox, source: Source) => renderSource(mapbox, source, [])

export const renderTraffic = (mapbox: Mapbox, displayState: VesselDisplayState, traffic: TrafficVessel[]) => {
  if (displayState === VesselDisplayState.SHAPES) {
    renderSource<IconProps>(mapbox, 'traffic-source', [])
    renderSource<ShapeProps>(
      mapbox,
      'traffic-shapes-source',
      traffic.map(({ features: { shape } }) => shape)
    )
  } else {
    renderSource<ShapeProps>(mapbox, 'traffic-shapes-source', [])
    renderSource<IconProps>(
      mapbox,
      'traffic-source',
      traffic.map(({ features: { icon } }) => icon)
    )
  }
}

export const renderPortcalls = (
  mapbox: Mapbox,
  displayState: VesselDisplayState,
  portcalls: Array<PortVessel | HandPickedVessel>
) => {
  if (displayState === VesselDisplayState.SHAPES) {
    renderSource<IconProps>(mapbox, 'portCalls-source', [])
    renderSource<ShapeProps>(
      mapbox,
      'portcall-shapes-source',
      portcalls.map(({ features: { shape } }) => shape)
    )
  } else {
    renderSource<ShapeProps>(mapbox, 'portcall-shapes-source', [])
    renderSource<IconProps>(
      mapbox,
      'portCalls-source',
      portcalls.map(({ features: { icon } }) => icon)
    )
  }
}
export const clearTraffic = (mapbox: Mapbox) => clearSource(mapbox, 'traffic-source')
export const clearPortcalls = (mapbox: Mapbox) => clearSource(mapbox, 'portCalls-source')

export const renderHoverMarker = (mapbox: Mapbox, markers: number[][]) =>
  renderSource<{}>(
    mapbox,
    'hoverMarker-source',
    markers.map(coordinates => ({
      type: 'Feature',
      properties: {},
      geometry: { type: 'Point', coordinates },
    }))
  )

type EventType = 'click' | 'mouseleave' | 'mousemove'

export const fromLayerEvent = <Event,>(mapbox: Mapbox, layer: string, event: EventType) =>
  fromEventPattern<Event>(
    handler => mapbox.on(event, layer, handler),
    handler => mapbox.off(event, layer, handler)
  )

type State = Readonly<{
  mapbox: Mapbox
  userMapStyle: UserMapStyle
  setUserMapStyle: Dispatch<SetStateAction<UserMapStyle>>
}>

const Context = createContext<State>({} as State)

export const useMapboxContext = () => useContext<State>(Context)

const isMovingOrZooming = (mapbox: Mapbox) => mapbox.isMoving() || mapbox.isZooming()

const useMapResize = (mapbox: Mapbox) => {
  const { current: mapContainer } = useRef(mapbox.getContainer())

  useEffect(() => {
    const subscription = resizeObservable(mapContainer)
      .pipe(
        debounceTime(5),
        switchMap(() => {
          if (!isMovingOrZooming(mapbox)) {
            return of(1)
          }

          return interval(100).pipe(
            takeUntil(interval(100).pipe(filter(() => !isMovingOrZooming(mapbox)))),
            takeLast(1)
          )
        })
      )
      .subscribe(() => {
        mapbox.resize()
      })

    return subscription.unsubscribe.bind(subscription)
  }, [mapContainer, mapbox])
}

export enum UserMapStyle {
  DEFAULT = 'DEFAULT',
  SATELLITE = 'SATELLITE',
}

export function MapboxProvider({ children, mapbox }: PropsWithChildren<{ mapbox: Mapbox }>) {
  const { isDarkMode } = useDarkMode()
  const [userMapStyle, setUserMapStyle] = useState<UserMapStyle>(UserMapStyle.DEFAULT)
  useMapResize(mapbox)

  useEffect(() => {
    switch (userMapStyle) {
      case UserMapStyle.DEFAULT: {
        changeMapStyle(mapbox, isDarkMode ? MAPBOX_DARK_STYLE_ID : MAPBOX_LIGHT_STYLE_ID)
        break
      }
      case UserMapStyle.SATELLITE: {
        changeMapStyle(mapbox, MAPBOX_SATELLITE_STYLE_ID)
        break
      }
      default: {
        defaultNever(userMapStyle)
      }
    }
  }, [mapbox, isDarkMode, userMapStyle])

  return (
    <Context.Provider
      value={{
        mapbox,
        userMapStyle,
        setUserMapStyle,
      }}
    >
      {children}
    </Context.Provider>
  )
}
