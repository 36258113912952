import { Unlocode } from '../Domain/Port'
import { Brand } from '../lib/brand'
import { MMSI, PortVessel } from './Vessel'
import { ShipIdentifier } from './Ship'
import { Option, fromNullable, chain, fold } from 'fp-ts/es6/Option'
import { Country, findCountryByAlpha2 } from './Countries'
import { pipe } from 'fp-ts/es6/function'
import { VesselTypeStore } from './ShipType'

export type IMO = Brand<string, 'IMO'>
export type ENI = Brand<string, 'ENI'>
export type USCG = Brand<string, 'USCG'>
type ZonedDateTime = Brand<string, 'ZonedDateTime'>
type CallSign = Brand<string, 'CallSign'>
type EventPortcallId = Brand<string, 'Port call id'>

export type ShipTrackerEvent = {
  eventType: string
  eventTime: ZonedDateTime
  eventTimeLocal?: ZonedDateTime
  recordTime: ZonedDateTime
}

export type Ship = Readonly<
  {
    name?: string
    callSign?: CallSign
  } & ShipIdentifier
>

export type VisitJson = Readonly<{
  eventPortcallId: EventPortcallId
  ship: Ship & { mmsi: MMSI }
  port: Unlocode
  startEvent: ShipTrackerEvent
  shipMasterData?: {
    flag?: Country['alpha2']
    spireVesselType?: string
  }
}>

export type Visit = Pick<VisitJson, 'eventPortcallId' | 'port'> & {
  mmsi: MMSI
  arrivalTime?: ShipTrackerEvent
  country: Option<Country>
  spireVesselType?: string
}

export const visitFromJson = ({ eventPortcallId, ship, port, startEvent, shipMasterData }: VisitJson): Visit => ({
  mmsi: ship.mmsi,
  eventPortcallId,
  port,
  arrivalTime: startEvent,
  country: pipe(shipMasterData?.flag, fromNullable, chain(findCountryByAlpha2)),
  spireVesselType: shipMasterData?.spireVesselType,
})

export function shipTypesSum(acc: VesselTypeStore, { properties: { spireVesselType } }: PortVessel) {
  if (spireVesselType) {
    acc[spireVesselType] = (acc[spireVesselType] || 0) + 1
  }
  return acc
}

export const countrySum = (acc: { [key: number]: number }, { properties: { country } }: PortVessel) =>
  pipe(
    country,
    fold(
      () => acc,
      ({ id }) => ({ ...acc, [id]: (acc[id] ?? 0) + 1 })
    )
  )

export function vesselStatusesSum(acc: { [key: string]: number }, { properties: { status } }: PortVessel) {
  acc[status] = acc[status] || 0
  acc[status] += 1

  return acc
}
