import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'
import { useResponsiveness } from '../lib/hooks/useResponsiveness'
import { Logo } from '../UI/Logo'
import styles from './Flow.module.scss'

type FlowProps = {
  title: string
  isLoading: boolean
  stepsData: {
    key: string
    label: string
    isActive: boolean
  }[]
}
export const Flow = ({ title, children, isLoading, stepsData }: PropsWithChildren<FlowProps>) => {
  const { isMobile } = useResponsiveness()

  return (
    <div className={styles.flowContainer}>
      {isMobile ? (
        <div className={styles.mobileTopBar}>
          <div className={styles.logoContainer}>
            <Logo className={styles.logo} />
          </div>
          <h1 className={styles.title}>{title}</h1>
        </div>
      ) : (
        <div className={styles.menuDrawer}>
          <div className={styles.header}>
            <div className={styles.logoContainer}>
              <Logo className={styles.logo} />
            </div>
            <h1 className={styles.title}>{title}</h1>
          </div>
          <ul className={styles.stepsList}>
            {stepsData.map(({ key, isActive, label }, index) => (
              <li
                className={classNames(styles.stepItem, { [styles.stepItemActive]: !isLoading && isActive })}
                key={key}
              >
                <span className={styles.stepIndicator}>{index + 1}</span> {label}
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className={styles.content}>{children}</div>
      {isMobile && (
        <div className={styles.mobileFooter}>
          <h2 className={styles.title}>{stepsData.find(({ isActive }) => isActive)?.label ?? ''}</h2>
          <ul className={styles.stepsList}>
            {stepsData.map(({ key, isActive }, index, array) => (
              <>
                <span
                  key={key}
                  className={classNames(styles.stepIndicator, {
                    [styles.stepIndicatorActive]: !isLoading && isActive,
                  })}
                >
                  {index + 1}
                </span>
                {array.length - 1 !== index && <span className={styles.stepDivider} />}
              </>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}
