import React from 'react'
import { VesselProperties, HandPickedVesselProperties, PortVesselProperties } from '../../Domain/Vessel'

import styles from './Marker.module.scss'
import { BasicDetails } from '../VesselDetails/BasicDetails'

type VesselMarkerProps = Readonly<{ properties: VesselProperties | HandPickedVesselProperties | PortVesselProperties }>

export const VesselMarker = ({
  properties: {
    shipName,
    mmsi,
    destinationPort,
    speedOverGround,
    reportedArrivalTime,
    predictedArrivalTime,
    actualArrivalTime,
  },
}: VesselMarkerProps) => (
  <>
    <h3 className={styles.markerHeader}>{shipName || mmsi}</h3>
    <BasicDetails
      destinationPort={destinationPort}
      speedOverGround={speedOverGround}
      reportedArrivalTime={reportedArrivalTime}
      predictedArrivalTime={predictedArrivalTime}
      actualArrivalTime={actualArrivalTime}
    />
  </>
)
