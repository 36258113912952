type AccountIconProps = { className?: string }
export const AccountIcon = ({ className }: AccountIconProps) => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <g id="00-login" transform="translate(-1326.000000, -15.000000)" fillRule="nonzero">
      <g id="01_Header" transform="translate(-23.000000, -13.000000)">
        <g id="Group-14" transform="translate(44.000000, 0.000000)">
          <g id="right" transform="translate(970.000000, 0.157585)">
            <g id="date02" transform="translate(233.000000, 0.000000)">
              <g id="iconfinder_8_3898372" transform="translate(102.000000, 26.842415)">
                <path
                  d="M13.4090909,10.6991145 C15.2147078,9.25029677 15.9090812,6.81951947 15.1412,4.63555894 C14.3733189,2.45159841 12.3104762,0.990259088 9.99545455,0.990259088 C7.68043293,0.990259088 5.61759023,2.45159841 4.84970907,4.63555894 C4.08182792,6.81951947 4.77620129,9.25029677 6.58181818,10.6991145 C2.63264089,12.1355353 0.0028377193,15.8877268 0,20.0900236 L0,20.9991145 L20,20.9991145 L20,20.0900236 C19.9957721,15.8853928 17.3618514,12.1325099 13.4090909,10.6991145 Z M6.36363636,6.45365992 C6.36363636,4.44535174 7.99169182,2.81729628 10,2.81729628 C12.0083082,2.81729628 13.6363636,4.44535174 13.6363636,6.45365992 C13.6363636,8.4619681 12.0083082,10.0900236 10,10.0900236 C7.99169182,10.0900236 6.36363636,8.4619681 6.36363636,6.45365992 Z M1.87272727,19.1809326 C2.33294329,15.037226 5.83536049,11.9022655 10.0045455,11.9022655 C14.1737304,11.9022655 17.6761476,15.037226 18.1363636,19.1809326 L1.87272727,19.1809326 Z"
                  id="Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

type AccountUserProfileIconProps = { className?: string }
export const AccountUserProfileIcon = ({ className }: AccountUserProfileIconProps) => (
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <g id="Page-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="02a-Default-Porft" transform="translate(-41.000000, -335.000000)" fill="#FFFFFF" fillRule="nonzero">
        <g id="item03" transform="translate(0.000000, 314.000000)">
          <g id="create-24px" transform="translate(38.000000, 18.000000)">
            <path
              d="M3,17.25 L3,21 L6.75,21 L17.81,9.94 L14.06,6.19 L3,17.25 Z M20.71,7.04 C21.1,6.65 21.1,6.02 20.71,5.63 L18.37,3.29 C17.98,2.9 17.35,2.9 16.96,3.29 L15.13,5.12 L18.88,8.87 L20.71,7.04 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

type AccountStatusProps = { className?: string }
export const AccountStatus = ({ className }: AccountStatusProps) => (
  <svg
    width="29px"
    height="29px"
    viewBox="0 0 29 29"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <defs>
      <path
        d="M8.94171429,0.342857143 C8.75587918,0.211098368 8.52523824,0.158735983 8.30072707,0.197333891 C8.07621591,0.235931799 7.87630479,0.362314595 7.74514286,0.548571429 L4.05942857,5.75314286 L1.77942857,3.31885714 C1.45563191,2.97328469 0.913001029,2.95563191 0.567428571,3.27942857 C0.221856114,3.60322523 0.204203341,4.14585611 0.528,4.49142857 L4.24457143,8.46171429 L9.14742857,1.52228571 C9.40837497,1.13804752 9.31733181,0.61606675 8.94171429,0.342857143 L8.94171429,0.342857143 Z"
        id="path-1"
      ></path>
    </defs>
    <rect id="Mask-Copy" fill="#46BE41" x="15" y="0" width="14" height="14" rx="7"></rect>
    <g id="Group" transform="translate(16.000000, 1.000000)">
      <g id="DBS-Icons-Bold-21" transform="translate(1.371429, 1.714286)">
        <mask id="mask-2" fill="white">
          <use xlinkHref="#path-1"></use>
        </mask>
        <use id="Path" fill="#002E5C" xlinkHref="#path-1"></use>
        <g id="Group" mask="url(#mask-2)">
          <g transform="translate(-1.371429, -1.714286)" id="Kleur/cyaan">
            <rect id="Rectangle" fill="#FFFFFF" x="0" y="0" width="12" height="12"></rect>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

type AccountLogoutIconProps = { className?: string }
export const AccountLogoutIcon = ({ className }: AccountLogoutIconProps) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <g id="Page-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
      <path
        d="M10.09,15.59 L11.5,17 L16.5,12 L11.5,7 L10.09,8.41 L12.67,11 L3,11 L3,13 L12.67,13 L10.09,15.59 Z M19,3 L5,3 C3.89,3 3,3.9 3,5 L3,9 L5,9 L5,5 L19,5 L19,19 L5,19 L5,15 L3,15 L3,19 C3,20.1 3.89,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z"
        id="Shape"
        fill="#0693CA"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
)

type AccountMapIconProps = { className?: string }
export const AccountMapIcon = ({ className }: AccountMapIconProps) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <title>map-24px</title>
    <g id="Page-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="02a-Default-Porft" transform="translate(-38.000000, -902.000000)">
        <g id="item06" transform="translate(0.000000, 884.000000)">
          <g id="map-24px" transform="translate(38.000000, 18.000000)">
            <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
            <path
              d="M20.5,3 L20.34,3.03 L15,5.1 L9,3 L3.36,4.9 C3.15,4.97 3,5.15 3,5.38 L3,20.5 C3,20.78 3.22,21 3.5,21 L3.66,20.97 L9,18.9 L15,21 L20.64,19.1 C20.85,19.03 21,18.85 21,18.62 L21,3.5 C21,3.22 20.78,3 20.5,3 Z M15,19 L9,16.89 L9,5 L15,7.11 L15,19 Z"
              id="Shape"
              fill="#FFFFFF"
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
