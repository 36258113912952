import { useState, useEffect, useRef, useCallback } from 'react'
import { Subject, of, concat, defer, empty } from 'rxjs'
import { distinctUntilChanged, switchMap, delay, map } from 'rxjs/operators'

type InitialSearch = Readonly<{ kind: 'INITIAL_SEARCH'; query: string }>
type ComposingSearch = Readonly<{ kind: 'COMPOSING_QUERY'; query: string }>
type LoadingSearch = Readonly<{ kind: 'LOADING'; query: string }>
type LoadedSearch<R> = Readonly<{ kind: 'LOADED'; query: string; results: R }>

export type SearchState<R> = InitialSearch | ComposingSearch | LoadingSearch | LoadedSearch<R>

const initialSearchState: InitialSearch = { kind: 'INITIAL_SEARCH', query: '' }

const loadingSearchState = (query: string): LoadingSearch => ({ kind: 'LOADING', query })

const loadedSearchState = <R>(results: R, query: string): LoadedSearch<R> => ({
  kind: 'LOADED',
  results,
  query,
})

export const composingSearchState = (query: string): ComposingSearch => ({ kind: 'COMPOSING_QUERY', query })

type SearchStateOptions = Readonly<{
  debounceTime: number
  minimumQueryLength: number
  initialTerm?: string
}>

type Search<R> = (query: string) => Promise<R>

export function useSearchState<R>(
  search: Search<R>,
  { minimumQueryLength, debounceTime, initialTerm }: SearchStateOptions
) {
  const query = useRef(new Subject<string>())
  const [state, setState] = useState<SearchState<R>>(initialSearchState)
  const searchCallback = useCallback((query: string) => search(query), [search])

  useEffect(() => {
    const sub = query.current
      .pipe(
        distinctUntilChanged((prev, next) => prev === next),
        switchMap(term => {
          if (term.length === 0) {
            return of(initialSearchState)
          } else if (term.length < minimumQueryLength) {
            return of(composingSearchState(term))
          } else {
            return concat(
              // Show loading state
              of(loadingSearchState(term)),
              // Wait for changes
              empty().pipe(delay(debounceTime)),
              // Run search
              defer(() => searchCallback(term)).pipe(map(results => loadedSearchState(results, term)))
            )
          }
        })
      )
      .subscribe(setState)

    return sub.unsubscribe.bind(sub)
  }, [query, minimumQueryLength, debounceTime, searchCallback])

  useEffect(() => {
    if (initialTerm) {
      query.current.next(initialTerm)
    }
  }, [initialTerm])

  return {
    searchState: state,
    resetSearchState: () => query.current.next(''),
    onQueryChange: (value: string) => {
      query.current.next(value)
    },
  }
}
