import { Visit, VisitJson, visitFromJson } from '../../Domain/PortCall'
import { BACKEND_URL } from '../../config'
import ky from 'ky'
import { Unlocode } from '../../Domain/Port'

const cache: Record<Unlocode, Visit[]> = {}

export const fetchPortVisitsPublic = (port: Unlocode) =>
  ky
    .get(`${BACKEND_URL}/api/${port.toLowerCase()}/visits`)
    .json<VisitJson[]>()
    .then((res): Visit[] => res.map(visitFromJson))
    .then(visits => {
      cache[port] = visits

      return visits
    })

export const fetchStoredVisitsPublic = async (port: Unlocode) =>
  port in cache ? cache[port] : await fetchPortVisitsPublic(port)
