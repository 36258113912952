import { store } from './../dataStore'
import { Option } from 'fp-ts/es6/Option'
import { Unlocode } from './Port'
import { ZonedDateTime } from './DateTime'
import { CompanyType } from './Company'
import { uniqArray } from '../lib/utils'

export enum SystemOfMeasurement {
  Metric = 'Metric',
  Imperial = 'Imperial',
}

export type User = Readonly<{ port: Option<Unlocode>; id: string }>

type Company = {
  name: string
  companyType: CompanyType
}

export enum MetadataOption {
  'NO_BANNERS' = 'no_banners',
  'CUSTOM_STYLES' = 'custom_styles',
}

type OnboardingStatus = 'not_started' | 'in_progress' | 'completed'

export type Metadata = {
  name?: string
  givenName?: string
  familyName?: string
  defaultPort?: Unlocode
  consentEmailMarketing?: boolean
  acceptedTerms?: ZonedDateTime
  acceptedApiTerms?: ZonedDateTime
  accountType: 'business' | 'private'
  createdAt: ZonedDateTime
  company?: Company
  subscriptionExpirationDate?: ZonedDateTime
  isInTrialPeriod?: boolean
  onboardingApiStatus?: OnboardingStatus
  onboardingGuiStatus?: OnboardingStatus
  phoneNumber?: string
  autoTopUpCredits?: boolean
  options: MetadataOption[]
  products?: ('Shiptracker' | 'Shiptracker API')[]
  measurementUnit: SystemOfMeasurement
}

export type MetadataJson = Omit<Metadata, 'measurementUnit'> & {
  measurementUnit?: SystemOfMeasurement
}

export const addMetadataProduct = async (product: 'Shiptracker' | 'Shiptracker API'): Promise<Metadata['products']> => {
  const metadata = await store.metadata.fetch()
  return uniqArray([...(metadata.products ?? []), product])
}

export const fetchMeasurementUnit = async (isAuthenticated: boolean) =>
  isAuthenticated ? store.metadata.fetch().then(({ measurementUnit }) => measurementUnit) : SystemOfMeasurement.Metric
