import React from 'react'
import classnames from 'classnames'
import MaterialIcon from '../MaterialIcon'

import styles from './CloseButton.module.scss'

export type CloseButtonTheme = Readonly<{ button: string; icon: string }>

type CloseButtonProps = Readonly<{
  theme: CloseButtonTheme
  text?: JSX.Element
  onClose?: (e: React.MouseEvent) => void
}>

export const CloseButton = ({ onClose, text, theme: { button, icon } }: CloseButtonProps) => (
  <button type="button" onClick={onClose} className={classnames(styles.button, button)}>
    {text}
    <MaterialIcon type="close" className={icon} />
  </button>
)
