import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import { SHOW_CASE } from '../../config'
import { parseJwt } from '../parseJWT'
import { useFromFetch } from '../useFromFetch'

export enum Feature {
  HANDPICKED_LIST = 'shiptracker:edit:fleet',
  PORTCALLS = 'shiptracker:read:visits:eta',
  CREATE_API_KEY = 'shiptracker:create:api-key',
}

const SHOW_CASE_FEATURES: Record<string, { [feature in Feature]: boolean }> = {
  NLRTM: {
    [Feature.PORTCALLS]: true,
    [Feature.HANDPICKED_LIST]: true,
    [Feature.CREATE_API_KEY]: false, // todo: make sure it's false
  },
}

export const useFeatureToggler = (feature: Feature): { isEnabled: boolean; isFeatureLoading: boolean } => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()
  const { data, isLoading } = useFromFetch(getAccessTokenSilently)
  const [isEnabled, setIsEnabled] = useState(false)
  const [isFeatureLoading, setIsFeatureLoading] = useState(true)

  useEffect(() => {
    if (SHOW_CASE !== undefined) {
      setIsEnabled(SHOW_CASE_FEATURES[SHOW_CASE][feature])
    } else if (!isLoading && data !== undefined) {
      const { scope } = parseJwt<{ scope: string }>(data)
      setIsEnabled(scope.includes(feature))
    }
    setIsFeatureLoading(false)
  }, [data, isLoading, feature, isAuthenticated])

  return { isEnabled, isFeatureLoading }
}
