import { UseFromFetchState } from './useFromFetch'

type RenderQueryResultProps<Result> = Readonly<{
  query: UseFromFetchState<Result>
  ErrorComponent: JSX.Element
  LoadingComponent: JSX.Element
  children: (result: Result) => JSX.Element
}>
export const RenderQueryResult = <T extends {}>({
  query: { isLoading, error, data },
  ErrorComponent,
  LoadingComponent,
  children,
}: RenderQueryResultProps<T>) => {
  if (isLoading && !data) {
    return LoadingComponent
  }
  if (!error && data) {
    return children(data)
  }

  return ErrorComponent
}
