import { Auth0Error } from 'auth0-js'
import React from 'react'
import classnames from 'classnames'
import { CloseButton } from '../../UI/CloseButton'
import { getAuth0ErrorDescription } from './AuthenticationForm'
import styles from './AuthenticationForm.module.scss'

type Auth0ErrorComponentProps = { auth0Error: Auth0Error; handleClose: () => void; description?: string }
export const Auth0ErrorComponent = ({ auth0Error, handleClose }: Auth0ErrorComponentProps) => {
  const errorDescription = getAuth0ErrorDescription(auth0Error)
  return (
    <div className={classnames(styles.messageBox, styles.error)}>
      <div className={styles.messageBoxContent}>
        <span>Something went wrong</span>
        <p className={styles.description}>
          {typeof errorDescription === 'string' ? errorDescription : 'Please try again later'}
        </p>
      </div>
      <CloseButton onClose={handleClose} theme={{ button: styles.closeButton, icon: styles.closeButtonIcon }} />
    </div>
  )
}
