import { createStore, createHook, Action } from 'react-sweet-state'
import { Option, none, some } from 'fp-ts/es6/Option'

type State = {
  isSideMenuOpen: Option<boolean>
}

const initialState: State = {
  isSideMenuOpen: none,
}

const actions = {
  closeSideMenu: (): Action<State> => ({ getState, setState }) => {
    setState({ ...getState(), isSideMenuOpen: some(false) })
  },

  openSideMenu: (): Action<State> => ({ getState, setState }) => {
    setState({ ...getState(), isSideMenuOpen: some(true) })
  },

  resetSideMenu: (): Action<State> => ({ getState, setState }) => {
    setState({ ...getState(), isSideMenuOpen: none })
  },
}

type Actions = typeof actions

const Store = createStore<State, Actions>({ initialState, actions })

export const useAccountState = createHook(Store)
