import { AtLeastOne } from '../lib/AtLeastOne'
import { IMO, ENI, USCG } from './PortCall'
import { MMSI } from './Vessel'

export type Identifier = Readonly<{ imo: IMO; eni: ENI; mmsi: MMSI; uscg: USCG }>

type ShipIdentifierKey = keyof Identifier

export const shipIdentifiers: ShipIdentifierKey[] = ['imo', 'eni', 'mmsi', 'uscg']

export type ShipIdentifier = AtLeastOne<Identifier>

export type SearchResultShip = { name?: string } & ShipIdentifier

export const paramsFromShipIdentifiers = (ship: SearchResultShip) =>
  shipIdentifiers
    .reduce((acc: string[], key) => {
      const value = ship[key]

      return value ? [...acc, `${key}=${value}`] : acc
    }, [])
    .join('&')

export const isShipIndentifierKey = (key: string): key is ShipIdentifierKey => (shipIdentifiers as any[]).includes(key)
