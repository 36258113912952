import classnames from 'classnames'
import { ErrorMessage, useField } from 'formik'
import React from 'react'
import { InputError } from '../InputError'
import styles from './AuthenticationForm.module.scss'

type InputEmailProps = { name: string; label: string; className?: string }
export const InputEmail: React.FC<InputEmailProps> = ({ name, label, className }) => {
  const [field, meta] = useField({ name })
  return (
    <div className={classnames(styles.inputField, className)}>
      <label className={styles.label}>{label}</label>
      <input
        {...field}
        type="email"
        placeholder={label}
        className={classnames({ [styles.error]: meta.error && meta.touched })}
      />
      <ErrorMessage name={name} component={InputError} />
    </div>
  )
}
