import React from 'react'

export type LinkProps = { onClick?: () => void; href: string; className?: string }
export const Link: React.FC<LinkProps> = ({ onClick, href, className, children }) => (
  <a
    href={href}
    onClick={e => {
      if (onClick) {
        e.preventDefault()
        onClick()
      }
    }}
    className={className}
  >
    {children}
  </a>
)
