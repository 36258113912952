import { switchMap, filter, map } from 'rxjs/operators'
import { fetchAISJwtToken } from '../Api/fetchAISJwtToken'
import { ReplaySubject, defer } from 'rxjs'
import { MINUTE } from '../constants'
import { client } from './client'

const TOKEN_EXPIRY_TIME = 59 * MINUTE

enum Status {
  LOADED = 'LOADED',
  EXPIRED = 'EXPIRED',
}

type ExpiredState = { kind: Status.EXPIRED }
type LoadedState = { kind: Status.LOADED; token: string }

type State = ExpiredState | LoadedState

const expired: ExpiredState = { kind: Status.EXPIRED }
const loaded = (token: string): LoadedState => ({ kind: Status.LOADED, token })

const isLoadedState = (state: State): state is LoadedState => state.kind === Status.LOADED
const isExpiredState = (state: State): state is ExpiredState => state.kind === Status.EXPIRED

const tokenState = new ReplaySubject<State>(1)

tokenState.pipe(filter(isExpiredState)).subscribe(async () => {
  const token = await fetchAISJwtToken()

  setTimeout(() => {
    tokenState.next(expired)
  }, TOKEN_EXPIRY_TIME)

  tokenState.next(loaded(token))
})

const aisJwtToken = tokenState.pipe(
  filter(isLoadedState),
  map(({ token }) => token)
)

tokenState.next(expired)

export const aisToken$ = client.pipe(
  switchMap(({ isAuthenticated, getTokenSilently }) => (isAuthenticated ? defer(getTokenSilently) : aisJwtToken))
)
