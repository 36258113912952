import React, { PropsWithChildren, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useFromFetch } from '../lib/useFromFetch'
import { store } from '../dataStore'
import { useHistory } from 'react-router-dom'
import { Pages } from '../constants'

type HandleOnboardingRedirectProps = PropsWithChildren<{}>

export function HandleOnboardingRedirect({ children }: HandleOnboardingRedirectProps) {
  const { isAuthenticated } = useAuth0()
  const { isLoading, data } = useFromFetch(isAuthenticated ? store.metadata.fetch : () => Promise.resolve(undefined))
  const history = useHistory()

  useEffect(() => {
    if (
      !isLoading &&
      data !== undefined &&
      (data.onboardingGuiStatus !== 'completed' || data.defaultPort === undefined)
    ) {
      history.push(Pages.CREATE_ACCOUNT)
    }
  }, [history, isLoading, data])

  return isLoading ? null : <>{children}</>
}
