import React, { CSSProperties, PropsWithChildren } from 'react'
import classnames from 'classnames'

type MarkerProps = PropsWithChildren<{ className?: string; styles: CSSProperties }>

export const Marker = ({ className, children, styles }: MarkerProps) => (
  <div style={styles} className={classnames('mapboxgl-marker', className)}>
    {children}
  </div>
)
