import { Map as Mapbox } from 'mapbox-gl'
import { useState, useEffect, useRef } from 'react'
import { Option, none, some } from 'fp-ts/es6/Option'
import { from, noop } from 'rxjs'

export function useMapbox(createMap: (node: HTMLDivElement) => Promise<Mapbox>) {
  const mapNode = useRef<HTMLDivElement | null>(null)
  const [mapbox, setMap] = useState<Option<Mapbox>>(none)

  useEffect(() => {
    if (mapNode.current === null) {
      return noop
    }

    const subscription = from(createMap(mapNode.current)).subscribe(map => setMap(some(map)))

    return subscription.unsubscribe.bind(subscription)
  }, [mapNode, createMap])

  return {
    mapbox,
    onMount: mapNode,
  }
}
