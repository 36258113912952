import React from 'react'
import styles from './Modal.module.scss'
import { usePortal } from '../../lib/hooks/usePortal'

export type ModalProps = {
  isVisible?: boolean
  onClose: () => void
}

export const Modal: React.FC<ModalProps> = ({ children, isVisible, onClose }) => {
  const { Portal } = usePortal()

  return isVisible ? (
    <Portal>
      <div className={styles.modalParent} onClick={e => e.stopPropagation()}>
        <div className={styles.backdrop} onClick={onClose} />
        <div className={styles.modal} role="dialog" aria-hidden="true">
          {children}
        </div>
      </div>
    </Portal>
  ) : null
}
