import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export const useRedirectToPath = (shouldRedirect: boolean, path: string) => {
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (shouldRedirect && location.pathname !== path) {
      history.replace(`${path}`)
    }
  })
}
