import React from 'react'

type LayerIconProps = Readonly<{
  width?: number
  height?: number
  color?: string
  className?: string
}>

const topPath =
  'm 46.510371,14.978671 -22.41715,-8.897366 -22.41715,8.897366 22.41715,8.897366 z M 24.093221,9.300372 38.375099,14.978671 24.093221,20.584012 9.811343,14.978671 Z'
const middlePath =
  'M 44.268079,20.450551 24.093221,28.518683 3.9183624,20.450551 a 1.60701,1.4882943 0 1 0 -1.2815839,2.729712 l 21.4564425,8.569943 21.456442,-8.569943 a 1.60701,1.4882943 0 0 0 -1.281584,-2.729712 z'
const bottomPath =
  'M 44.268079,29.141498 24.093221,37.20785 3.9183624,29.141498 a 1.6005388,1.4823012 0 1 0 -1.2815839,2.713697 l 21.4564425,8.585958 21.456442,-8.585958 a 1.6005388,1.4823012 0 1 0 -1.281584,-2.713697 z'

export const LayerIcon = (props: LayerIconProps) => (
  <svg
    version="1.1"
    id="Chevron_left"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 48 48"
    width={props.width}
    height={props.height}
    className={props.className}
    style={{ display: 'block' }}
    xmlSpace="preserve"
  >
    <path fill={props.color} d={topPath} />
    <path fill={props.color} d={middlePath} />
    <path fill={props.color} d={bottomPath} />
  </svg>
)
