import { Brand } from '../lib/brand'
import { Point, Polygon } from 'geojson'
import { AtLeastOne } from '../lib/AtLeastOne'
import center from '@turf/center'
import { Option, some, none } from 'fp-ts/es6/Option'

export type Unlocode = Brand<string, 'Port Unlocode'>

export const createUnlocode = (str: string): Unlocode => str as Unlocode

export type Port = {
  aliasses: string[]
  name: string
  port: Unlocode
} & AtLeastOne<{
  center: Point
  geo: Polygon
}>

export function portCenter(port: Port): Option<[number, number]> {
  if (port.center) {
    const [x, y] = port.center.coordinates
    return some([x, y])
  }

  if (port.geo?.coordinates !== undefined) {
    const [x, y] = center(port.geo).geometry.coordinates

    return some([x, y])
  }

  return none
}
