import { MINUTE } from '../../constants'
import { AIS_BACKEND_URL } from '../../config'
import { VesselSource, TrafficVessel, VesselJson } from '../../Domain/Vessel'
import { iconFeatureFromJson } from '../../Domain/VesselFeature'
import { vesselShapePolygon } from '../../Map/helpers/vesselShapPolygon'
import { Tile } from '../../Map/helpers/tiles'
import { aisAuthRequest } from '../auth'
import { vesselPropertiesFromJson } from '../../Domain/Vessel'

const CACHE_EXPIRY_TIME = 5 * MINUTE

const keyFromCell = (z: number, x: number, y: number) => `cell:(${z},${x},${y})`

const cacheIsExpired = (timestamp: number) => timestamp < Date.now() - CACHE_EXPIRY_TIME

const cache: { [key: string]: { vessels: TrafficVessel[]; timestamp: number } } = {}

export async function fetchTiles(zoom: number, { x, y }: Tile) {
  const key = keyFromCell(zoom, x, y)

  if (!(key in cache) || cacheIsExpired(cache[key].timestamp)) {
    try {
      const vessels = await aisAuthRequest
        .get(`${AIS_BACKEND_URL}/map/${zoom}/${x}/${y}/vessels`)
        .json<VesselJson[]>()
        .then((vesselsJson): TrafficVessel[] =>
          vesselsJson.map(json => ({
            properties: { ...vesselPropertiesFromJson(json), source: VesselSource.AIS },
            features: {
              icon: iconFeatureFromJson(json, VesselSource.AIS),
              shape: vesselShapePolygon(json, VesselSource.AIS),
            },
          }))
        )

      cache[key] = { vessels, timestamp: Date.now() }
    } catch (e) {
      return []
    }
  }

  return cache[key].vessels
}
