import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { useCombobox } from 'downshift'

import styles from './Search.module.scss'
import { SearchState, useSearchState } from '../lib/hooks/useSearchState'
import { ShipIdentifiers, SearchResultsLoading, SearchResultsNotice } from './SearchResults'
import { VesselSearchResult } from '../Domain/VesselSearchResult'
import MaterialIcon from '../UI/MaterialIcon'
import { fetchVesselSearch } from '../Api/fetchVessels'
import { MINIMUM_QUERY_LENGTH } from './constants'
import { usePortal } from '../lib/hooks/usePortal'
import { CloseButton, CloseButtonTheme } from '../UI/CloseButton'
import { useTranslation } from '../lib/i18n'
import { SHIP_TRACKER_THEME_CSS, SHIP_TRACKER_THEME_DARK_CSS } from '../constants'
import { useDarkMode } from '../lib/hooks/useDarkMode'

const DEBOUNCE_TIME = 500
const RESULTS_LIMIT = 20

type SearchListingProps = Readonly<{ searchState: SearchState<VesselSearchResult[]> }>

function SearchListing({ searchState }: SearchListingProps): JSX.Element | null {
  switch (searchState.kind) {
    case 'COMPOSING_QUERY': {
      return <SearchResultsNotice />
    }
    case 'LOADING': {
      return <SearchResultsLoading />
    }
    default: {
      return null
    }
  }
}

type QuickShipFinderProps = Readonly<{
  className: string
  handleSearchResult: (r: VesselSearchResult, query: string) => void
}>

type InputProps = {
  getProps: () => React.HTMLProps<HTMLInputElement>
}
const Input = ({ getProps }: InputProps) => <input {...getProps()} className={styles.input} type="text" />

type ItemProps = {
  getProps: () => React.HTMLProps<HTMLDivElement>
  isHighlighted?: boolean
  item: VesselSearchResult
}

const Item = ({ getProps, isHighlighted, item }: ItemProps) => {
  return (
    <div {...getProps()} className={classnames(styles.suggestion, { [styles.selected]: isHighlighted })}>
      <div className={styles.name}>
        {item.name}
        {item.spireVesselType ? ` (${item.spireVesselType})` : ''}
      </div>
      <ShipIdentifiers ship={item.ship} />
    </div>
  )
}

function RefineResultsNotice() {
  const { t } = useTranslation()
  return <div className={styles.notice}>{t('ShipTracker.Menu.Search.RefineResultsNotice')}</div>
}

const useSearchCombobox = (
  searchState: SearchState<VesselSearchResult[]>,
  handleSearchResult: QuickShipFinderProps['handleSearchResult'],
  onQueryChange: (value: string) => void
) =>
  useCombobox({
    items: searchState.kind === 'LOADED' ? searchState.results : [],
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        handleSearchResult(selectedItem, searchState.query)
      }
    },
    onInputValueChange: ({ inputValue }) => {
      if (inputValue !== undefined) {
        onQueryChange(inputValue)
      }
    },
  })

const useVesselSearchState = () =>
  useSearchState<VesselSearchResult[]>(fetchVesselSearch, {
    debounceTime: DEBOUNCE_TIME,
    minimumQueryLength: MINIMUM_QUERY_LENGTH,
  })

export function Search({ className, handleSearchResult }: QuickShipFinderProps) {
  const { t } = useTranslation()
  const { searchState, resetSearchState, onQueryChange } = useVesselSearchState()

  const {
    isOpen,
    getToggleButtonProps,
    reset,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useSearchCombobox(searchState, handleSearchResult, onQueryChange)

  useEffect(() => {
    if (isOpen === false && searchState.kind !== 'INITIAL_SEARCH') {
      resetSearchState()
      reset()
    }
  }, [isOpen, resetSearchState, reset, searchState.kind])

  return (
    <div {...getComboboxProps()} className={className}>
      <div {...getToggleButtonProps()} className={styles.toggleWrapper}>
        <MaterialIcon type="search" />
      </div>
      <div className={classnames(styles.search, { [styles.isActive]: isOpen })}>
        <Input
          getProps={() =>
            getInputProps({
              placeholder: t('ShipTracker.Menu.Search.SearchByIdentifier'),
            })
          }
        />
        <div {...getMenuProps()} className={styles.searchListing}>
          {searchState.kind === 'LOADED' ? (
            <>
              {searchState.results.map((item, index) => (
                <Item
                  key={item.ship.mmsi}
                  getProps={() => getItemProps({ item, index })}
                  isHighlighted={highlightedIndex === index}
                  item={item}
                />
              ))}
              {searchState.results.length >= RESULTS_LIMIT && <RefineResultsNotice />}
            </>
          ) : (
            <SearchListing searchState={searchState} />
          )}
        </div>
      </div>
    </div>
  )
}

const closeButtonTheme: CloseButtonTheme = {
  button: styles.closeButton,
  icon: styles.closeButtonIcon,
}

type MobileSearchProps = Readonly<{
  className: string
  handleSearchResult: (r: VesselSearchResult, query: string) => void
}>

export function MobileSearch({ className, handleSearchResult }: MobileSearchProps) {
  const { isDarkMode } = useDarkMode()
  const [isOpen, setIsOpen] = useState(false)
  const { Portal } = usePortal()

  return (
    <>
      <div className={className}>
        <div onClick={() => setIsOpen(true)} className={styles.toggleWrapper}>
          <MaterialIcon type="search" />
        </div>
      </div>
      {isOpen && (
        <Portal>
          <div
            className={classnames(styles.mobileSearch, SHIP_TRACKER_THEME_CSS, {
              [SHIP_TRACKER_THEME_DARK_CSS]: isDarkMode,
            })}
          >
            <h2 className={styles.title}>Find vessel</h2>
            <CloseButton theme={closeButtonTheme} onClose={() => setIsOpen(false)} />
            <MobileSearchBox
              handleSearchResult={(res, query) => {
                setIsOpen(false)
                handleSearchResult(res, query)
              }}
            />
          </div>
        </Portal>
      )}
    </>
  )
}

function MobileSearchBox({ handleSearchResult }: Pick<MobileSearchProps, 'handleSearchResult'>) {
  const { t } = useTranslation()
  const { searchState, onQueryChange } = useVesselSearchState()

  const { getMenuProps, getInputProps, getComboboxProps, highlightedIndex, getItemProps } = useSearchCombobox(
    searchState,
    handleSearchResult,
    onQueryChange
  )

  return (
    <div {...getComboboxProps()} className={styles.comboBox}>
      <div className={styles.inputWrapper}>
        <Input
          getProps={() =>
            getInputProps({
              autoFocus: true,
              placeholder: t('ShipTracker.Menu.Search.SearchByVesselIdentifier'),
            })
          }
        />
      </div>
      <div {...getMenuProps()} className={styles.searchListing}>
        {searchState.kind === 'LOADED' ? (
          <>
            {searchState.results.map((item, index) => (
              <Item
                key={index}
                getProps={() => getItemProps({ item, index })}
                isHighlighted={highlightedIndex === index}
                item={item}
              />
            ))}
            {searchState.results.length >= RESULTS_LIMIT && <RefineResultsNotice />}
          </>
        ) : (
          <SearchListing searchState={searchState} />
        )}
      </div>
    </div>
  )
}
