import { useCallback, useState } from 'react'
import { MenuWrapper, LeftMenu, RightMenu } from './Menu'
import styles from './Header.module.scss'
import { MenuNavigation } from './MenuNavigation/MenuNavigation'
import { useHistory } from 'react-router-dom'
import { paramsFromShipIdentifiers } from '../Domain/Ship'
import { VesselSearchResult } from '../Domain/VesselSearchResult'
import { Pages } from '../constants'

type HeaderProps = {
  isSearchHidden?: boolean
  handleSearchResult?: (result: VesselSearchResult, query: string) => void
}

const Header = ({ handleSearchResult }: HeaderProps) => {
  const [isMenuNavigationOpen, setMenuNavigationOpen] = useState(false)

  const handleClose = useCallback(() => setMenuNavigationOpen(false), [])

  const history = useHistory()

  const handleSearchResultCallback = useCallback(
    async (result, searchTerm) => {
      if (handleSearchResult) {
        return handleSearchResult(result, searchTerm)
      }

      return history.push(`${Pages.MAIN}?${paramsFromShipIdentifiers(result.ship)}`)
    },
    [handleSearchResult, history]
  )

  return (
    <header className={styles.header}>
      <MenuWrapper>
        <LeftMenu isMenuNavigationOpen={isMenuNavigationOpen} setMenuNavigationOpen={setMenuNavigationOpen} />
        <RightMenu handleSearchResult={handleSearchResultCallback} isMenuNavigationOpen={isMenuNavigationOpen} />
      </MenuWrapper>
      <MenuNavigation isOpen={isMenuNavigationOpen} onClose={handleClose} />
    </header>
  )
}

export default Header
