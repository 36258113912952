import ky from 'ky'
import { BACKEND_URL } from '../../config'
import { VisitJson, visitFromJson } from '../../Domain/PortCall'
import { authRequest } from '../auth'
import { Port, Unlocode } from '../../Domain/Port'
import { pipe } from 'fp-ts/es6/function'
import { fold, Option } from 'fp-ts/es6/Option'
import { fetchStoredVisits } from './fetchVisits'
import { MMSI } from '../../Domain/Vessel'
import { fetchStoredVisitsPublic } from './fetchVisitsPublic'

export const fetchPortVisit = (portcallId: string, showCasePort: Option<Port>) =>
  pipe(
    showCasePort,
    fold(
      () => authRequest.get(`${BACKEND_URL}/api/visits/${portcallId}`),
      ({ port }) => ky.get(`${BACKEND_URL}/api/${port.toLowerCase()}/visits/${portcallId}`)
    )
  ).json<VisitJson>()

export const fetchPortVisitFromUcrn = (ucrn: string, { port }: Port) =>
  ky.get(`${BACKEND_URL}/api/${port.toLowerCase()}/visits/ucrn/${ucrn}`).json<VisitJson>().then(visitFromJson)

export const fetchPortVisitByMMSI = (port: Unlocode, mmsi: MMSI, isShowCase: boolean) =>
  (isShowCase ? fetchStoredVisitsPublic(port) : fetchStoredVisits(port)).then(visits =>
    visits.find(v => v.mmsi === mmsi)
  )
