import React from 'react'
import styles from './InlineLoader.module.scss'

export type InlineLoaderTheme = Partial<{
  loaderInline: string
  spinnerWrapper: string
  spinner: string
}>

export const lightBackgroundInlineLoader: InlineLoaderTheme = {
  loaderInline: styles.loaderInline,
  spinnerWrapper: styles.spinnerWrapper,
  spinner: styles.lightBackgroundSpinner,
}

interface IInlineLoaderProps {
  theme?: InlineLoaderTheme
}

// simple and small inline loader
export const InlineLoader = (props: IInlineLoaderProps) => {
  const theme = props.theme || lightBackgroundInlineLoader
  return (
    <div className={theme.loaderInline}>
      <div className={theme.spinnerWrapper}>
        <div className={theme.spinner} />
      </div>
    </div>
  )
}
