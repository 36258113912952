import React, { useCallback, useState } from 'react'
import classnames from 'classnames'
import styles from './Modal.module.scss'
import { CloseButton, CloseButtonTheme } from '../CloseButton'
import { Modal, ModalProps } from './Modal'
import { useTranslation } from '../../lib/i18n'
import { InlineLoader, InlineLoaderTheme } from '../Loader/InlineLoader'
import { noop } from 'rxjs'

const inlineLoaderTheme: InlineLoaderTheme = {
  spinner: styles.lightBackgroundSpinner,
  loaderInline: styles.loaderInline,
}

const closeButtonTheme: CloseButtonTheme = {
  button: styles.closeButton,
  icon: styles.closeButtonIcon,
}

type ConfirmationModalTheme = { title?: string; text?: string }

type ConfirmationModalProps = Pick<ModalProps, 'isVisible'> & {
  title?: string
  text?: string
  onCancel: () => void
  onConfirm: () => void
  confirmText?: string
  cancelText?: string
  isProcessing: boolean
  theme?: ConfirmationModalTheme
  excludeCancel?: boolean
}

const toAsyncFn = (fn: () => void | Promise<void>) =>
  new Promise<void>(resolve => {
    const val = fn()

    if (val && typeof val.then === 'function') {
      return val.then(resolve)
    }

    resolve()
  })

export const useConfirmationModal = ({
  title,
  text,
  confirmText,
  cancelText,
  theme,
  excludeCancel,
}: Pick<ConfirmationModalProps, 'title' | 'text' | 'confirmText' | 'cancelText' | 'theme' | 'excludeCancel'>): [
  React.FC,
  (callback?: () => void) => void
] => {
  const [isVisible, setIsVisible] = useState(false)
  const [callback, setCallback] = useState<() => void>()
  const [isProcessing, setIsProcessing] = useState(false)

  const askForConfirmation = useCallback((callback?: () => void) => {
    setCallback(() => callback ?? noop)
    setIsVisible(true)
  }, [])

  const handleConfirm = useCallback(async () => {
    if (callback) {
      setIsProcessing(true)
      await toAsyncFn(callback)
    }

    setIsVisible(false)
    setIsProcessing(false)
  }, [callback])

  const handleCancel = useCallback(() => {
    setIsVisible(false)
  }, [])

  const ConfirmationModalContainer: React.FC = ({ children }) => (
    <ConfirmationModal
      title={title}
      text={text}
      confirmText={confirmText}
      cancelText={cancelText}
      isVisible={isVisible}
      isProcessing={isProcessing}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      excludeCancel={excludeCancel}
      theme={theme}
    >
      {children}
    </ConfirmationModal>
  )

  return [ConfirmationModalContainer, askForConfirmation]
}

const defaultTheme: ConfirmationModalTheme = {
  title: styles.title,
  text: styles.text,
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  onCancel,
  onConfirm,
  isVisible,
  isProcessing,
  excludeCancel,
  text,
  title,
  cancelText,
  confirmText,
  children,
  theme = {},
}) => {
  const { t } = useTranslation()

  const modalTheme = { ...defaultTheme, ...theme }

  return (
    <Modal isVisible={isVisible} onClose={onCancel}>
      <header className={styles.header}>
        <CloseButton theme={closeButtonTheme} onClose={onCancel} />
      </header>
      <div className={styles.content}>
        <h2 className={modalTheme.title}>{title}</h2>
        <p className={modalTheme.text} dangerouslySetInnerHTML={{ __html: text || '' }}></p>
        {children}
      </div>
      <footer className={styles.footer}>
        {!excludeCancel && (
          <button disabled={isProcessing} onClick={onCancel} className={classnames(styles.button, styles.cancelButton)}>
            {cancelText || t('ShipTracker.Modal.Default.Confirmation.Cancel')}
          </button>
        )}
        <button disabled={isProcessing} onClick={onConfirm} className={classnames(styles.button, styles.confirmButton)}>
          {isProcessing ? (
            <span className={styles.loadingStatus}>
              <InlineLoader theme={inlineLoaderTheme} /> <span>{t('ShipTracker.Common.Processing')}</span>
            </span>
          ) : (
            confirmText || t('ShipTracker.Modal.Default.Confirmation.Confirm')
          )}
        </button>
      </footer>
    </Modal>
  )
}
