import { pipe } from 'fp-ts/es6/function'
import { fromNullable, isSome } from 'fp-ts/es6/Option'
import { useEffect, useState } from 'react'
import { defer } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { fetchPortName } from '../../Api/Port/fetchPortName'
import { fetchPortVisitByMMSI } from '../../Api/Port/fetchVisit'
import { fetchNextVisitByMMSI } from '../../Api/Vessel/fetchNextVisitByMMSI'
import { getShowCasePort } from '../../constants'
import { createUnlocode, Port } from '../../Domain/Port'
import { visitFromJson } from '../../Domain/PortCall'
import {
  AllVesselProperties,
  createMMSI,
  handPickedFromVesselVisit,
  portcallFromVesselVisit,
  trafficVesselFromJson,
  VesselJson,
} from '../../Domain/Vessel'
import { handle404 } from '../../lib/utils'
import { vesselState } from '../Traffic/localVesselStore'

const fetchDestinationPortName = (destinationPort: string | undefined) =>
  destinationPort
    ? fetchPortName(createUnlocode(destinationPort))
        .then(({ port, name }) => `${name} (${port})`)
        .catch(handle404(undefined))
    : Promise.resolve(undefined)

export function useVesselProperties(json: VesselJson, port?: Port) {
  const [properties, setProperties] = useState<AllVesselProperties & { destinationPortName?: string }>()

  useEffect(() => {
    const subscription = vesselState
      .pipe(
        mergeMap(({ handPicked }) => {
          const showCasePort = pipe(getShowCasePort(), fromNullable)
          const mmsi = createMMSI(json.mmsi)
          const { destinationPort } = json

          if (port !== undefined && destinationPort === port.port) {
            return defer(() => fetchPortVisitByMMSI(port.port, mmsi, isSome(showCasePort))).pipe(
              map(visit => ({
                ...portcallFromVesselVisit(visit, json).properties,
                destinationPortName: `${port.name} (${port.port})`,
              }))
            )
          }

          if (handPicked.includes(mmsi)) {
            return defer(() =>
              Promise.all([fetchNextVisitByMMSI(mmsi, showCasePort), fetchDestinationPortName(destinationPort)])
            ).pipe(
              map(([visit, destinationPortName]) => ({
                ...handPickedFromVesselVisit(visit ? visitFromJson(visit) : undefined, json).properties,
                destinationPortName,
              }))
            )
          }

          return defer(() => fetchDestinationPortName(destinationPort)).pipe(
            map(destinationPortName => ({ ...trafficVesselFromJson(json).properties, destinationPortName }))
          )
        })
      )
      .subscribe(props => {
        if (properties === undefined || properties.mmsi !== props.mmsi) {
          setProperties(props)
        }
      })

    return subscription.unsubscribe.bind(subscription)
  }, [json, port, properties])

  useEffect(() => {
    if (properties !== undefined && properties.mmsi !== json.mmsi) {
      setProperties(undefined)
    }
  }, [json, properties])

  return properties
}
