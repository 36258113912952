import { LngLatBounds, LngLat } from 'mapbox-gl'
import { range } from 'fp-ts/es6/Array'

/****************************************************
/*                   -90 longitude
/*                    |
/*           +--------+--------+
/*           |        |        |
/* -180 -----+--------+--------+----- 180 latitude
/*           |        |        |
/*           +--------+--------+
/*                    |
/*                    90
/*
/****************************************************/

export type Tile = { kind: 'Tile'; x: number; y: number; distanceToCenter: number }

const tile = (x: number, y: number, distanceToCenter: number | undefined = 0): Tile => ({
  kind: 'Tile',
  x,
  y,
  distanceToCenter,
})

// https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#ECMAScript_.28JavaScript.2FActionScript.2C_etc..29
const lon2tile = (lon: number, n: number) => Math.floor(((lon + 180) / 360) * n)

const lat2tile = (lat: number, n: number) =>
  Math.floor(((1 - Math.log(Math.tan((lat * Math.PI) / 180) + 1 / Math.cos((lat * Math.PI) / 180)) / Math.PI) / 2) * n)

export function outerTiles(bounds: LngLatBounds, zoom: number) {
  const n = 2 ** zoom
  const [[west, north], [east, south]] = bounds.toArray()

  return {
    northWestTile: tile(lon2tile(west, n), lat2tile(north, n)),
    southEastTile: tile(lon2tile(east, n), lat2tile(south, n)),
  }
}

export const tilesFromOuterTiles = (
  { northWestTile, southEastTile }: { northWestTile: Tile; southEastTile: Tile },
  center: LngLat,
  zoom: number
) => {
  const n = 2 ** zoom

  const centerTile = tile(lon2tile(center.lng, n), lat2tile(center.lat, n))

  return range(northWestTile.x, southEastTile.x).reduce(
    (acc: Tile[], x) =>
      range(southEastTile.y, northWestTile.y).reduce((acc2: Tile[], y): Tile[] => {
        const distanceToCenter = Math.sqrt((x - centerTile.x) ** 2 + (y - centerTile.y) ** 2)

        const newTile = tile((x + n) % n, (y + n) % n, distanceToCenter)

        // The map can wrap, so tiles also wrap
        // Ex. x: -1 will become 7 at zoom 3
        // Because of the wrapped tiles duplicates might occur and need to be filtered out
        return acc2.find(tile => tilesAreEqual(newTile, tile)) ? acc2 : [...acc2, newTile]
      }, acc),
    []
  )
}

export const distanceToCenterComparator = (a: Tile, b: Tile) =>
  a.distanceToCenter === b.distanceToCenter ? 0 : a.distanceToCenter - b.distanceToCenter

export const tilesAreEqual = (a: Tile, b: Tile) => a.x === b.x && a.y === b.y
