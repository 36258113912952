import React, { PropsWithChildren } from 'react'
import { useFromFetch } from '../lib/useFromFetch'
import { initializeMap, useMapboxContext } from './Mapbox'

type InitializeMapProps = PropsWithChildren<{ center: [number, number]; zoom?: number }>

export function InitializeMap({ center, zoom, children }: InitializeMapProps) {
  const { mapbox } = useMapboxContext()
  const { isLoading } = useFromFetch(() => initializeMap(mapbox, center, zoom))

  return isLoading ? null : <>{children}</>
}
