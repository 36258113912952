import React from 'react'
import classnames from 'classnames'
import styles from './Select.module.scss'

type Icon = 'arrow' | 'search' | 'clear' | 'expand'

type InputProps = {
  className?: string
  getProps: () => React.HTMLProps<HTMLInputElement>
  hasError?: boolean
  icon?: Icon
  isOpen?: boolean
}

export const Input = ({ getProps, className, hasError, icon = 'arrow', isOpen = false }: InputProps) => (
  <input
    {...getProps()}
    className={classnames(styles.input, className, styles[icon], {
      [styles.errors]: hasError,
      [styles.isOpen]: isOpen,
    })}
    type="text"
  />
)
