import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Pages } from '../../constants'
import { object as YupObject, string as YupString, bool as YupBoolean } from 'yup'
import { store } from '../../dataStore'
import { Metadata } from '../../Domain/User'
import { StepHeader, StepText, StepTitle } from '../../Flow/GenericStepElements'
import { useTranslation } from '../../lib/i18n'
import { useFromFetch } from '../../lib/useFromFetch'
import { CreateAccountStepProps } from './CreateAccountOnboarding.d'
import { CompanyType, companyTypes } from '../../Domain/Company'
import { Form, Formik } from 'formik'
import styles from './StepProfile.module.scss'
import { FormikInputField } from '../../UI/FormikInputField/FormikInputField'
import { FormikCompanyTypeDropDown } from '../SelectCompanyType/CompanyTypeDropDown'
import { RadioButtonWithLabel } from '../../UI/RadioButton/RadioButton'
import { CtaButton } from '../../UI/CtaButton/CtaButton'
import { Checkbox } from '../../UI/Checkbox/Checkbox'
import { createUnlocode } from '../../Domain/Port'
import { SHOW_CASE } from '../../config'

type FormValues = {
  givenName: string
  familyName: string
  accountType: 'private' | 'business'
  company: { companyType: CompanyType | ''; name: string }
  defaultPort: string
  consentEmailMarketing: boolean
}

export const validationSchema = YupObject<Metadata>().shape(
  {
    givenName: YupString().required('Required'),
    familyName: YupString().required('Required'),
    accountType: YupString<Metadata['accountType']>().oneOf(['private', 'business']),
    company: YupObject().when('accountType', {
      is: type => type === 'business',
      then: YupObject().shape({
        name: YupString().required('Required'),
        companyType: YupString<CompanyType>().oneOf(companyTypes).required('Required'),
      }),
    }),
    consentEmailMarketing: YupBoolean().required('Required'),
  },
  [['accountType', 'companyName']]
)

export const dataToFormValues = (metadata: Metadata | undefined): FormValues => ({
  givenName: metadata?.givenName ?? '',
  familyName: metadata?.familyName ?? '',
  accountType: metadata?.accountType ?? 'private',
  company: metadata?.company ?? { name: '', companyType: '' },
  defaultPort: metadata?.defaultPort ?? '',
  consentEmailMarketing: metadata?.consentEmailMarketing ?? false,
})

export const StepProfile: React.FC<CreateAccountStepProps> = ({ onPrevStep, onCompletedFlow }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { isLoading, data } = useFromFetch(store.metadata.fetch)
  const [initialValues, setInitialValues] = useState<FormValues>()

  useEffect(() => {
    if (!isLoading && data) {
      setInitialValues(dataToFormValues(data))
    }
  }, [isLoading, data])

  const handleSubmit = async (values: FormValues) => {
    await store.metadata.update({
      givenName: values.givenName,
      familyName: values.familyName,
      accountType: values.accountType,
      company:
        values.accountType === 'business' && values.company.companyType !== ''
          ? {
              name: values.company.name,
              companyType: values.company.companyType,
            }
          : undefined,
      defaultPort: createUnlocode(SHOW_CASE),
      consentEmailMarketing: values.consentEmailMarketing,
    })
    onCompletedFlow()
  }

  return (
    <>
      <StepHeader onBackButton={onPrevStep} onClose={() => history.push(Pages.MAIN)} />
      <StepTitle>{t('ShipTracker.Account.CompleteAccount.Profile.Title')}</StepTitle>
      <StepText>{t('ShipTracker.Account.CompleteAccount.Profile.Description')}</StepText>
      {initialValues && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount
          onSubmit={handleSubmit}
        >
          {({
            isValid,
            errors,
            touched,
            handleChange,
            values,
            setFieldValue,
            setFieldTouched,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form className={styles.form}>
              <div className={styles.inputField}>
                <FormikInputField type="text" name="givenName" label="First name" />
              </div>
              <div className={styles.inputField}>
                <FormikInputField type="text" name="familyName" label="Last name" />
              </div>
              <div className={styles.inputField}>
                <RadioButtonWithLabel
                  checked={values.accountType === 'private'}
                  handleChange={e => {
                    setFieldValue('company.name', '', false)
                    setFieldTouched('company.name', false, false)
                    handleChange(e)
                  }}
                  label={t('ShipTracker.Account.UserProfile.PrivateUser')}
                  name="accountType"
                  value="private"
                />
                <RadioButtonWithLabel
                  checked={values.accountType === 'business'}
                  handleChange={handleChange}
                  label={t('ShipTracker.Account.UserProfile.BusinessUser')}
                  name="accountType"
                  value="business"
                />
              </div>
              {values.accountType === 'business' && (
                <>
                  <div className={styles.inputField}>
                    <FormikInputField type="text" name="company.name" label="Company name" />
                  </div>
                  <div className={styles.inputField}>
                    <FormikCompanyTypeDropDown name="company.companyType" placeHolder="Company type">
                      <div className={styles.label}>Company type</div>
                    </FormikCompanyTypeDropDown>
                  </div>
                </>
              )}
              <div className={styles.inputField}>
                <Checkbox
                  className={styles.checkbox}
                  onChange={(value: boolean) => setFieldValue('consentEmailMarketing', value)}
                  checked={values.consentEmailMarketing}
                >
                  {t('ShipTracker.Account.UseDataForMarketing')}
                </Checkbox>
              </div>
              <CtaButton
                className={styles.submitButton}
                onClick={() => handleSubmit()}
                isDisabled={!isValid || isSubmitting}
              >
                Complete account creation
              </CtaButton>
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}
