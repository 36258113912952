import React from 'react'
import style from './CookieBar.module.scss'
import classnames from 'classnames'
import { CloseButton } from '../CloseButton'
import { useCookieSettings } from './useCookieSettings'
import { COOKIE_POLICY_URL } from '../../constants'

const theme: Record<string, string> = {
  ...style,
}

const CookieBar = () => {
  const { setCookiesEnabled } = useCookieSettings()

  return (
    <div className={classnames(theme.cookieBar)}>
      <CloseButton
        onClose={setCookiesEnabled.bind(setCookiesEnabled, true)}
        theme={{ button: theme.closeButton, icon: theme.closeButtonIcon }}
      />
      <div>
        This site uses cookies. Find out more about the cookies we are using.
        <a href={COOKIE_POLICY_URL} target="_blank" rel="noopener noreferrer">
          More information
        </a>
      </div>
    </div>
  )
}

export default CookieBar
