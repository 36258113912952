import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { Pages } from '../../constants'
import { store } from '../../dataStore'
import { CommonStepAuthentication } from '../../Flow/CommonSteps/CommonStepAuthentication'
import { CommonStepEmailVerification } from '../../Flow/CommonSteps/CommonStepEmailVerification'
import { CommonStepLegalTerms } from '../../Flow/CommonSteps/CommonStepLegalTerms'
import { Flow } from '../../Flow/Flow'
import { GenericStepProps } from '../../Flow/GenericStepElements.d'
import { useFlow } from '../../Flow/useFlow'
import { useHandleFlowStart } from '../../Flow/useHandleFlowStart'
import { LegalTerms } from '../../Generic/LegalTermsPage'
import { useRedirectToPath } from '../../lib/hooks/useRedirectToPath'
import { useTranslation } from '../../lib/i18n'
import { useFromFetch } from '../../lib/useFromFetch'
import { CreateAccountStep } from './CreateAccountOnboarding.d'
import { dataToFormValues, StepProfile, validationSchema } from './StepProfile'
import { useAuthFlowContext, VerificationStatus, AuthFlowProvider } from '../../Auth/AuthFlowContext'
import { addMetadataProduct } from '../../Domain/User'

const stepTranslationKeys: { [key in CreateAccountStep]: string } = {
  [CreateAccountStep.AUTHENTICATION]: 'AUTHENTICATION',
  [CreateAccountStep.EMAIL_VERIFICATION]: 'EMAIL_VERIFICATION',
  [CreateAccountStep.LEGAL_TERMS]: 'LEGAL_TERMS',
  [CreateAccountStep.PROFILE]: 'PROFILE',
}

const stepsArray = Object.values(CreateAccountStep)

const stepsToData = (label: (step: string) => string, current: CreateAccountStep) =>
  stepsArray.map(step => ({
    key: step,
    label: label(stepTranslationKeys[step]),
    isActive: step === current,
  }))

const AuthenticatedFlow: React.FC<{
  setStep: (step: CreateAccountStep) => void
  setInitialStep: (step: CreateAccountStep) => void
}> = ({ setStep, setInitialStep, children }) => {
  const history = useHistory()
  const { isLoading, data: metadata } = useFromFetch(store.metadata.fetch)

  useEffect(() => {
    if (!isLoading && metadata !== undefined) {
      const stepConditions: [CreateAccountStep, () => boolean][] = [
        [CreateAccountStep.LEGAL_TERMS, () => !metadata?.acceptedTerms],
        [
          CreateAccountStep.PROFILE,
          () =>
            metadata.onboardingGuiStatus !== 'completed' || !validationSchema.isValidSync(dataToFormValues(metadata)),
        ],
      ]

      for (const [name, checkCondition] of stepConditions) {
        if (checkCondition()) {
          setStep(name)
          setInitialStep(name)
          break
        }

        if (name === CreateAccountStep.PROFILE) {
          history.push(Pages.MAIN)
        }
      }
    }
  }, [history, isLoading, metadata, setStep, setInitialStep])

  return isLoading || metadata === undefined ? null : <>{children}</>
}

const UnAuthenticatedFlow: React.FC<{
  setStep: (step: CreateAccountStep) => void
  setInitialStep: (step: CreateAccountStep) => void
  current: CreateAccountStep
}> = ({ setStep, setInitialStep, current, children }) => {
  const { status } = useAuthFlowContext()

  useEffect(() => {
    if (
      current !== CreateAccountStep.EMAIL_VERIFICATION &&
      [VerificationStatus.Unverified, VerificationStatus.Verified].includes(status)
    ) {
      setStep(CreateAccountStep.EMAIL_VERIFICATION)
    } else {
      setInitialStep(current)
    }
  }, [current, setStep, setInitialStep, status])

  return <>{children}</>
}

export const CreateAccountOnboarding = () => {
  const { isAuthenticated } = useAuth0()
  const { current, setStep, nextStep, prevStep } = useFlow(stepsArray)
  const [initialStep, setInitialStep] = useState<CreateAccountStep>()
  const handleFlowStart = useHandleFlowStart('onboardingGuiStatus')

  const { t } = useTranslation()
  const history = useHistory()

  const isLoadingStep = initialStep === undefined

  useRedirectToPath(!isLoadingStep, `${Pages.CREATE_ACCOUNT}/${current}`)

  useEffect(() => {
    if (!isLoadingStep) {
      handleFlowStart()
    }
  }, [handleFlowStart, isLoadingStep])

  const stepsData = useMemo(() => stepsToData(key => t(`ShipTracker.Account.CompleteAccount.Steps.${key}`), current), [
    current,
    t,
  ])

  const renderSteps = () => {
    const genericProps: Omit<GenericStepProps<CreateAccountStep>, 'index'> = {
      onNextStep: nextStep,
      onPrevStep: prevStep,
      onSetStep: setStep,
      onCompletedFlow: async () => {
        const products = await addMetadataProduct('Shiptracker')
        await store.metadata.update({
          onboardingGuiStatus: 'completed',
          products,
        })
        history.push(Pages.MAIN)
      },
    }

    const authenticationProps = {
      loginRedirectPage: Pages.CREATE_ACCOUNT,
    }

    switch (current) {
      case CreateAccountStep.AUTHENTICATION:
        return <CommonStepAuthentication {...authenticationProps} {...genericProps} />
      case CreateAccountStep.EMAIL_VERIFICATION:
        return <CommonStepEmailVerification {...authenticationProps} {...genericProps} />
      case CreateAccountStep.LEGAL_TERMS:
        return (
          <CommonStepLegalTerms metadataLegalTermsKey="acceptedTerms" {...genericProps}>
            <LegalTerms />
          </CommonStepLegalTerms>
        )
      case CreateAccountStep.PROFILE:
        return <StepProfile {...genericProps} index={4} />
    }
  }

  return (
    <Flow title={t(`ShipTracker.Account.CompleteAccount.MenuTitle`)} stepsData={stepsData} isLoading={isLoadingStep}>
      {isAuthenticated ? (
        <AuthenticatedFlow setStep={setStep} setInitialStep={setInitialStep}>
          {!isLoadingStep && renderSteps()}
        </AuthenticatedFlow>
      ) : (
        <AuthFlowProvider>
          <UnAuthenticatedFlow setStep={setStep} setInitialStep={setInitialStep} current={current}>
            {!isLoadingStep && renderSteps()}
          </UnAuthenticatedFlow>
        </AuthFlowProvider>
      )}
    </Flow>
  )
}
