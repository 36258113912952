import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'
import ky from 'ky'

export const toPromise = <A>(o: Observable<A>): Promise<A> => o.pipe(take(1)).toPromise()

export const uniqArray = <T>(array: T[]) => array.filter((value, index, arr) => arr.indexOf(value) === index)

export const is404 = (e: Error) => e instanceof ky.HTTPError && e.response.status === 404

export const handle404 = <A>(value: A) => (e: Error): A => {
  if (is404(e)) {
    return typeof value === 'function' ? value() : value
  }

  throw e
}
