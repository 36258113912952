import { useEffect, useState, useCallback } from 'react'
import { defer } from 'rxjs'

export type UseFromFetchState<A> = Readonly<{ isLoading: boolean; data?: A; error?: Error }>

export function useFromFetch<A>(fetch: () => Promise<A>): UseFromFetchState<A> {
  const [state, setState] = useState<UseFromFetchState<A>>({ isLoading: true })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchCallback = useCallback(() => fetch(), [])

  useEffect(() => {
    const subscription = defer(() => fetchCallback()).subscribe(
      data => setState({ isLoading: false, data }),
      error => setState({ isLoading: false, error })
    )

    return subscription.unsubscribe.bind(subscription)
  }, [fetchCallback])

  return state
}
