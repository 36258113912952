import React, { PropsWithChildren } from 'react'

import styles from './ControlStrip.module.scss'

export const ControlStrip = ({ children }: PropsWithChildren<{ disabled?: boolean }>) => (
  <div className={styles.controlStrip}>{children}</div>
)

export const ControlStripEntry = ({ children }: PropsWithChildren<{}>) => <div className={styles.entry}>{children}</div>

type ControlStripButtonProps = PropsWithChildren<{ onClick?: () => void }>

export const ControlStripButton = ({ children, onClick }: ControlStripButtonProps) => (
  <div onClick={onClick} className={styles.button}>
    {children}
  </div>
)
