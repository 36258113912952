import { useState, useEffect, useMemo } from 'react'
import { fold, none, Option, isSome, chain, map, fromNullable } from 'fp-ts/es6/Option'
import { bimap } from 'fp-ts/es6/Either'
import { pipe } from 'fp-ts/es6/pipeable'
import { useMapboxContext, renderHoverMarker } from '../Mapbox'
import { constNull } from 'fp-ts/es6/function'
import { VesselDetailsMarker, VesselDetailsMarkerProps, MobileVesselDetailsMarker } from './VesselDetailsMarker'
import { mapEvents } from '../Traffic/mapMouseEvents'
import { VesselHoverMarker } from './VesselHoverMarker'
import { VesselFeature } from '../../Domain/VesselFeature'
import { VesselDisplayState } from '../Traffic/useVesselTraffic'
import {
  PortVessel,
  TrafficVessel,
  HandPickedVessel,
  VesselSource,
  Vessel,
  findByMMSI,
  createMMSI,
  VesselJson,
} from '../../Domain/Vessel'
import { useHistory } from 'react-router-dom'
import { selectedVesselPath, useMMSIMatcher } from '../helpers/paths'
import { Pages } from '../../constants'
import { clearSelectedVessel, setSelectedVessel, VesselNotFoundError } from '../Traffic/selectedVessel'
import { selectedVessel } from '../Traffic/selectedVessel'
import { useResponsiveness } from '../../lib/hooks/useResponsiveness'
import { VesselDetails } from '../VesselDetails'
import { Port } from '../../Domain/Port'
import { toast } from 'react-toastify'
import { DURATION, VesselNotFound } from '../../UI/Toast'

type MarkerProps = Pick<VesselDetailsMarkerProps, 'coordinates' | 'properties'>

type Store = {
  portcalls: PortVessel[]
  handPicked: HandPickedVessel[]
  traffic: TrafficVessel[]
}

type Source = { mmsi: number; source: VesselSource }

function findVesselFeature({ portcalls, handPicked, traffic }: Store, { mmsi, source }: Source): Option<Vessel> {
  switch (source) {
    case VesselSource.PORT_VESSEL: {
      return pipe(portcalls, findByMMSI(createMMSI(mmsi)))
    }

    case VesselSource.HAND_PICKED: {
      return pipe(handPicked, findByMMSI(createMMSI(mmsi)))
    }

    case VesselSource.AIS: {
      return pipe(traffic, findByMMSI(createMMSI(mmsi)))
    }
  }
}

const findMarkerPropsFromFeature = (
  featureOption: Option<VesselFeature>,
  store: Store,
  displayState: VesselDisplayState
) =>
  pipe(
    featureOption,
    chain(feature => findVesselFeature(store, feature.properties)),
    map(f => markerProps(f, displayState))
  )

const findMarkerPropsFromMMSI = (mmsiOption: Option<VesselJson>, store: Store, displayState: VesselDisplayState) =>
  pipe(
    mmsiOption,
    chain(({ mmsi }) =>
      pipe(
        store.handPicked.find(({ properties }) => properties.mmsi === mmsi) ||
          store.portcalls.find(({ properties }) => properties.mmsi === mmsi) ||
          store.traffic.find(({ properties }) => properties.mmsi === mmsi),
        fromNullable
      )
    ),
    map((f: Vessel) => markerProps(f, displayState))
  )

export const markerProps = (
  item: PortVessel | TrafficVessel | HandPickedVessel,
  displayState: VesselDisplayState
): MarkerProps => {
  const coordinates =
    displayState === VesselDisplayState.SHAPES
      ? [item.features.shape.properties.lng, item.features.shape.properties.lat]
      : item.features.icon.geometry.coordinates

  return { properties: item.properties, coordinates }
}

export function useMapDetailMarkers() {
  const { mapbox } = useMapboxContext()
  const [detailMarkerData, setDetailMarkerData] = useState<Option<VesselJson>>(none)
  const history = useHistory()
  const { mmsiMatch } = useMMSIMatcher()

  useEffect(() => {
    const { onSelect } = mapEvents(mapbox)

    const subscription = onSelect.subscribe(feature => {
      pipe(
        feature,
        fold(
          () => history.push(Pages.MAP),
          ({ properties: { mmsi } }) => history.push(selectedVesselPath(mmsi))
        )
      )
    })

    return subscription.unsubscribe.bind(subscription)
  }, [mapbox, history])

  useEffect(() => {
    const subscription = selectedVessel.subscribe(res =>
      pipe(
        res,
        bimap(
          e => {
            if (e instanceof VesselNotFoundError) {
              toast.error(<VesselNotFound mmsi={e.message} />, { autoClose: DURATION.MEDIUM })
            }
          },
          option => {
            if (isSome(option) && option.value.location === undefined) {
              toast.error(<VesselNotFound mmsi={option.value.mmsi.toString()} />, { autoClose: DURATION.MEDIUM })

              return setDetailMarkerData(none)
            }

            setDetailMarkerData(option)
          }
        )
      )
    )

    return subscription.unsubscribe.bind(subscription)
  }, [])

  useEffect(() => {
    pipe(mmsiMatch, fold(clearSelectedVessel, setSelectedVessel))
  }, [mmsiMatch])

  return {
    detailMarkerData,
  }
}
export function useMapHoverMarkers(detailMarkerMMSI: Option<MarkerProps>) {
  const { mapbox } = useMapboxContext()
  const [hoverMarker, setHoverMarker] = useState<Option<VesselFeature>>(none)

  useEffect(() => {
    const { onHover } = mapEvents(mapbox)

    const subscription = onHover.subscribe(setHoverMarker)

    return subscription.unsubscribe.bind(subscription)
  }, [mapbox])

  return {
    hoverMarker: pipe(
      detailMarkerMMSI,
      fold(
        () => hoverMarker,
        ({ properties: { mmsi } }) =>
          isSome(hoverMarker) && hoverMarker.value.properties.mmsi === mmsi ? none : hoverMarker
      )
    ),
    setHoverMarker,
  }
}

type MapMarkersContentProps = {
  detailMarker: Option<MarkerProps>
  findMarkerProps: (marker: Option<VesselFeature>) => Option<MarkerProps>
}

function HoverMarkers({ detailMarker, findMarkerProps }: MapMarkersContentProps) {
  const { hoverMarker } = useMapHoverMarkers(detailMarker)
  const { mapbox } = useMapboxContext()

  const hoverMarkerProps = findMarkerProps(hoverMarker)

  useEffect(() => {
    const markers = [detailMarker, hoverMarkerProps].map(option =>
      pipe(
        option,
        fold(
          (): number[] => [],
          ({ coordinates }) => coordinates
        )
      )
    )

    renderHoverMarker(mapbox, markers)

    return () => renderHoverMarker(mapbox, [])
  }, [detailMarker, hoverMarkerProps, mapbox])

  return pipe(
    hoverMarkerProps,
    fold(constNull, props => <VesselHoverMarker {...props} key={`hover-${props.properties.mmsi}`} />)
  )
}

type MapMarkerProps = {
  detailMarkerData: Option<VesselJson>
  portcalls: PortVessel[]
  traffic: TrafficVessel[]
  handPicked?: HandPickedVessel[]
  displayState: VesselDisplayState
  port?: Port
}

export function MapMarkers({
  portcalls,
  traffic,
  handPicked = [],
  detailMarkerData,
  displayState,
  port,
}: MapMarkerProps) {
  const { isMobile } = useResponsiveness()
  const store: Store = useMemo(() => ({ portcalls, traffic, handPicked }), [handPicked, portcalls, traffic])
  const detailMarkerOption = useMemo(() => findMarkerPropsFromMMSI(detailMarkerData, store, displayState), [
    detailMarkerData,
    store,
    displayState,
  ])
  const MarkerComponent = isMobile ? MobileVesselDetailsMarker : VesselDetailsMarker
  return (
    <>
      {pipe(
        detailMarkerData,
        fold(constNull, data => (
          <MarkerComponent coordinates={data.location.coordinates}>
            <VesselDetails port={port} vesselData={data} />
          </MarkerComponent>
        ))
      )}
      <HoverMarkers
        detailMarker={detailMarkerOption}
        findMarkerProps={marker => findMarkerPropsFromFeature(marker, store, displayState)}
      />
    </>
  )
}
