import { Map as Mapbox, MapMouseEvent } from 'mapbox-gl'
import { merge, fromEventPattern, fromEvent } from 'rxjs'
import { switchMap, map, distinctUntilChanged, mapTo, throttleTime, tap } from 'rxjs/operators'

import { some, none, isSome, Option } from 'fp-ts/es6/Option'

import { fromLayerEvent } from '../Mapbox'
import { VesselFeature } from '../../Domain/VesselFeature'

type MapFeatureMouseEvent = MapMouseEvent & { features: VesselFeature[] }

const MOUSE_MOVE_THROTTLE_TIME = 60

export const mapEvents = (mapbox: Mapbox) => {
  const mouseMove = merge(
    fromLayerEvent<MapFeatureMouseEvent>(mapbox, 'traffic-layer', 'mousemove').pipe(map(({ features }) => features)),
    fromLayerEvent<MapFeatureMouseEvent>(mapbox, 'portCalls-layer', 'mousemove').pipe(map(({ features }) => features)),
    fromLayerEvent<MapFeatureMouseEvent>(mapbox, 'traffic-shapes-layer', 'mousemove').pipe(
      map(({ features }) => features)
    ),
    fromLayerEvent<MapFeatureMouseEvent>(mapbox, 'portcall-shapes-layer', 'mousemove').pipe(
      map(({ features }) => features)
    )
  ).pipe(
    throttleTime(MOUSE_MOVE_THROTTLE_TIME),
    map(fs => fs[0])
  )

  const mouseClick = merge(
    fromLayerEvent<MapFeatureMouseEvent>(mapbox, 'traffic-layer', 'click').pipe(map(({ features }) => features)),
    fromLayerEvent<MapFeatureMouseEvent>(mapbox, 'portCalls-layer', 'click').pipe(map(({ features }) => features)),
    fromLayerEvent<MapFeatureMouseEvent>(mapbox, 'traffic-shapes-layer', 'click').pipe(map(({ features }) => features)),
    fromLayerEvent<MapFeatureMouseEvent>(mapbox, 'portcall-shapes-layer', 'click').pipe(map(({ features }) => features))
  ).pipe(map(fs => fs[0]))

  const mouseLeave = mouseMove.pipe(
    map(({ properties: { mmsi } }) => mmsi),
    distinctUntilChanged(),
    switchMap(() =>
      merge(
        fromLayerEvent<MapFeatureMouseEvent>(mapbox, 'traffic-layer', 'mouseleave'),
        fromLayerEvent<MapFeatureMouseEvent>(mapbox, 'portCalls-layer', 'mouseleave'),
        fromLayerEvent<MapFeatureMouseEvent>(mapbox, 'traffic-shapes-layer', 'mouseleave'),
        fromLayerEvent<MapFeatureMouseEvent>(mapbox, 'portcall-shapes-layer', 'mouseleave')
      )
    )
  )

  const mouseClickOutside = mouseClick.pipe(
    switchMap(() =>
      fromEventPattern(
        handler => mapbox.on('click', handler),
        handler => mapbox.off('click', handler)
      )
    )
  )

  return {
    onHover: merge<Option<VesselFeature>>(
      mouseMove.pipe(map(m => some(m))),
      mouseLeave.pipe(mapTo(none)),
      fromEvent(mapbox, 'dragstart').pipe(mapTo(none))
    ).pipe(tap(fs => (mapbox.getCanvas().style.cursor = isSome(fs) ? 'pointer' : ''))),
    onSelect: merge<Option<VesselFeature>>(
      mouseClick.pipe(map(marker => some(marker))),
      mouseClickOutside.pipe(mapTo(none))
    ),
  }
}
