import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { saveMetadataCache } from '../lib/metadataCacheStorage'
import { EmailVerified, DURATION, EmailAlreadyVerified } from '../UI/Toast'
import { useHistory } from 'react-router-dom'
import { getVerifyEmailCache, saveVerifyEmailCache } from '../lib/verifyEmailStorage'
import { Pages } from '../constants'

const verifiedEmailRegex = /^Your email was verified.(.*)$/
const alreadyVerifiedEmailRegex = /^This URL can be used only once(.*)$/

export function useHandleEmailVerificationSuccess() {
  const {
    location: { search },
  } = window
  const history = useHistory()

  useEffect(() => {
    const message = new URLSearchParams(search).get('message')

    if (message && message.search(verifiedEmailRegex) > -1) {
      toast.success(<EmailVerified />, { autoClose: DURATION.MEDIUM })
      const signupProperties = { emailVerified: true }

      saveMetadataCache(cache => ({ ...cache, ...signupProperties }))

      const verifyEmailCache = getVerifyEmailCache()

      if (verifyEmailCache) {
        saveVerifyEmailCache({ ...verifyEmailCache, isVerified: true })

        history.replace(Pages.CREATE_ACCOUNT)
      }
    } else if (message && message.search(alreadyVerifiedEmailRegex) > -1) {
      toast.info(<EmailAlreadyVerified />, { autoClose: DURATION.MEDIUM })
      const verifyEmailCache = getVerifyEmailCache()

      if (verifyEmailCache) {
        saveVerifyEmailCache({ ...verifyEmailCache, isVerified: true })

        history.replace(Pages.CREATE_ACCOUNT)
      }
    }
  }, [search, history])
}
