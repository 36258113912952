import React, { PropsWithChildren } from 'react'
import { useSelect } from 'downshift'
import { Menu, Item, Input } from '../../UI/Select'
import { companyTypes, CompanyType } from '../../Domain/Company'
import styles from './CompanyTypeDropDown.module.scss'
import { useField, ErrorMessage } from 'formik'
import { InputError } from '../InputError'

const companyTypeLabels: { [key in CompanyType]: string } = {
  port: 'Port',
  terminal: 'Terminal',
  freightforwarder: 'Freight forwarder',
  serviceprovider: 'Service provider',
  operator: 'Truck/Rail/Barge operator',
  other: 'Other',
}

const items = companyTypes.slice()

type CompanyTypeDropDownProps = PropsWithChildren<{
  value: CompanyType | ''
  placeHolder?: string
  onBlur?: () => void
  onChange: (item: CompanyType | '') => void
  hasError: boolean
  className?: string
}>

export function CompanyTypeDropDown({
  value,
  placeHolder,
  onChange,
  onBlur,
  hasError,
  className,
  children,
}: CompanyTypeDropDownProps) {
  const { isOpen, selectedItem, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps } = useSelect<
    CompanyType
  >({
    selectedItem: value !== '' ? value : null,
    items,
    onIsOpenChange: ({ isOpen }) => !isOpen && onBlur && onBlur(),
    onSelectedItemChange: ({ selectedItem }) => onChange(selectedItem || ''),
  })

  return (
    <div className={styles.dropdown}>
      <label onBlur={onBlur}>{children}</label>
      <Input
        className={className}
        hasError={hasError}
        getProps={() =>
          getToggleButtonProps({
            placeholder: placeHolder,
            readOnly: true,
            value: selectedItem ? companyTypeLabels[selectedItem] : '',
          })
        }
      />
      <Menu isOpen={isOpen} getProps={getMenuProps}>
        {isOpen &&
          items.map((item, index) => (
            <Item
              key={item}
              isHighlighted={highlightedIndex === index}
              getProps={() => ({ ...getItemProps({ item, index }) })}
            >
              {companyTypeLabels[item]}
            </Item>
          ))}
      </Menu>
    </div>
  )
}

type FormikCompanyTypeDropDownProps = PropsWithChildren<{ name: string; placeHolder?: string }>
export function FormikCompanyTypeDropDown({ name, placeHolder, children }: FormikCompanyTypeDropDownProps) {
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField<CompanyType | ''>(name)

  return (
    <>
      <CompanyTypeDropDown
        value={value}
        className={styles.input}
        placeHolder={placeHolder}
        hasError={touched && error !== undefined}
        onBlur={() => setTouched(true)}
        onChange={setValue}
      >
        {children}
      </CompanyTypeDropDown>
      <ErrorMessage name={name} component={InputError} />
    </>
  )
}
