import { isTestEnvironment } from '../config'

export function trackError(e: unknown) {
  if (isTestEnvironment) {
    throw e
  } else {
    console.error(e)
  }
}

export const defaultNever = (val: never) => {
  trackError(new Error(`Unreachable case: ${val}`))
}
