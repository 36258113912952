import { Feature, Point, Polygon } from 'geojson'
import { VesselJson, VesselSource } from './Vessel'

const ICON_NAMES = [
  'traffic-S',
  'traffic-M',
  'traffic-L',
  'portcall',
  'portcall-S',
  'portcall-M',
  'portcall-L',
  'Oval-green',
  'Oval-orange',
] as const

type IconName = typeof ICON_NAMES[number]

export type ShapeProps = Readonly<{ mmsi: number; source: VesselSource; lng: number; lat: number; portcallId?: string }>
export type IconProps = Readonly<{
  mmsi: number
  source: VesselSource
  heading: number
  iconName: IconName
  portcallId?: string
}>

export type IconFeature = Feature<Point, IconProps>
export type ShapeFeature = Feature<Polygon, ShapeProps>
export type VesselFeature = IconFeature | ShapeFeature

const size = (shipDimensions: VesselJson['shipDimensions']) => {
  if (shipDimensions) {
    const size = shipDimensions.distanceToStern + shipDimensions.distanceToBow

    if (size > 75 && size < 275) {
      return 'M'
    }

    if (size > 275) {
      return 'L'
    }
  }

  return 'S'
}

export function iconFeatureFromJson(
  { location, mmsi, speedOverGround, heading, courseOverGround, shipDimensions }: VesselJson,
  source: VesselSource,
  portcallId?: string
): IconFeature {
  const [icon, dotIcon]: IconName[] =
    source === VesselSource.AIS
      ? [`traffic-${size(shipDimensions)}` as IconName, 'Oval-orange']
      : [`portcall-${size(shipDimensions)}` as IconName, 'Oval-green']

  const iconName = speedOverGround && speedOverGround > 0.0 ? icon : dotIcon

  return {
    type: 'Feature',
    geometry: location,
    properties: {
      mmsi,
      source,
      iconName,
      heading: (heading || courseOverGround || 0) % 360,
      portcallId,
    },
  }
}
