import React, { useCallback, useContext, useRef } from 'react'
import ReactDOM from 'react-dom'

type PortalContextState = {
  Portal: React.FC
}

const PortalContext = React.createContext({} as PortalContextState)

export const usePortal = () => useContext(PortalContext)

export const PortalProvider: React.FC<{ id: string }> = ({ id, children }) => {
  const portalParent = useRef<HTMLDivElement>(null)

  const Portal = useCallback<React.FC>(
    ({ children }) => (portalParent.current ? ReactDOM.createPortal(children, portalParent.current) : null),
    [portalParent]
  )

  return (
    <>
      <PortalContext.Provider value={{ Portal }}>{children}</PortalContext.Provider>
      <div ref={portalParent} id={id} />
    </>
  )
}
