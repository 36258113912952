import { useAuth0 } from '@auth0/auth0-react'
import { useConfirmationModal } from '../UI/Modal/ConfirmationModal'
import { deleteUser } from '../Api/User/deleteUser'
import { clientLogout } from '../Auth/client'
import { useTranslation } from '../lib/i18n'
import { clearMetadataCache } from '../lib/metadataCacheStorage'
import { clearVerifyEmailCache } from '../lib/verifyEmailStorage'

export function useDeleteAccount(text?: string) {
  const { logout } = useAuth0()
  const { t } = useTranslation()

  const [ModalContainer, askForConfirmation] = useConfirmationModal({
    title: t('ShipTracker.Account.Subscription.DeleteAccountTitle'),
    text: text || t('ShipTracker.Account.Subscription.DeleteAccountMessage'),
    confirmText: t('ShipTracker.Common.Delete'),
  })

  function handleDeleteAccount(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault()

    askForConfirmation(async () => {
      await deleteUser()
      clearMetadataCache()
      clearVerifyEmailCache()
      clientLogout(logout)
    })
  }

  return { ModalContainer, handleDeleteAccount }
}
