import React, { PropsWithChildren, useCallback } from 'react'
import styles from './Ticker.module.scss'
import classnames from 'classnames'
import { fromEvent, noop } from 'rxjs'

type TickerProps = PropsWithChildren<{
  className?: string
}>

const SCROLL_PIXELS_PER_SECOND = 80

const isOverflown = (element: HTMLElement) => element.scrollWidth > element.clientWidth

/*
  This Ticker scrolls content that overflows it's parent from right to left to show the user the whole text.
  If the user `mouseOver`s the element it will start scrolling when the content is wider than the parent.
  When the user `mouseLeave`s the element it will scroll back to its original postion.
  The scroll speed is dynamic and based on the amount of pixels to scroll.

  The inner is `absolute` positioned and sticks to the right side of the parent. It uses `min-width` and `max-width`
  which are equal to the parents width. When the user hovers over the element it sets the `max-width` to the width of
  the inner content. (`element.scrollWidth`) Because this makes the inner space wider and causes the left-aligned text to
  scroll to the left.
  This `max-width` needs to be the same as the inner content (`element.scrollWidth`) to determine the correct scroll speed
  and how far it needs to grow for the content to fit.
*/

export function Ticker({ children, className }: TickerProps) {
  const onRef = useCallback((node: HTMLDivElement | null) => {
    if (!node || !isOverflown(node)) {
      return noop
    }

    const subscriptions = [
      fromEvent(node, 'mouseover').subscribe(() => {
        const pixelsToScroll = node.scrollWidth - node.clientWidth
        const transitionDurationInSeconds = pixelsToScroll / SCROLL_PIXELS_PER_SECOND

        node.style.maxWidth = `${node.scrollWidth}px`
        node.style.transitionDuration = `${transitionDurationInSeconds}s`
      }),

      fromEvent(node, 'mouseleave').subscribe(() => {
        node.style.maxWidth = ''
      }),
    ]

    return () => {
      subscriptions.forEach(s => s.unsubscribe())
    }
  }, [])

  return (
    <div className={classnames(className, styles.ticker)}>
      <div className={styles.inner} ref={onRef}>
        {children}
      </div>
    </div>
  )
}
