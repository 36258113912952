import { createCachedRequest } from './lib/cachedRequest'
import { fetchMetadata } from './Api/User/fetchMetadata'
import { updateUserMetadata } from './Api/User/updateMetadata'

function createEntry<A, B>(mutation: (...args: A[]) => Promise<B>, clearCache: () => void) {
  return function (...args: A[]) {
    const p = mutation.apply(null, args)

    p.then(() => clearCache())

    return p
  }
}

const cachedFetchMetaData = createCachedRequest(fetchMetadata)

export const store = {
  metadata: {
    ...cachedFetchMetaData,
    update: createEntry(updateUserMetadata, cachedFetchMetaData.clearCache),
  },
}
